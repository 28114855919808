export const getMenuItems = ({
  match,
  sujeitoAtencaoStore,
  isTituloParcelaRead,
  profissionalLogado,
}) => {
  if (!sujeitoAtencaoStore?.objView?.id) {
    return [
      {
        title: 'Dados do paciente',
        children: [
          {
            id: 'principal',
            name: 'Informações Principais',
            to: match.url,
          },
        ],
      },
    ]
  }

  return [
    {
      title: 'Dados do paciente',
      children: [
        {
          id: 'principal',
          name: 'Dados Principais',
          to: match.url,
        },
        {
          id: 'pagamentos',
          name: 'Pagamentos',
          to: `${match.url}/pagamentos`,
          hidden: !isTituloParcelaRead,
        },
        {
          id: 'prontuario',
          name: 'Prontuário',
          to: `${match.url}/prontuario`,
          hidden: profissionalLogado.tipo === 4,
        },
      ],
    },
    {
      title: 'Dados do agendamento',
      children: [
        {
          id: 'consultas',
          name: 'Consultas',
          to: `${match.url}/consultas`,
        },
        {
          id: 'sessoes-em-andamento',
          name: 'Sessões em andamento',
          to: `${match.url}/sessoes-em-andamento`,
          hidden: !sujeitoAtencaoStore.withSessionControl,
        },
        {
          id: 'procedimentos-realizados',
          name: 'Procedimentos realizados',
          to: `${match.url}/procedimentos-realizados`,
        },
      ],
    },
    {
      title: 'Dados gerais',
      children: [
        {
          id: 'auditoria',
          name: 'Auditoria',
          to: `${match.url}/auditoria`,
        },
        {
          id: 'arquivos',
          name: 'Arquivos',
          to: `${match.url}/arquivos`,
        },
        {
          id: 'orcamentos',
          name: 'Orçamentos',
          to: `${match.url}/orcamentos`,
        },
      ],
    },
  ]
}
