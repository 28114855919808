import React, {useEffect, useState} from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { withStyles } from '@material-ui/core/styles';
import { InView } from 'react-intersection-observer';
import Notification from "../../../components/Notification";

const styles = {
    pdfContainer: {
        display: 'flex', 
        alignContent: 'center', 
        justifyContent: 'center', 
        padding: '20px 0 20px 0'
    },
    page: {
        margin: '10px 15px'
    }
};

const DocumentPreview = (props) => {
    const {classes, onPageChange} = props;
    const [pdfContent, setPdfContent] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [notificationOptions, setNotificationOptions] = useState({
        openNotification: false,
        notificationVariant: '',
        notificationMessage: ''
    });

    useEffect(() => {
        getDocumentBase64();
    }, []);

    const getDocumentBase64 = async () => {
        try {
            const {documentoStore, modeloDocumento} = props;
            let documentBase64 = await documentoStore.imprimirDocumento({id: modeloDocumento.id});
            documentBase64 = `data:${documentBase64}`;
            setPdfContent(documentBase64);
        } catch (error) {
            console.log(error.message);
        }
    };

    const onLoadSuccess = ({ numPages }) => {
        setNotificationOptions({
            openNotification: true,
            notificationMessage: 'Documento carregado com sucesso!',
            notificationVariant: 'success'
        });
        setNumPages(numPages);
        onPageChange(1, numPages);
    };

    const onLoadError = () => {
        setNotificationOptions({
            openNotification: true,
            notificationMessage: 'Falha ao carregar documento',
            notificationVariant: 'error'
        });
    }

    const handleCloseNotification = () => {
        setNotificationOptions({
            openNotification: false,
            notificationMessage: '',
            notificationVariant: ''
        });
    }

    const changePage = (inView, entry, pageNumber) => {
        if (inView && entry.intersectionRatio > 0.7 && entry.intersectionRatio < 1) {
            onPageChange(pageNumber, numPages);
        }
    }

    return (
        <div className={classes.pdfContainer}>
            {pdfContent && 
                <Document 
                    file={pdfContent}
                    onLoadError={onLoadError}
                    onLoadSuccess={onLoadSuccess}
                >
                    {numPages > 0 && Array.from(
                        new Array(numPages),
                        (item, index) => (
                            <InView 
                                as="div" 
                                onChange={(inView, entry) => changePage(inView, entry, index + 1)} 
                                key={index + 1}
                                threshold={0.7}
                                className={classes.page}
                            >
                                <Page 
                                    pageNumber={index + 1}
                                />
                            </InView>
                        )
                    )}
                </Document>
            }
            {notificationOptions.openNotification && 
                <Notification 
                    close={handleCloseNotification}
                    isOpen={notificationOptions.openNotification}
                    variant={notificationOptions.notificationVariant}
                    message={notificationOptions.notificationMessage}
                />
            }
        </div>
    );
};

export default withStyles(styles)(DocumentPreview);
