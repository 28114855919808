import React from 'react'
import { withStyles } from '@material-ui/core'

import Card from '../../Card'

export const CAMPOS_OBRIGATORIOS = {
  DATA_NASCIMENTO: 'dataNascimento',
  CPF: 'cpf',
  RG: 'rg',
  ENDERECO_RESIDENCIAL: 'endereco',
  CONVENIO: 'dadosConvenio',
}

const Pacientes = ({
  classes,
  handleChange,
  configuracaoUnidade,
  isRoleUnidadeUpdate,
  handleChangeCamposObrigatorios,
}) => {
  const usuarioLogado = JSON.parse(localStorage[`_immortal|usuarioLogado`] || null)
  const isRoleControleSessaoRead = usuarioLogado.authorities.some(
    value => value.authority === 'ROLE_CONTROLE_SESSAO_READ'
  )

  return (
    <div className={classes.container}>
      <Card
        title={'Contato'}
        configuracaoUnidade={configuracaoUnidade}
        fields={[
          { value: 'utilizaTelefoneInternacional', label: 'Utiliza telefone internacional?' },
        ]}
        onChange={handleChange}
        disabled={!isRoleUnidadeUpdate}
      />
      {isRoleControleSessaoRead && (
        <Card
          title={'Controle de sessão'}
          configuracaoUnidade={configuracaoUnidade}
          fields={[
            {
              value: 'utilizaControleSessao',
              label: 'Permitir que os usuários tenham acesso a múltiplas sessões',
            },
          ]}
          onChange={handleChange}
          disabled={!isRoleUnidadeUpdate || !isRoleControleSessaoRead}
        />
      )}
      <div className={classes.fullWidth}>
        <Card
          title={'Campos obrigatórios'}
          configuracaoUnidade={configuracaoUnidade}
          withChildrens
          childrenField={'camposSujeitoAtencao'}
          fields={[
            { value: CAMPOS_OBRIGATORIOS.DATA_NASCIMENTO, label: 'Data de nascimento' },
            { value: CAMPOS_OBRIGATORIOS.CPF, label: 'CPF' },
            { value: CAMPOS_OBRIGATORIOS.RG, label: 'RG' },
            { value: CAMPOS_OBRIGATORIOS.ENDERECO_RESIDENCIAL, label: 'Endereço residencial' },
            { value: CAMPOS_OBRIGATORIOS.CONVENIO, label: 'Convênio' },
          ]}
          onChange={handleChangeCamposObrigatorios}
          disabled={!isRoleUnidadeUpdate}
        />
      </div>
      <Card 
        title={'Sobre o paciente'}
        configuracaoUnidade={configuracaoUnidade}
        fields={[
          { value: 'permiteDuplicidadeCPF', label: 'Permite cadastrar pacientes com o mesmo CPF.' },
        ]}
        onChange={handleChange}
        disabled={!isRoleUnidadeUpdate}
      />
    </div>
  )
}

const styles = theme => ({
  container: {
    overflowY: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '24px',
    gridTemplateAreas: `'a b' 'c c'`,

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto auto',
      width: '70%',
    },

    '&>div': {
      height: 'fit-content',
    },
  },
  fullWidth: {
    gridArea: 'c',
  },
})

export default withStyles(styles)(Pacientes)
