import React, { useEffect, useState } from "react";
import moment from "moment";
import { inject } from 'mobx-react';
import { Fab, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from '@material-ui/icons/Person';
import {MeetingRoom} from '@material-ui/icons'
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckboxAtendimento from "../../components/Checkbox/CheckboxAtendimento";
import Dialog from '../../components/Dialog/Dialog';
import { InputForm } from '../../components/Input/InputDadosCadastraisPaciente'
import { findAgendamentoById } from '../../services/ProntuarioService'
import {getStatusAtendimento, String} from '../../utils';
import AlertDialog from '../../components/Dialog/AlertDialog';
import TelefoneFormatado from "../Input/TelefoneFormatado";
import PageTitle from "../PageTitle/PageTitle";
import RectLabel from "../Labels/RectLabel";
import ButtonWhatsApp from "../Button/ButtonWhatsApp";
import  {getDadosMensagem}  from "../../pages/Atendimento/utils/getDadosMensagem";
import { goToWhatsApp } from "../../utils/goToWhatsApp";
import ModalMensagemWhatsapp from "../../pages/Atendimento/ModalMensagemWhatsapp";
import { findAllDadosMensagemSelecionada } from "../../services/MensagensService";
import { findByUnidadeLogadaConfiguracaoUnidade } from "../../services/ConfiguracoesUnidadeService";
import { observer } from "mobx-react-lite";

const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "18px",
    color: "#26ACA9",
    fontWeight: 700,
    maxWidth: "230px",
    overflow: 'hidden',
    textOverflow: 'ellipsis' 
  },
  label: {
    display: "flex",
    alignItems: "center",
    color: "#505050",
    fontSize: "14px",
    fontWeight: 400,
    maxWidth: "220px",
    margin: "9px 0",
    "& > span": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginLeft: "8px"
    }
  },
  subtitle: {
    color: "#667595",
    fontSize: "12px",
    fontFamily: 'Poppins',
    lineHeight: '18px'
  },
  content: {
    marginTop: "10px"
  },
  fab: {
    width: "24px",
    height: "24px",
    minHeight: "24px",
    color: "#fff",
    marginLeft: "8px",
    boxShadow:
      "4px 4px 15px rgba(38, 172, 169, 0.05), 2px 2px 10px rgba(38, 172, 169, 0.1), 1px 1px 50px rgba(38, 172, 169, 0.15);",
  },
  editIcon: {
    backgroundColor: "#26ACA9",
    "&:hover": {
      backgroundColor: "#219A97"
    }
  },
  deleteIcon: {
    backgroundColor: "#F9BE73",
    "&:hover": {
      backgroundColor: " #E3922B"
    }
  },
  closeIcon: {
    backgroundColor: "#FB7676",
    "&:hover": {
      backgroundColor: "#c4454a"
    }
  },
  personIcon: {
    backgroundColor: '#707C97',
    "&:hover": {
      backgroundColor: "#5B667E"
    }
  },
  icon: {
    fontSize: "14px"
  },
  wrapperAtendimento:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '292px',
    height: '100%',
    minHeight: '450px',
    borderRadius: '10px',
    background: '#FFFFFF'
  },
  rootProntuario:{
    margin: '36px',
    width: '448px',
    height: '455px',
    left: '0px',
    top: '0px'
  },
  inputObservacao: {
    width: '95%',
    maxHeight: '100px',
    background: '#F2F2F2',
    border:' 1px solid rgba(220, 220, 220, 0.2)',
    borderRadius: 10,
    padding: '8px',
    
    '& textarea':{
      maxHeight: '85px'
    }
  },
  titleProcedimento:{
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#FFFFFF',
  },
  procedimentosText:{
    position: 'static',
    width: '260px',
    left: '0px',
    top: '0px',
    bottom: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    display: 'flex',
    alignItems: 'center',
    color: '#505050'
  },
  closeIconProcedimentos:{
    background:' #FB7676',
    width: "32px",
    height: "32px",
  },
  nomeProcedimento:{
    fontSize: "18px !important",
    color: "#26ACA9",
    fontWeight: 700,
    maxWidth: "300px",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  wrapperInfos:{
    display: 'grid',
    paddingBottom: '23px',
  },
  labelProcedimento:{
    '& label':{    
      width: '181px',
      height: '26px',
      paddingLeft: '8px',
    }
  },
  horizontaLine:{
    width: '260px',
    background:'#f2f2f2',
    opacity: 0.5
  },
  contentProcedimentos:{
    height: 'auto',
    maxHeight: '450px',
    overflow: 'overlay',
  },
  fullSizePaper: {
    display: "flex",
    borderRadius: 10,
    overflow: "unset",
    height: 'auto',
    width: 'auto',
    flexDirection: "row",
  },

  dialogPaperProntuario:{
    width: "100%",
  },
  wrapperProcedimentos:{
    display: "flex",
    padding: 20,
    borderRadius: "0px 10px 10px 0px",
    background: '#219A97',
    width: "100%",
    maxWidth: "300px",
  },
  wrapperHeaderProcedimento:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  titleSemProcedimentos:{
    margin: '20px 0 10px 0',
    textAlign: 'center'
  },
});

const InfosAgendamentoModal = observer( ({ 
  open, 
  event,
  classes,
  handleEditAgendamento,
  onClose,
  weekViewSala,
  handleOpenSujeitoEdit,
  serviceFields,
  fieldProcedimento,
  idSelected,
  atendimentoStore,
  filtroHeaderStore,
  calendarioSemanal,
}) => {

  const [prontuario, setProntuario] = useState({
    convenio: "",
    end: '',
    id: null,
    remoto: false,
    start: '',
    status: "",
    sujeitoAtencaoId: "",
    pacienteId: "",
    telefone: "",
    telefoneDDI: "",
    tipoConsulta: "",
    title: "",
    profissionalSaude: null,
    sala: null,
    horaInicio: null,
    horaFim: null,
    observacao: null,
    unidade: null,
    chavePublica: "",
    mensagemWhatsappEnviada: false,
  })
  const [deleteModalAgendamento, setDeleteModalAgendamento] = useState({
    openAlert: false,
    title: '',
    description: '',
  })
  const[ openModalMensagemWhatsapp, setOpenModalMensagemWhatsapp]=useState(false)
  const[dadosMensagem,setDadosMensagem]=useState(null)
  const [profissionalSelecionado, setProfissionalSelecionado]=useState(null)
  useEffect(() => {
    if(event){
      setProntuario(event)
    }
  }, [event])

  useEffect(() => {
   const profissional = filtroHeaderStore.profissionalSaude.id
   setProfissionalSelecionado(profissional)
  }, [])

  const handleExcluirAgendamento = (agendamento) => {
    if(atendimentoStore.tipoProfissionalLogado === 'tecnico') return;
    const situacao = agendamento.situacao || agendamento.status;
    const dia = agendamento.start ? moment(agendamento.start).format('DD/MM/YYYY') : moment(agendamento.data).format('DD/MM/YYYY');
    const horaInicio = agendamento.start ? moment(agendamento.start).format('HH:mm') : agendamento.horaInicio;
    const horaFim = agendamento.end ? moment(agendamento.end).format('HH:mm') : agendamento.horaFim;
    const titleMessage = situacao === 'BLOQUEADO' ? `Deseja excluir o bloqueio?` : `Deseja excluir a consulta de ${agendamento?.nome?.trim() || agendamento?.title}?`;

    setDeleteModalAgendamento({
      openAlert: true,
      title: titleMessage,
      description: `${dia} ${horaInicio && horaFim? `${horaInicio} - ${horaFim}`: ``}`,
    });
};

  const handleExcluirAgendamentoOk = async () => {
      try {
          const id = prontuario.id;
          await atendimentoStore.excluirAgendamento({id});
          onClose()
          handleExcluirAgendamentoCancel();
      } catch (error) {
          console.error(error);
      }
  };

  const handleExcluirAgendamentoCancel = () => {
      setDeleteModalAgendamento({
        openAlert: false,
        title: '',
        description: '',
      })
  };


  const getAgendamentoById = async() => {
    const response = await (await findAgendamentoById(idSelected)).data.data.findByIdAgendamento
    const { sujeitoAtencao, paciente, horaFim, horaInicio, remoto, situacao, tipo, nome, profissionalSaude, sala, observacao, id, data } = response
    setProntuario({
      convenio: sujeitoAtencao?.dadosConvenio?.convenio?.descricao,
      id,
      remoto,
      data: data,
      status:situacao,
      sujeitoAtencaoId: sujeitoAtencao?.id,
      pacienteId: paciente?.id,
      telefone: sujeitoAtencao?.contato?.telefonePrincipal,
      telefoneDDI: sujeitoAtencao?.contato?.telefonePrincipalDDI,
      tipoConsulta: tipo?.descricao,
      title: nome,
      profissionalSaude,
      sala,
      horaInicio: horaInicio,
      horaFim: horaFim,
      observacao,
    })
  }

  const handleClickWhatsapp = async (e) => {
    e.stopPropagation();
    const configuracoesUnidade = await (await findByUnidadeLogadaConfiguracaoUnidade()).data.data.findByUnidadeLogadaConfiguracaoUnidade
    const{utilizaMensagemPersonalizada,horasPermiteConfirmarAgendamento, utilizaEnderecoMensagemAgendamento}=configuracoesUnidade;
    const{profissionalSaude}=filtroHeaderStore;
    if(calendarioSemanal && utilizaMensagemPersonalizada){
      setOpenModalMensagemWhatsapp(true)
      const idMensagem = event.id;
      const mensagem= await findAllDadosMensagemSelecionada(idMensagem);
      setDadosMensagem(mensagem);
    }else{
        let message = "";
      const dadosMensagem = {
      nomePaciente: prontuario.title,
      nomeFantasiaUnidade: prontuario.nomeFantasiaUnidade,
      nomeProfissional: prontuario.profissionalSaude.nome,
      horaInicio: moment(prontuario.start).format('HH:mm') || " - ",
      data: prontuario.data,
      chavePublica: prontuario.chavePublica,
      remoto: prontuario.remoto,
      linkTelemedicina: prontuario.linkTelemedicina,
      horasPermiteAgendamento: horasPermiteConfirmarAgendamento,
      withEndereco: utilizaEnderecoMensagemAgendamento,
      endereco: prontuario?.endereco,
    };
    message = getDadosMensagem(dadosMensagem);
    atendimentoStore.increaseWhatsappMessageCounter(prontuario.id);
    return goToWhatsApp(prontuario.telefone, prontuario.telefoneDDI, message)
    }
    atendimentoStore.increaseWhatsappMessageCounter(prontuario.id);
  };

  const handleCLoseModalWhatsapp=()=>{
    setOpenModalMensagemWhatsapp(false)
  }


  useEffect(() => {
    if(idSelected){
    getAgendamentoById()
    }
  }, [idSelected])

  const statusColor = getStatusAtendimento(prontuario?.status);
  return (
    <>
      <PageTitle title="Atendimento - Informação agendamento"/>
      <AlertDialog
        open={deleteModalAgendamento.openAlert}
        alertTitle={deleteModalAgendamento.title}
        alertDescription={deleteModalAgendamento.description}
        onCancel={handleExcluirAgendamentoCancel}
        onOk={handleExcluirAgendamentoOk}
        loadingOk={atendimentoStore.cancelandoAgendamento}
      />
      <Dialog  open={open} maxWidth={'md'} fullWidth classes={{
        paper: classes.fullSizePaper,
        }}
        >
        <div className={classes.dialogPaperProntuario}>
      
        <div className={classes.rootProntuario}>
          <Grid className={classes.header}>
            <span className={ classes.nomeProcedimento}> {prontuario?.title} </span>
            <Grid>
            { !serviceFields && (
              <>
              <Fab 
                className={[classes.fab, classes.personIcon]} 
                onClick={() => handleOpenSujeitoEdit(prontuario)}
              >
                <PersonIcon className={classes.icon} />
              </Fab>
              </> 
              )}
              <Fab 
                className={[classes.fab, classes.editIcon]} 
                onClick={() => handleEditAgendamento(prontuario)}
              >
                <CreateOutlinedIcon className={classes.icon} />
              </Fab>

              <Fab 
                className={[classes.fab, classes.deleteIcon]} 
                onClick={() => handleExcluirAgendamento(prontuario)}
              >
                <DeleteIcon className={classes.icon} />
              </Fab>
            </Grid>
          </Grid>
          <Grid container className={classes.content}>
            <Grid xs={6}  className={classes.wrapperInfos}>
              <Grid className={classes.label}>
                <PhoneIcon />
                <span>
                  <TelefoneFormatado
                    telefone={prontuario?.telefone}
                    telefoneDDI={prontuario?.telefoneDDI}
                  />
                </span>
                {prontuario?.telefone && prontuario?.telefoneDDI && 
                  <ButtonWhatsApp 
                    messageSent={prontuario?.mensagemWhatsappEnviada}
                    labelTooltip='Mensagem enviada, deseja enviar novamente?'
                    onClick={(e) => handleClickWhatsapp(e)}
                  />
                }
              </Grid>
              <Grid className={classes.label}>
                <EventIcon />
                <span>
                {!serviceFields ? moment(prontuario.start).format("DD / MM / YYYY") :  moment(prontuario.data ).format("DD / MM / YYYY")}
                </span>
              </Grid>
              <Grid className={classes.label}>
                <ScheduleIcon /> 
                {!serviceFields ? (                
                <span>
                  {moment(prontuario.start).format("HH:mm")} às {moment(prontuario.end ).format("HH:mm")}
                </span>)
                : (
                <span>
                  {prontuario?.horaInicio} às {prontuario?.horaFim}
                </span>
                )
                }
              </Grid>
              <Grid className={classes.label}>
                <RoomIcon />
                <span>
                  {prontuario?.remoto ? 'Remoto' : 'Presencial'}
                </span>
              </Grid>
              <Grid className={classes.label}>
                <MeetingRoom />
                <span>
                  {prontuario?.sala?.nome}
                </span>
              </Grid>
              <Grid>
                <Grid className={classes.subtitle}>Origem</Grid>
                <RectLabel 
                  withPadding 
                  text={prontuario.pacienteId ? 'Vizi Saúde' : 'App Health'} 
                  color={prontuario.pacienteId ? '#6639F4' : '#229A97'}
                  areaStyle={{ margin: 0 }} 
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {!weekViewSala && (<Grid>
                <span className={classes.subtitle}>Tipo de Consulta</span>
                <Grid className={classes.label}>
                  <AssignmentIcon />
                  <span>
                    { prontuario.tipoConsulta}
                  </span>
                </Grid>
              </Grid>)}
              <Grid>
                <span className={classes.subtitle}>Convênio</span>
                <Grid className={classes.label}>
                  <BusinessIcon />
                  <span>
                    {prontuario.convenio}
                  </span>
                </Grid>
              </Grid>
              <Grid>
                <span className={classes.subtitle}>Profissional</span>
                <Grid className={classes.label}>
                  <PersonIcon />
                  <span>
                    {prontuario?.profissionalSaude?.nome}
                  </span>
                </Grid>
              </Grid>
              <Grid>
              <Grid className={classes.subtitle}>Situação</Grid>
                <Grid className={classes.labelProcedimento}>
                  <CheckboxAtendimento
                    checked={true}
                    color={statusColor.backgroundColor}
                    label={prontuario?.status && String.capitalize(prontuario.status)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{marginTop: '20px'}}>
            <Grid className={classes.subtitle}>Observações:</Grid>
            <Grid>
              <InputForm
                className={classes.inputObservacao}
                rowsMax={20}
                rows={5}
                multiline
                alternativeInputClass={classes.inputNome}
                value={prontuario?.observacao}
                disabled
              />
            </Grid>
          </Grid>
        </div>
        </div>
        
        <div className={classes.wrapperProcedimentos}>
        
        <div>
          <div className={classes.wrapperHeaderProcedimento}>
            <Grid className={classes.titleProcedimento}>Procedimentos solicitados</Grid>
            <Fab 
            className={[classes.fab, classes.closeIconProcedimentos]} 
            onClick={onClose}
            >
            <CloseIcon style={{ fontSize: 20 }} className={classes.icon} />
            </Fab>
          </div>
          <>
          <div className={classes.contentProcedimentos}>
          <div className={classes.wrapperAtendimento}>
          {fieldProcedimento?.length > 0 ? (
            <>
              {fieldProcedimento?.map((procedimento, index) => (
                <>
                  <p key={procedimento + index} className={classes.procedimentosText}>{procedimento.procedimento.nome}</p>
                  <hr className={classes.horizontaLine}/>
                </>
              ))}
            </>
          ):(
            <>
              <div className={classes.titleSemProcedimentos}>Não há procedimentos</div>
              <hr className={classes.horizontaLine}/>
            </>
          )}
          </div>
          </div>
          </>
        </div>
        </div>
        {<ModalMensagemWhatsapp 
        open={openModalMensagemWhatsapp} 
        close={handleCLoseModalWhatsapp}
        dadosMensagem={dadosMensagem}
        profissionalSaudeSelecionado={profissionalSelecionado}
        />

        }
      </Dialog>
    </>
  );
});

export default withStyles(styles)(inject('atendimentoStore','filtroHeaderStore','unidadeStore')(InfosAgendamentoModal));
