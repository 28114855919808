import React, { useState, useEffect, useRef } from 'react';

import { withStyles, Fab, CircularProgress } from '@material-ui/core';
import { style } from './style';

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import { criarEImprimirDocumento } from '../../../../services/DocumentoService';
import { imprimirDocumentoAssinado } from '../../../../services/ImpressaoService';
import localStorageService, { ACCESS_TOKEN_KEY } from '../../../../services/storage';

import InformacoesImportantesModal from '../../../Atendimento/InformacoesImportantes/InformacoesImportantesModal';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Dialog from '../../../../components/Dialog/Dialog';
import CloseIcon from '../../../../components/Icon/CloseIcon';
import ModelDocumentEditor from '../../../../components/ModelDocumentEditor';
import { Button } from '../../../../components/ui/Buttons';
import PrintIcon from '../../../../components/Icon/Print';
import TokenDialog from '../../../../components/Dialog/TokenDialog';
import InputTokenDialog from '../../../../components/Dialog/InputTokenDialog';
import { InputForm } from '../../../../components/Modal/Input';

import { checkUserRole } from '../../../../utils/checkUserRole';
import { isIOS } from '../../../../utils/OS';
import printPage from '../../../../utils/printPage';
import base64ToBlob from '../../../../utils/base64ToBlob';
import string from '../../../../utils/string';
import { buildUrlDownload } from '../../../../config/config';
import HorizontalLineIcon from '../../../../components/Icon/HorizontalLineIcon';
import { ReactSelect } from '../../../../components/Select/SelectSearch';

const PreencherDocumento = observer(({
  classes, 
  open, 
  isDuplicar, 
  previewDocumento, 
  screen, 
  handleClose,
  options,
  sujeitoAtencaoId,
  configuracaoImpressaoStore,
  profissionalSaudeStore,
  prontuarioStore,
  documentoStore,
  receituarioStore,
  modeloDocumentoStore,
  history,
  handleMinimize,
}) => {
  const [loadingButtonImpressao, setLoadingButtonImpressao] = useState(false);
  const [documento, setDocumento] = useState(null);
  const [inputs, setInputs] = useState(null);
  const [camposEntrada, setCamposEntrada] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(true);
  const [showInformacoesImportantesModal, setshowInformacoesImportantesModal] = useState(false);
  
  const [printType, setPrintType] = useState('');
  const [isSigned, setIsSigned] = useState(false);
  const [openSignQuestionDialog, setOpenSignQuestionDialog] = useState(false);
  const [openTokenDialog, setOpenTokenDialog] = useState(false);
  const [signToken, setSignToken] = useState('');
  const [tokenError, setTokenError] = useState(false);
  const [tokenDialogActionClicked, setTokenDialogActionClicked] = useState(false);
  const [menuIsOpen,setMenuIsOpen]=useState(true);

  const editorRef = useRef(null);
  const notInitialRender = useRef(false);
  const prevOptionsRef = useRef(null);

  useEffect(() => {
    setDefaultOptions();
  }, [])

  useEffect(() => {
    if (documento) {
      setInitialDocumentConfig();
    }
  }, [documento]);

  useEffect(() => {
    const optionsChanged =  options instanceof Array &&
      prevOptionsRef.current instanceof Array &&
      options.length !== prevOptionsRef.current.length;

    if (optionsChanged) {
      setDefaultOptions();
    }

    prevOptionsRef.current = options;
  }, [options]);

  const setInitialDocumentConfig = async () => {
    const { sujeitoAtencaoSelected } = prontuarioStore;
    await profissionalSaudeStore.carregaProfissionalSaude();
    await prontuarioStore.checkUseDigitalSignature();
    await configuracaoImpressaoStore.getConfig('DOCUMENTO');
    const roleEntradaProntuarioRead = await checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
    const showInformacoesImportantesModal = roleEntradaProntuarioRead && sujeitoAtencaoSelected?.informacoesImportantes?.length > 0;
    setshowInformacoesImportantesModal(showInformacoesImportantesModal);
  };

  const setDefaultOptions = () => {
    if (options instanceof Array && options.length === 1) {
      setDocumento({
        ...options[0],
        label: options[0].name,
      });
      handleSetCamposEntrada(options[0]);
    }
    if(isDuplicar) {
      setDocumento({
        ...previewDocumento,
        label: previewDocumento.content.name,
      });
      setLoadingDocument(false);
    }
  };
  
  const handleSetCamposEntrada = (value) => {
    if (value) {
      const { camposEntrada } = value;
      setCamposEntrada(camposEntrada || []);
      setLoadingDocument(true);
      if(camposEntrada?.length > 0) {
        let inputs = {};
        camposEntrada.forEach((campoEntrada => {
          inputs[campoEntrada?.campo] = { valor: "", campo: campoEntrada.campo };
        }));

        setInputs(inputs);
      } else {
        setInputs(null);
      }
    setLoadingDocument(false);
    }
  }

  const handleDocumentoChange = (e) => {
    setMenuIsOpen(false)
    setDocumento(e);
    handleSetCamposEntrada(e);
    };
    
  const handleDocumentoSearch = (e) => {
    e && !menuIsOpen && setMenuIsOpen(true)
  };

  useEffect(() => {
    if (notInitialRender.current) {
      const { possuiToken } = receituarioStore;

      const isPrintingUnsigned = tokenDialogActionClicked && !isSigned && printType === 'unsigned';
      const isPrintingSigned = tokenDialogActionClicked && isSigned && (signToken || possuiToken) && printType === 'signed';


      if (isPrintingSigned) {
        onPrintSigned();
      }

      if (isPrintingUnsigned) {
        onPrintUnsigned()
      }

    } else {
      notInitialRender.current = true;
    }
  }, [isSigned, signToken, tokenDialogActionClicked, printType]);

  const handleClickPrint = async () => {
    const {assinaturaDigitalObrigatoria} = prontuarioStore;
    const {utilizaAssinaturaDigital} = profissionalSaudeStore.profissionalSaude
    const openSignDialog = assinaturaDigitalObrigatoria || utilizaAssinaturaDigital;
    if (openSignDialog) {
      setOpenSignQuestionDialog(true);
    } else {
      handlePrintDocumentUnsigned()
    }
  };

  const handlePrintDocumentUnsigned = () => {
    setIsSigned(false);
    setOpenSignQuestionDialog(false);
    setTokenDialogActionClicked(true);
    setPrintType('unsigned')
  };

  const handlePrintDocumentSigned = async () => {
    setTokenDialogActionClicked(true);
    receituarioStore.possuiToken = await receituarioStore.possuiTokenAssinaturaValido()

    const { possuiToken } = receituarioStore;

    setPrintType('signed');
    setOpenSignQuestionDialog(false);
    setOpenTokenDialog(!possuiToken);
    setIsSigned(possuiToken);
  };

  const handleCancelSignedPrint = () => {
    resetTokenDialogs()
  };

  const onPrintUnsigned = async () => {
    setLoadingButtonImpressao(true);

    if (!isDuplicar && contemErros()) {
      resetTokenDialogs();
      return setLoadingButtonImpressao(false);
    }

    if (isIOS) {
      const newWindowRef = window.open('', 'print_documento');

      if (newWindowRef) {
        const loadingContent = `<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
          <h1 style="font-family: -apple-system;">Aguarde...</h1>
        </div>`;
        newWindowRef.document.open();
        newWindowRef.document.write(loadingContent);
        newWindowRef.document.close();
      }
    }

    const variablesDTO = buildVariablesDTO();

    try {
      const response = await criarEImprimirDocumento(variablesDTO);
      const conteudoImpressao = response?.data?.data?.criarEImprimirDocumento;

      if (!conteudoImpressao) {
        setTokenError(true);
        throw response.data.errors[0];
      }

      printPage(base64ToBlob(conteudoImpressao?.pdf));
      setLoadingButtonImpressao(false);
      resetTokenDialogs();
      handleClose();
    } catch(error) {
      setLoadingButtonImpressao(false);
      resetTokenDialogs();
      console.error(error);
    }
  };

  const handleSubmitSignToken = async () => {
    setLoadingButtonImpressao(true);
    setPrintType('signed');
    setIsSigned(true)
  };

  const onPrintSigned = async () => {
    if (!isDuplicar && contemErros()) {
      resetTokenDialogs();
      return setLoadingButtonImpressao(false);
    }

    setLoadingButtonImpressao(true);
    setTokenDialogActionClicked(false);

    let variablesDTO = buildVariablesDTO();
    if (!receituarioStore?.possuiToken) {
      variablesDTO.tokenAplicativo = signToken.replace(' ', '');
    }

    try {
      const response = await imprimirDocumentoAssinado(variablesDTO);
      if (response.data.errors || !response.data.data.criarEImprimirAssinadaDocumento) {
        throw response.data.errors[0];
      }
      const token = await localStorageService.get(ACCESS_TOKEN_KEY);
      const { entradaProntuario } = response?.data?.data?.criarEImprimirAssinadaDocumento;
      window.open(buildUrlDownload(
        entradaProntuario?.urlDownload || response, 
        token,
        "_blank"
      ));
      resetTokenDialogs();
      handleClose();
    } catch(error) {
      setLoadingButtonImpressao(false);
      setTokenError(true);
      setIsSigned(false);
      setTokenDialogActionClicked(true);
      console.error(error);
    }
  };

  const buildVariablesDTO = () => {
    const modelDucumentChanged = editorRef.current.handleSubmitFromChild();

    let variablesDTO = {
      sujeitoAtencao: {
        id: sujeitoAtencaoId,
      },
    };

    if (isDuplicar) {
      variablesDTO.nome = previewDocumento.content.nome;
      variablesDTO.documento = modelDucumentChanged;
    } else {
      variablesDTO.camposEntrada = camposEntrada.reduce((acc, campo) => {
        const input = { ...inputs[campo.campo], error: undefined };
        return input ? [...acc, input] : acc;
      }, []);

      variablesDTO.documentoModelo = {
        id: documento.value,
        modelo: modelDucumentChanged,
        nome: documento.name
      };
    }
    return variablesDTO;
  }


  const contemErros = () => {
    if (!documento) return true;
    if (!inputs) return false;

    let updatedInputs = {
      ...inputs,
    }

    Object.keys(updatedInputs).forEach((input) => {
      updatedInputs[input].error = string.isEmpty(inputs[input].valor); 
    });
  
    return Object.keys(updatedInputs).reduce((acc, input) => {
        const error = updatedInputs[input].error;
        return acc || Boolean(error);
      }, false);
  };

  const resetTokenDialogs = () => {
    setOpenTokenDialog(false);
    setOpenSignQuestionDialog(false);
    setTokenError(false);
    setSignToken('');
    setTokenDialogActionClicked(false);
    setPrintType('')
  };

  const handleInputClick = (event, campo) => {
    let updatedInputs = inputs;
    updatedInputs[campo] = { valor: event.target.value, campo };

    setInputs(updatedInputs);
  };

  const handlePushToModelosDocumentos = () => {
    history.push('/configuracoes/modelos-documento');
  };

  const noOptions = !documento && options?.length === 0;

  return (
    <>
      <PageTitle title={`${screen} - Documento`} />
      <Dialog
        classes={{ 
          paper: showInformacoesImportantesModal ? classes.fullSizePaper : classes.paper
        }}
        open={open}
        keepMounted
        fullWidth
      >
        {showInformacoesImportantesModal && 
          <div>
            <InformacoesImportantesModal />
          </div>
        }
        <div className={classes.dialogContainer}>
          <div className={classes.dialogHeader}>
            <div className={classes.headerArea}>
              <span className={classes.modalTitle}>Documento</span>
              <div className={classes.wrapperButtons}>
                <Fab 
                  className={classes.minimizeButton}
                  onClick={handleMinimize}
                >
                  <HorizontalLineIcon color="white"/>
                </Fab>
                <Fab 
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon color="white"/>
                </Fab>
              </div>
            </div>
            {!isDuplicar && (
              <div className={classes.searchArea} onClick={()=> setMenuIsOpen(!menuIsOpen)}>
                <label className={classes.searchInputLabel}>
                  Pesquisar documentos
                </label> 
                <ReactSelect
                  autoFocus
                  menuIsOpen={menuIsOpen}
                  customStylesSelect={{
                    menu: {
                      maxHeight: '300px',
                    }
                  }}
                  className={classes.selectDocumento}
                  value={documento}
                  options={options || []}
                  onChange={handleDocumentoChange}
                  onInputChange={handleDocumentoSearch}
                  getOptionLabel={(option) => option.label}
                />
              </div>
            )}
            {loadingDocument && documento && (
              <CircularProgress color='primary' className={classes.loading} />
            )}
            {documento && !isDuplicar && camposEntrada.length > 0 && (
              <div className={classes.inputsContainer}>
                {camposEntrada.map((input) => (
                  <div
                    key={`input-document-${input.campo}`}
                    className={classes.inputLabel}
                  >
                    <label>{input.campoAmigavel}</label>
                    <InputForm
                      error={inputs && inputs[input.campo] ? 
                        inputs[input.campo]?.error : 
                        null
                      }
                      onChange={(e) => handleInputClick(e, input.campo)}
                      placeholder={input.campoAmigavel}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classes.dialogContent}>
            {documento && (
              <ModelDocumentEditor
                classes={{
                  textAreaWrapper: classes.textEditorWrapper,
                  scrollEditor: classes.textEditorScroll,
                  editor: classes.editor,
                  jodit: classes.jodit,
                }}
                id={documento?.value}
                key={documento?.value}
                refChild={editorRef}
                disabledButtons={['image']}
                textoHtml={previewDocumento?.content?.documento}
                isDuplicar={isDuplicar}
                modeloDocumentoStore={modeloDocumentoStore}
              />
            )}
            {noOptions && (
              <div className={classes.emptyContainer}>
                <span className={classes.emptyMessage}>
                  Nenhum documento foi criado
                </span>
                <span onClick={handlePushToModelosDocumentos} className={classes.emptyMessageLink}>
                  Criar documento
                </span>
              </div>  
            )}
          </div>
          <div className={classes.dialogFooter}>
            <Button 
              width="50%" 
              onClick={handleClickPrint} 
              disabled={loadingButtonImpressao || noOptions}
              isLoading={loadingButtonImpressao}
            >
              <div className={classes.printButtonContent}>
                <PrintIcon color="white" size={20} />
                Imprimir
              </div>
            </Button>
          </div>
        </div>
        <TokenDialog
          open={openSignQuestionDialog}
          handleClose={resetTokenDialogs}
          onCancel={handlePrintDocumentUnsigned}
          onOk={handlePrintDocumentSigned}
          isLoading={tokenDialogActionClicked}
        />
        <InputTokenDialog
          inputValue={signToken}
          onChange={(e) => setSignToken(e.target.value)}
          open={openTokenDialog}
          onCancel={handleCancelSignedPrint}
          onOk={handleSubmitSignToken}
          error={tokenError}
          loading={loadingButtonImpressao}
          canPrintSigned={profissionalSaudeStore.canPrintSigned}
          history={history}
        />
      </Dialog>
    </>
  )
});

const stores = ['atendimentoStore', 'prontuarioStore', 'configuracaoImpressaoStore', 'profissionalSaudeStore', 'receituarioStore', 'documentoStore', 'modeloDocumentoStore'];
const PreencherDocumentoStyle = withStyles(style)(PreencherDocumento);
export default inject(...stores)(PreencherDocumentoStyle);
