import Api from "../config/api";

export const saveProcedimento = async(procedimento) => {
  const method = procedimento.id ? 'updateProcedimento' : 'createProcedimento';
    const response = await Api.post("", {
      query: `
        mutation ($procedimento: ProcedimentoInput) {
          ${method}(procedimento: $procedimento) {
            id
            nome
            unidade {
              id
            }
            convenioProcedimentos {
              convenio {
                id
              }
              procedimento {
                id
              }
              valor
            }
          }
        }`,
    variables: { procedimento }
  });

  if (response?.data?.errors) throw new Error(response.data.errors[0].message)
  return response.data.data[method]
  
};

export const findByIdProcedimento = id => Api.post("", {
  query: `
    query ($id: UUID) {
      findByIdProcedimento(id: $id) {
        nome
        ativo
        tiposProcedimentos
        tuss22ProcedimentoEventoSaude {
          id
          codigoTermo
          descricao
          codigoTermoDescricao
          localOdontograma {
            id
          }
        }
        convenioProcedimentos {
          convenio {
            id
          }
          procedimento {
            id
          }
          valor
        }
        produtoProcedimentos {
          produto {
            id
          }
          quantidade
        }
      }
    }`,
  variables: { id }
});

export const findAllProcedimentos = (variables) => Api.post("", {
      query: `
      query ($nome: String, $ativo: Boolean, $pageableDTO: PageableDTOInput, $salaId: UUID) {
        findAllProcedimentos(nome: $nome, ativo: $ativo, pageableDTO: $pageableDTO, salaId: $salaId) {
          last
          content {
            id
            nome
            ativo
            unidade {
              id
            }
            tuss22ProcedimentoEventoSaude {
              codigoTermo
              descricao
              codigoTermoDescricao
            }
            convenioProcedimentos {
              procedimento {
                id
                nome
              }
              convenio {
                id
                descricao
              }
              valor
            }
            produtoProcedimentos {
              procedimento {
                id
                nome
              }
              produto {
                id
                nome
              }
              quantidade
            }
          }
        }
      }
      
  `,
  variables: variables

});

export const findAllProcedimentosAtendimento = async (variables = {}) => {
  const searchDTODefault = {
    pageableDTO:{
      pageSize: 999,
      sortDir: "ASC",
      sortField: "nome",
    },
    nome: '',
    ativo: true
  }

  const response = await Api.post("", {
  query: `
  query ($ativo: Boolean, $pageableDTO: PageableDTOInput, $nome: String, $profissionalSaudeId: Long) {
    findAllProcedimentos(ativo: $ativo, pageableDTO: $pageableDTO, nome: $nome, profissionalSaudeId: $profissionalSaudeId) {
      last
      content {
        id
        nome
        ativo
        convenioProcedimentos {
          convenio {
            id
          }
          valor
        }
      }
    }
  }
`,
  variables: {
    ...searchDTODefault,
    ...variables
  }
});

  return response.data.data.findAllProcedimentos
};

export const findAllProcedimentosRelatorio = async (nome) => {
  const pageableDTO = {
    sortDir: "ASC",
    sortField: "nome",
    pageSize: 999,
    pageNumber: 0
  }  

  const response = await Api.post("", {
    query: `
    query ($nome: String, $ativo: Boolean, $pageableDTO: PageableDTOInput) {
      findAllProcedimentos(nome: $nome, ativo: $ativo, pageableDTO: $pageableDTO) {
        content {
          id
          nome
        }
      }
    }
  `,
    variables: {
      nome: nome || "",
      ativo: true,
      pageableDTO
    }
  });

  return response.data.data.findAllProcedimentos.content;
};

export const procedimentoNomeJaExistente = async search => {
  const response =  await Api.post("", {
  query: `
    {
      procedimentoNomeJaExistente(search: "${search}")
    }`
  })
  
  return response.data.data.procedimentoNomeJaExistente;
};

export const findAllProcedimentosNormaisEOdontologicos = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($convenioId: Long, $ativo: Boolean, $pageableDTO: PageableDTOInput, $nome: String, $tiposProcedimentos: [TipoProcedimento], $localOdontograma: LocalOdontogramaInput) {
        findAllProcedimentos(convenioId: $convenioId, ativo: $ativo, pageableDTO: $pageableDTO, nome: $nome, tiposProcedimentos: $tiposProcedimentos, localOdontograma: $localOdontograma) {
          last
          content {
            id
            nome
            convenioProcedimentos {
              valor
              convenio {
                id
              }
            }
          }
        }
      }
    `,
    variables,
  })

  if (response.data.errors) throw Error(response.data.errors[0].message);

  return response.data.data.findAllProcedimentos;
}