import React from "react";
import debounce from "lodash.debounce";
import { observer, inject } from "mobx-react";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles/index";
import string from '../../utils/string';

import Notification from "../../components/Notification";

import Moment from "moment";

import styles from "../../assets/jss/pages/prontuarioAtendimentoStyle";
import classNames from "classnames";

import Receituario from "./Receituario/Receituario";
import ReceituarioModelo from "./Receituario/ReceituarioModelo";
import VincularSujeitoAlertDialog from "./Prontuario/VincularSujeitoAlertDialog";
import AtendimentoModal from "./AtendimentoModal";
import TermoUsoModal from "../Dashboard/TermoUso/TermoUsoModal";

import PreencherDocumento from "../Configuracoes/ModelosDocumento/Documento/PreencherDocumento";
import Api from "../../config/api";
import localStorageService, { ATENDIMENTOS_EM_ANDAMENTO } from '../../services/storage';
import Imprimir from "./Prontuario/Imprimir";
import SADTModal from "./SADT/SADTModal";
import SolicitacaoProcedimentoModal from "./SolicitacaoProcedimento/SolicitacaoProcedimentoModal";
import AnamneseModal from "./Anamnese/AnamneseModal";
import PersonalizarCamposProntuario from "./Prontuario/PersonalizarCamposProntuario"
import {db} from "../../config/config";
import EncerrarTeleconsultaModal from "./Telemedicina/EncerrarTeleconsultaModal";

import Footer from "../../components/Footer/Footer";

import { defaultObjView, TypeModalEnum } from "../../stores/SADT.store";
import { TypeModalEnumReceituario } from "../../stores/Receituario.store";
import ModalProcedimentosRealizados from "../SujeitoAtencao/Manutencao/ModalProcedimentosRealizados";

import InfosAgendamentoModal from '../../components/Modal/InfosAgendamentoModal';

import InformacoesImportantesModal from "./InformacoesImportantes/InformacoesImportantesModal";
import AvaliacaoAntropometricaModal from "./AvaliacaoAntropometrica/AvaliacaoAntropometricaModal";
import AfericaoVitalModal from "./AfericaoVital/AfericaoVitalModal";
import { getProfissionalSaudeLogado } from "../../services/UsuarioService";
import PageTitle from "../../components/PageTitle/PageTitle";
import { tabs, tabsContent } from "../../stores/Prontuario.store";

import HeaderProntuario from "./HeaderProntuario";

import ModalSolicitacoes from "./ModalSolicitacoes";
import { quantitySolicitacoes } from "../../services/SolicitacoesProntuarioService";
import MultiToggleButtons from "../../components/Button/MultiToggleButtons";
import Prontuario from "./Tabs/Prontuario/Prontuario";
import OptionsProntuario from "./Prontuario/components/OptionsProntuario";
import Odontograma from "./Tabs/Odontograma/Odontograma";
import DrawerProntuario from "./Prontuario/components/DrawerProntuario";
import ModalConfirmarPresenca from "./ModalConfirmarPresenca";
import { countAgendamentosAtendendo, findSujeitoAtencaoById } from "../../services/ProntuarioService";
import ModalConfirmacao from "../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import InfoIconFilled from "../../components/Icon/InfoIconFilled";
import CloseIcon from "../../components/Icon/CloseIcon";
import { Button } from "../../components/ui/Buttons";
import ModalAtendimentos from "./ModalAtendimentos";
import MinimizedTaskbar from "./MinimizedTaskbar";
import ModalCID from "./ModalCID";
import ModalGuiaInternacao from "../Configuracoes/ModelosDocumento/GuiaInternacao/ModalGuiaInternacao";
import ConfirmAlertModal from "../../components/ConfirmModal/ConfirmAlertModal";

const MODAL = {
    RECEITUARIO: 'receituario',
    DOCUMENTO: 'documento',
    ANAMNESE: 'anamnese',
}

let page = {
    pageNumber: 0,
    pageSize: 9999
};

class ProntuarioPainelHOC extends React.Component {
    shouldComponentUpdate(prevProps, prevState) {
        return !this.props.dateState;
    }

    render() {
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

@inject("atendimentoStore", "prontuarioStore", "SADTStore", "sujeitoAtencaoStore", "googleAnalyticsStore", "receituarioStore", "termoUsoStore", "usuarioStore", "extratoStore", "odontogramaStore",  "chatStore", "guiaInternacaoStore")
@observer
class ProntuarioAtendimento extends React.Component {

    constructor(props) {
        super(props);

        this.anchorBottomMessagesRef = React.createRef();
        this.documentoFileInput = React.createRef();
        this.agendamentoPulling = setInterval(this.findAllAgendamentoWithFilter, 10000);
        this.blockPage = this.props.history.block(this.onBlock);

        this.debounceFindAllAgendamento = debounce(
            this.debounceFindAllAgendamento,
            500
        );
        this.debounceUpdateCampoProntuario = debounce(
            this.debounceUpdateCampoProntuario,
            1000
        );

        this.pageNumberEntradas = 0;

        this.state = {
            openImprimir: false,
            openPreencherDocumento: false,
            openPersonalizarCamposProntuario: false,
            documentos: [],
            dataFilter: true,
            today: moment(new Date()),
            changeDate: moment(new Date()),
            dateState: false,
            open: true,
            selectedCamposProntuario: [],
            showProcedimentos: false,
            agendamentoInfo: null,
            usuarioLogado: undefined,
            teleconsultaEncerrado: false,
            tempoTeleconsulta: { inicio: undefined, fim: undefined },
            openProcedimentoRealizadoModal: false,
            procedimentoRealizadoId: '',
            showModalSolicitacaoProcedimento: false,
            showModalSocilicitacoes: false,
            quantitySolicitacoes: 0,
            showModalAnamnese: false,
            anamneseSelecionado: null,
            isDuplicar: false,
            showModalConfirmarPresenca: false,
            openInformacoesImportantes: false,
            openAvaliacaoAntropometrica: false,
            avaliacaoAntropometricaSelecionado: null,
            isNovoLancamento: false,
            openTriagem: false,
            triagemSelecionado: null,
            profissionalSaudeAtual: null,
            unidadeAtual: null,
            prontuarioSelected: null,
            tabContentSelected: tabsContent.PRONTUARIO,
            modalsStates:{
                receituario: 'closed',
                documento: 'closed',
                anamnese: 'closed',
            },
            modalEditIndex: null,
            showModalDadosMinimizados: false,
            sujeitoAtencaoToSetOnAgendamento: null,
            openModalAtendimento: false,
            openModalCID: false,
            openModalCIDFromStop: false,
            openModalGuiaInternacao: false,
            openModalWriteMessage: false,
            disabledChamadaPaciente: false,
            agendamentoId: null,
        };
    }

    async componentDidMount() {
        const { prontuarioStore, SADTStore, usuarioStore, atendimentoStore, odontogramaStore } = this.props;
        odontogramaStore.isTelaAmpliada = false;
        const profissionalSaudeLogado = await getProfissionalSaudeLogado();
        const unidadeLogada = await usuarioStore.getUnidadeAtual();
        atendimentoStore.loadAgendamentoTipo()

        this.setState({
            profissionalSaudeAtual: profissionalSaudeLogado,
            unidadeAtual: unidadeLogada
        });

        SADTStore.typeModal = TypeModalEnum.SADT_MODAL;
        
        prontuarioStore.dataEntrada = null;

        prontuarioStore.checkUserRoleEntradaProntuario();
        prontuarioStore.checkUtilizaConfirmacaoPresenca();
        prontuarioStore.checkUtilizaCIDObrigatorio();

        prontuarioStore.tabSelected= tabs.TODOS;
        this.listarAgendamentosESelecionarAgendamento();

        db.get("usuarioLogado", "{}")
            .then(usuarioLogadoString => {
                const usuarioLogado = JSON.parse(usuarioLogadoString);
                if (usuarioLogado) {

                    this.setState({ usuarioLogado });

                    if (unidadeLogada) {
                        Api.post("", {
                            query: `
                                query ($profissionalSaudeId: Long, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
                                    findAllDocumentosModelo(profissionalSaudeId: $profissionalSaudeId, ativo: $ativo, search: $search, pageableDTO: $pageableDTO) {
                                        content {
                                            camposEntrada{
                                            campo
                                            campoAmigavel
                                            campoCompleto
                                            valor
                                            }
                                            camposSistema{
                                            campo
                                            descricao
                                            entidade
                                            entidadeComCampo
                                            entidadeEnum
                                            }
                                            modelo
                                            name: nome
                                            value: id
                                            label: nome
                                        }
                                    }
                                }`,
                            variables: {
                                ativo: true,
                                search: "",
                                pageableDTO: {
                                    ...page,
                                    sortDir: 'ASC', 
                                    sortField: 'nome',
                                }  
                            }
                        })
                            .then(({ data: response }) => {
                                this.setState({
                                    documentos: response.data.findAllDocumentosModelo.content,
                                    isLoading: false
                                });
                            })
                            .catch(() => {
                                this.props.prontuarioStore.openNotification('Erro ao carregar documentos modelo', 'error');
                            });
                    }
                }
            });

        const selectedCamposProntuario = await this.props.prontuarioStore.findAllCampoProntuarioByProfissionalSaudeLogadoList()
        this.props.prontuarioStore.selectedCamposProntuario = selectedCamposProntuario
        this.setState({selectedCamposProntuario})

        let pagina = {
            page: window.location.hash, 
            title: 'Prontuario Atendimento'
        }
        this.props.googleAnalyticsStore.pageView(pagina);

        if (this.props.sujeitoAtencaoStore.sujeitoToSetOnAgendamento) {
            this.openModalAtendimento();
        }

        window.addEventListener('beforeunload', this.clearRequestsInterval);
    }

    clearRequestsInterval = () => {
        clearInterval(this.agendamentoPulling); 
    }

    componentWillUnmount() {
        const { prontuarioStore } = this.props;
        prontuarioStore.resetPacienteAndAgendamentoSelected();

        this.clearRequestsInterval();
        window.removeEventListener('beforeunload', this.clearRequestsInterval);
        if (prontuarioStore.previewDocumento.open) {
            prontuarioStore.closePreviewDocumento();
        }   
    }

    findAllAgendamentoWithFilter = async () => {
        let dateFilter = {
            dataInicial: this.state.changeDate
                .startOf("day")
                .format("YYYY-MM-DD"),
            dataFinal: this.state.changeDate.endOf("day").format("YYYY-MM-DD")
        };
        await this.findAllAgendamento(dateFilter, false);
    };

    handleShowProcedimentos = async(agendamento) => {
        const {showProcedimentos} = this.state
    
        const procedimentosNaoRealizado = agendamento?.procedimentos && agendamento?.procedimentos?.filter(item => !item.realizado)
        this.setState({
            showProcedimentos: !showProcedimentos, 
            agendamentoInfo: procedimentosNaoRealizado,
            prontuarioSelected: agendamento
        })
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    updateSelectedCamposProntuario = async (campo) => {
        const selectedCamposProntuario = await this.props.prontuarioStore.findAllCampoProntuarioByProfissionalSaudeLogadoList()
        this.props.prontuarioStore.selectedCamposProntuario = selectedCamposProntuario
        this.setState({selectedCamposProntuario});
    }

    debounceFindAllAgendamento() {
        let dateFilter = {
            dataInicial: this.state.changeDate
                .startOf("day")
                .format("YYYY-MM-DD"),
            dataFinal: this.state.changeDate.endOf("day").format("YYYY-MM-DD")
        };

        this.findAllAgendamento(dateFilter, false);
    }

    debounceUpdateCampoProntuario(value, field) {
        this.props.prontuarioStore.updateSujeitoAtencaoNoAtendimento(value, field);
    }

    findAllAgendamento = async (filter = {}, isShowLoading = true) => {
        try {
            const { prontuarioStore } = this.props;
            await prontuarioStore.findAllAgendamento(filter, isShowLoading);
        } catch (error) {
            console.error(error);
        }
    };

    listarAgendamentosESelecionarAgendamento = () => {
        let dateFilter = {
            dataInicial: this.state.changeDate
                .startOf("day")
                .format("YYYY-MM-DD"),
            dataFinal: this.state.changeDate.endOf("day").format("YYYY-MM-DD")
        };

        this.findAllAgendamento(dateFilter, true).then(async () => {
            const { prontuarioStore } = this.props;
            try {
                const profissionalSaudeLogado = await getProfissionalSaudeLogado();
                let prontuarioAgendamentoSelecionado = JSON.parse(await db.get("prontuarioAgendamentoSelecionado-" + profissionalSaudeLogado?.id));
                if (typeof prontuarioAgendamentoSelecionado === 'object'){
                    await db.set("prontuarioAgendamentoSelecionado-" + profissionalSaudeLogado?.id, (prontuarioAgendamentoSelecionado.id))
                    prontuarioAgendamentoSelecionado = prontuarioAgendamentoSelecionado.id
                }

                if (!prontuarioAgendamentoSelecionado) {
                    prontuarioStore.selectFirstAgendamento();
                    return;
                }

                this.selectAgendamento(prontuarioAgendamentoSelecionado);
            } catch (error) {
                prontuarioStore.selectFirstAgendamento();
            }
        });
    };
    handleCheckWritedInProntuario = (idAgendamento) =>{
        const { prontuarioStore } = this.props;
        if (!!prontuarioStore.mensagemProntuario) {
            this.setState({openModalWriteMessage: true, agendamentoId:idAgendamento})
            return 
        }
        
        this.selectAgendamento(idAgendamento)
    }
    selectAgendamento = async idAgendamento => {
        const { prontuarioStore } = this.props;
        const isIpad = 768;
        prontuarioStore.dataEntrada = null;
       
        try {
            prontuarioStore.closePreviewDocumento();
            this.setState((prevState) => {
                Object.keys(prevState.modalsStates).forEach(key => {
                    prevState.modalsStates[key] = 'closed'
                })
                return {
                    modalsStates: prevState.modalsStates
                }
            })
            this.setState({
                dateState: false,
                tabContentSelected: 0,
            });
            this.pageNumberEntradas = 0;
            prontuarioStore.agendamentoSelected = null;
            await prontuarioStore.selectAgendamento(idAgendamento);
            this.getQuantityOfSolicitacoes(idAgendamento);
        } catch (error) {
            console.error(error);
        }
        if (isIpad >= innerWidth) {
            this.setState({ open: false })
        }
    };

    handleChangePacienteWithDadosMinimizados = async (idAgendamento) => {
        const {modalsStates} = this.state;
        const isMinimized = Object.values(modalsStates).some(item => item === 'minimized');
        if (isMinimized) {
            this.setState({ 
                showModalDadosMinimizados: true, 
                sujeitoAtencaoToSetOnAgendamento: idAgendamento
            });
            return;
        } else {
            this.selectAgendamento(idAgendamento);
        }
    }

    reloadEntradas = ({ withLoading = false } = {}) => {
        const { prontuarioStore } = this.props;
        this.pageNumberEntradas = 0;
        prontuarioStore.findAllEntradaProntuario({ withLoading, sujeitoAtencao: prontuarioStore.sujeitoAtencaoSelected });
    };

    onPlayAgendamento = async (e, agendamento) => {
        e.stopPropagation();
        const { prontuarioStore, odontogramaStore } = this.props;
        const { agendamentoSelected } = prontuarioStore;
        
        agendamento.dataInicioAtendimento = moment().format(
            "YYYY-MM-DD HH:mm:ss"
        );
        await prontuarioStore.iniciarAtendimento(agendamento);
        odontogramaStore.modalProcedimento.procedimentos = [];
        odontogramaStore.findAllProcedimentoOdontologico();
        if (agendamentoSelected?.id !== agendamento?.id) {
            this.selectAgendamento(agendamento.id);
        }
        this.eventoGA('Prontuario', 'Consultar paciente', 'Iniciar atendimento');  
    };

    onStopAgendamento = async (e, agendamento, persist) => {
        e.stopPropagation();
        const { prontuarioStore, googleAnalyticsStore } = this.props;
        const atendimentosEmAndamento = await localStorageService.get(ATENDIMENTOS_EM_ANDAMENTO);
        const atendimentoSelecionado = atendimentosEmAndamento && atendimentosEmAndamento.filter(item => item.id === agendamento.id);
        if (atendimentoSelecionado) {
            agendamento.dataInicioAtendimento = atendimentoSelecionado && atendimentoSelecionado[0]?.inicioAtendimento;
        }

        agendamento.dataFimAtendimento = moment().format("YYYY-MM-DD HH:mm:ss");
        const tempoAtendimento = await prontuarioStore.finalizarAtendimento(agendamento, persist);
        
        if (tempoAtendimento) {
            const dataInicioAtendimento = moment(tempoAtendimento.data.data.finalizarAtendimento.dataInicioAtendimento).format('x');
            const dataFimAtendimento = moment(tempoAtendimento.data.data.finalizarAtendimento.dataFimAtendimento).format('x');
            ga(googleAnalyticsStore.profissionalSaude + '.send', 
                'timing', 
                'Prontuario', 
                'Tempo de atendimento', 
                dataFimAtendimento - dataInicioAtendimento
            );
        }

        this.eventoGA('Prontuario', 'Consultar paciente', 'Encerrar atendimento');  
    };

    onCallAgendamento = async e => {
        e.stopPropagation();
        this.eventoGA('Prontuario', 'Consultar paciente', 'Chamar paciente');  
        if (this.callingAgendamento) {
            return;
        }

        const { prontuarioStore, chatStore } = this.props;
        const { agendamentoSelected } = prontuarioStore;
        const { profissionalSaude, sujeitoAtencao } = agendamentoSelected;
        prontuarioStore.callingPaciente = true;

        try {
            prontuarioStore.openNotification("Chamando...", "warning", false);
            await prontuarioStore.createChamada();
            await prontuarioStore.chamarProximoAtendimento();
            await chatStore.callPaciente(profissionalSaude.id, sujeitoAtencao.nome);

            prontuarioStore.openNotification("Chamada solicitada!", "success");
            setTimeout(() => {
                prontuarioStore.callingPaciente = false;
            }, 10000);
        } catch (error) {
            console.error(error);
            prontuarioStore.openNotification(
                "Chamada não solicitada!",
                "error"
            );
            prontuarioStore.callingPaciente = false;
        }
    };

    messagesScrollToBottom = () => {
        if (
            this.anchorBottomMessagesRef &&
            this.anchorBottomMessagesRef.current
        ) {
            this.anchorBottomMessagesRef.current.scrollIntoView();
        }
    };

    handleChangeCampoProntuario = (e, field) => {
        const { prontuarioStore } = this.props;
        if (!prontuarioStore.sujeitoAtencaoSelected) {
            return;
        }
        const campo = string.capitalizeSecondWord(field)
        prontuarioStore.sujeitoAtencaoSelected[campo] = e.target.value;
        this.debounceUpdateCampoProntuario(e.target.value, field);
    };

    handleOpenReceituario = () => {
        this.eventoGA('Prontuario', 'Documentos', 'Receituario');
        const { prontuarioStore, receituarioStore } = this.props;
        prontuarioStore.receituario = {
            open: true
        };
        receituarioStore.typeModal = TypeModalEnumReceituario.RECEITUARIO_MODAL;
    };

    handleCloseReceituario = () => {
        const { prontuarioStore, receituarioStore } = this.props;
        receituarioStore.tipoListagemSelecionada = "sugestoes";
        receituarioStore.amountTemp = "";
        receituarioStore.ascriptionTemp = "";
        receituarioStore.drugTemp = null;
        receituarioStore.recipesTemp = [];
        receituarioStore.usoTemp = null;
        prontuarioStore.receituario.open= false;

        this.reloadEntradas();
    };

    handleCloseReceituarioModelo = () => {
        const { receituarioStore, prontuarioStore } = this.props;
        receituarioStore.openModalModelo = false;
        if (receituarioStore.isProntuario){
            prontuarioStore.receituario = {
                open: true
            };
        };
    };

    handleOpenImprimir = () => {
        this.setState({ openImprimir: true });
    };

    handleCloseImprimir = () => {
        this.setState({ openImprimir: false });
    };

    handleCloseSADT = () => {
        const { SADTStore } = this.props;
        SADTStore.tipoListagemSelecionada = 'sugestoes';
        SADTStore.readOnly=false;
        SADTStore.objView=defaultObjView;
        SADTStore.idModeloSelecionado = ''
        SADTStore.openModal = false;
        this.reloadEntradas();
    };

    handleCloseProcedimentoRealizado = () => {
        this.setState({ openProcedimentoRealizadoModal: false })
    };

    handleOpenSolicitacaoProcedimento = () => {
        this.setState({ showModalSolicitacaoProcedimento: true })
    };

    handleCloseSolicitacaoProcedimentoModal = () => {
        this.setState({ showModalSolicitacaoProcedimento: false })
    };

    handleCloseAnamneseModal = () => {
        this.setState({ 
            showModalAnamnese: false,
            anamneseSelecionado: null 
        }, () => this.reloadEntradas());
    };

    handleCloseModalCID = () => {
        this.setState({ openModalCID: false, openModalCIDFromStop: false }, () => {
            this.reloadEntradas();
            this.findAllAgendamentoWithFilter();
        });
    };

    loadMoreMensagens = () => {
        const { prontuarioStore } = this.props;

        if (prontuarioStore.loadingMoreMensagens) {
            return;
        }

        this.pageNumberEntradas += 1;
        prontuarioStore.findAllEntradaProntuario({
            withLoading: false,
            pageNumber: this.pageNumberEntradas,
            sujeitoAtencao: prontuarioStore.sujeitoAtencaoSelected
        })
    };

    openModalAtendimento = () => {
        const { atendimentoStore, prontuarioStore } = this.props;
        const { agendamentoSelected } = prontuarioStore;

        const event = {
            id: agendamentoSelected.id || null,
            start: moment(
                `${agendamentoSelected.data} ${agendamentoSelected.horaInicio}`
            ),
            end: moment(
                `${agendamentoSelected.data} ${agendamentoSelected.horaFim}`
            ),
            status: agendamentoSelected.situacao
        };
        prontuarioStore.isOpenModalVinculoSujeito = false;
        atendimentoStore.changeOpen(
            event
        );
        atendimentoStore.isProntuarioPage = true;
    };

    handleCadastrarNovoSujeito = async () => {
        const { atendimentoStore, prontuarioStore } = this.props;
        const { agendamentoSelected } = prontuarioStore;
        if(agendamentoSelected?.paciente?.id && atendimentoStore.open) {
            return this.createOnPacientesPage();
        }

        try {
            atendimentoStore.isProntuarioPage = true;
            prontuarioStore.loadingCadastrarNovoSujeito = true;
            await atendimentoStore.updateAgendamentoCadastroNovoSujeitoAtencao(
                agendamentoSelected
            );
            await db.remove("prontuarioAgendamentoSelecionado");

            this.selectAgendamento(agendamentoSelected.id)

        } catch (error) {
            console.error(error);
            atendimentoStore.isProntuarioPage = false;
        } finally {
            prontuarioStore.loadingCadastrarNovoSujeito = false;
            prontuarioStore.isOpenModalVinculoSujeito = false;
        }
    };

    createOnPacientesPage = async () => {
        const { sujeitoAtencaoStore } = this.props;
        sujeitoAtencaoStore.reset();
        const objView = this.createObjViewViziPaciente();
        sujeitoAtencaoStore.setViziPacienteInObjView(objView);
        sujeitoAtencaoStore.screenToBack = this.props.history.location.pathname;
        sujeitoAtencaoStore.willLinkViziHealthPaciente = true;
        this.handleCloseVincularAlertDialog();
        this.props.history.push(`/sujeito-atencao`);
    };

    createObjViewViziPaciente = () => {
        const { agendamentoSelected } = this.props.prontuarioStore;
        return {
            nome: agendamentoSelected.nome,
            documento: agendamentoSelected.cpf,
            paciente: agendamentoSelected.paciente,
            convenio: agendamentoSelected.convenio,
            dataNascimento: agendamentoSelected.paciente?.dataNascimento,
            contato: {
                telefonePrincipal: agendamentoSelected.telefone || agendamentoSelected.paciente?.telefonePrincipal,
                email: agendamentoSelected.paciente?.email,

            },
            dadosConvenio: {
                numeroCarteira: agendamentoSelected.numeroCarteira,
                validadeCarteira: agendamentoSelected.validadeCarteira
            },
            endereco: agendamentoSelected.paciente?.endereco,
            ...agendamentoSelected.sujeitoAtencao
        };
    }

    handleClickEditSujeitoAtencao = async () => {
		try {
			const { history, sujeitoAtencaoStore, prontuarioStore } = this.props;
			const sujeitoAtencaoId = prontuarioStore.agendamentoSelected?.sujeitoAtencao?.id;
			if (sujeitoAtencaoId) {
				sujeitoAtencaoStore.reset();
				sujeitoAtencaoStore.changeId(sujeitoAtencaoId);
				return history.push(`/sujeito-atencao/edit/${sujeitoAtencaoId}`);
			}
		} catch (error) {
			console.log(error);
		}
	};

    handleClickFinanceiro = async() => {
        try {
            const { history, prontuarioStore, extratoStore } = this.props;
              history.push("/financeiro")
              const sujeitoAtencao = prontuarioStore.agendamentoSelected.sujeitoAtencao;
              const profissionalSaude = this.state.profissionalSaudeAtual;
              const tipoConsulta = {
                label: prontuarioStore.agendamentoSelected.tipo?.descricao,
                value: prontuarioStore.agendamentoSelected.tipo?.id
            };
              const procedimentos = prontuarioStore.agendamentoSelected.procedimentos;
              const idAgendamento = prontuarioStore.agendamentoSelected.id;
      
              extratoStore.cadastrarReceitaBySujeito(
                "RECEITA",
                sujeitoAtencao,
                profissionalSaude,
                tipoConsulta,
                procedimentos,
                idAgendamento,
              );
        } catch (error) {
            console.error(error);
        }
    };

    handleCloseVincularAlertDialog = () => {
        const { prontuarioStore } = this.props;
        prontuarioStore.isOpenModalVinculoSujeito = false;
        prontuarioStore.agendamentos = prontuarioStore.agendamentos.map(a => ({
            ...a,
            selected: false
        }));
    };

    handleDocument = () => {
        this.setState({
            openPreencherDocumento: true
        });
        this.eventoGA('Prontuario', 'Documentos', 'Documento');
    };

    handleClickSADT = () => {
        this.eventoGA('Prontuario', 'Documentos', 'SP/SADT');
        const { SADTStore } = this.props;
        SADTStore.guiaServico = null;
        SADTStore.openModal = true;
        this.props.SADTStore.typeModal = TypeModalEnum.SADT_MODAL;
    };

    handleClickSolicitacaoProcedimento = () => {
        this.setState({ 
            showModalSolicitacaoProcedimento: true 
        });
    };

    handleClickAnamnese = () => {
        this.setState({
            showModalAnamnese: true
        })
    }

    handleEditarCamposProntuario = () => {
        this.setState({
            openPersonalizarCamposProntuario: true
        });
    }

    handleClosePersonalizarCamposProntuario = () => {
        this.setState({
            openPersonalizarCamposProntuario: false
        });
    }

    handleClosePreencherDocumento = () => {
        this.setState({
            openPreencherDocumento: false,
            isDuplicar: false 
        });
        this.reloadEntradas();
    };

    handleClickInformacoesImportantes = async () => {
        const {prontuarioStore} = this.props;
        await prontuarioStore.refreshSelectedSujeitoAtencao;
        prontuarioStore.openModalDadosImportantes = true
    }

    onCloseInformacaoModal = () => {
        const {prontuarioStore} = this.props;
        prontuarioStore.openModalDadosImportantes = false
    }

    handleClickAvaliacaoAntropometrica = async () => {
        this.setState({ 
            openAvaliacaoAntropometrica: true,
            isNovoLancamento: true,
        });
    }

    onCloseAvaliacaoAntropometrica = () => {
        this.setState({ 
            openAvaliacaoAntropometrica: false,
            avaliacaoAntropometricaSelecionado: null 
        });
        this.reloadEntradas();
    }

    handleClickTriagem = async () => {
        this.setState({ openTriagem: true });
    }

    onCloseTriagem = () => {
        this.setState({ 
            openTriagem: false,
            triagemSelecionado: null
        });
        this.reloadEntradas()
    }

    checkIsPlaying = () => {
        const { prontuarioStore } = this.props;
        const { agendamentoSelected } = prontuarioStore;

        if (!agendamentoSelected?.id) {
            return false;
        }

        return !!(agendamentoSelected.dataInicioAtendimento && !agendamentoSelected.dataFimAtendimento);
    };

    isAtendimentoFinalizado = () => {
        const { prontuarioStore } = this.props;
        const { agendamentoSelected } = prontuarioStore;

        if (!agendamentoSelected?.id) {
            return false;
        }

        return !!(
            agendamentoSelected.dataInicioAtendimento &&
            agendamentoSelected.dataFimAtendimento
        );
    };

    

    calculaIdade = (dataNascimento) => {
        if(!dataNascimento){
            return null;
        }
        let idadeSujeito = moment().diff(dataNascimento, "years");

        if(idadeSujeito >= 18){
            return idadeSujeito + " anos"
        }

        let dataNascimentoMoment = moment(dataNascimento),
            dataHoje = moment(),
            intervals = [{label: 'anos', value: 'years'},{label: 'meses', value: 'months'},{label: 'dias', value: 'days'}],
            out = [];

        for(let i=0; i<intervals.length; i++){
            let diff = dataHoje.diff(dataNascimentoMoment, intervals[i].value);
            dataNascimentoMoment.add(diff, intervals[i].value);
            out.push(diff + ' ' + intervals[i].label);
        }
        return out.join(', ');
    };

    handleDateFilter = (date) => {
        const { prontuarioStore } = this.props;
        prontuarioStore.openModalDadosImportantes = false;

        switch (date) {
            case "add":
                this.setState(
                    {
                        changeDate: this.state.changeDate.add(1, "day"),
                        dataFilter:
                            this.state.changeDate.format("DD") ===
                            this.state.today.format("DD"),
                        dateState: true
                    },
                    () => {
                        let filter = {
                            search: prontuarioStore.searchTextAgendamento,
                            dataInicial: this.state.changeDate
                                .startOf("day")
                                .format("YYYY-MM-DD"),
                            dataFinal: this.state.changeDate
                                .endOf("day")
                                .format("YYYY-MM-DD")
                        };
                        this.findAllAgendamento(filter, true);
                    }
                );
                break;

            case "subtract":
                this.setState(
                    {
                        changeDate: this.state.changeDate.subtract(1, "day"),
                        dataFilter:
                            this.state.changeDate.format("DD") ===
                            this.state.today.format("DD"),
                        dateState: true
                    },
                    () => {
                        let filter = {
                            dataInicial: this.state.changeDate
                                .startOf("day")
                                .format("YYYY-MM-DD"),
                            dataFinal: this.state.changeDate
                                .endOf("day")
                                .format("YYYY-MM-DD")
                        };
                        this.findAllAgendamento(filter, true);
                    }
                );
                break;

            case "current":
                if (this.state.changeDate !== moment()) {
                    this.setState(
                        {
                            changeDate: moment(),
                            dataFilter: true,
                            dateState: false
                        },
                        () => {
                            let filter = {
                                dataInicial: this.state.changeDate
                                    .startOf("day")
                                    .format("YYYY-MM-DD"),
                                dataFinal: this.state.changeDate
                                    .endOf("day")
                                    .format("YYYY-MM-DD")
                            };
                            this.findAllAgendamento(filter, true);
                        }
                    );
                }
                break;
            default:
                console.log("default date");
                break;
        }
    }

    renderPlayStopButtons = () => {
        const { prontuarioStore } = this.props;

        let selecionado = prontuarioStore.agendamentoSelected.sujeitoAtencao;

        if (selecionado.dataInicioAtendimento && selecionado.dataFimAtendimento) {
            return null;
        }
    }
    onBlock = (page) => {
        const { prontuarioStore } = this.props;
        this.navigation = () => this.props.history.push(page.pathname);
        if (!prontuarioStore.mensagemProntuario) {
            return true
        }
        if (!this.state.openModalWriteMessage) {
            this.setState({ openModalWriteMessage: true });
        }
        else{
            this.blockPage();
            return true;
        }
        return false;
    };
    onCloseModal = () => {
        this.setState({ openModalWriteMessage: false});
    };

    allowNextPage = () => {
        this.state.agendamentoId && this.selectAgendamento(this.state.agendamentoId)
        this.setState({openModalWriteMessage: false,agendamentoId:null })     
        this.navigation && this.navigation();
    };

    onChangeTeleconsultaEncerrado = (value) => {
        this.setState({ teleconsultaEncerrado: value });
    };

    onChangeTempoTeleconsulta = (value) => {
        this.setState(prevState => ({
            tempoTeleconsulta: { ...prevState.tempoTeleconsulta, ...value }
        }));
        this.checkIsPlaying();
    };

    verificarTermosUsoAssinados = async () => {
        const termoUso = await this.props.termoUsoStore.buscarTermoUso("TELEMEDICINA");
        return termoUso;
    }
    
    getCampoProntuarioValue = (campoProntuario) => {
        const sujeitoAtencao = this.props.prontuarioStore.sujeitoAtencaoSelected;
        const valor = campoProntuario.caminhoValor;
        const valorCampo = string.getValueByPath(sujeitoAtencao, valor);

        if (campoProntuario.campo === 'IDADE' && valorCampo) {
            return this.calculaIdade(valorCampo)
        }

        if (campoProntuario.campo && valorCampo) {
            return string.capitalizeEachWord(String(valorCampo));
        }

        return
    }

    eventoGA = (categoria, acao, rotulo) => {
        ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', categoria, acao, rotulo);
    }


    openModal = (event, isModalSala) => {
        const { atendimentoStore } = this.props;
        const {tipoProfissionalLogado} = this.props.atendimentoStore;


        if(tipoProfissionalLogado === 'tecnico') {
            if(!event?.id) return
            this.props.atendimentoStore.changeOpen({
                id: event?.id || null, 
                horaInicio: event?.start, 
                horaFim: event?.end, 
                status: event?.status, 
                agendaSelecionada: event?.agendaSelecionada,
                tipoModalAtendimento: 'tecnico'
            });
        } else {
            if(!event?.start){
                const hora = Moment().format('LTS');
                const dataSelecionada = atendimentoStore.selectedDate.format("YYYY-MM-DD");
                const horaInicio = Moment(`"${dataSelecionada} ${hora}"`, 'YYYY-MM-DD HH:mm:ss');
                const horaFim = Moment(horaInicio).add(
                    atendimentoStore.configVigente?.duracao,
                  "minutes"
                );

                this.handleShowProcedimentos()

                return this.props.atendimentoStore.changeOpen({
                  id: event?.id || null, 
                  horaInicio: horaInicio, 
                  horaFim: horaFim, 
                  status: "AGENDADO",
                  isModalSala, 
                  agendaSelecionada: event?.agendaSelecionada
                });
            };
            this.props.atendimentoStore.changeOpen({
                id: event?.id || null, 
                horaInicio: event?.start, 
                horaFim: event?.end, 
                status: event?.status, 
                isModalSala, 
                agendaSelecionada: event?.agendaSelecionada,
                chavePublica: event?.chavePublica
            });
        }
    };

    toggleModalSolicitacoes = () => {
        this.setState(prevState => ({
            showModalSocilicitacoes: !prevState.showModalSocilicitacoes
        }));
    };

    getQuantityOfSolicitacoes = async (idAgendamento) => {
        if(!idAgendamento) return;
        const { prontuarioStore } = this.props;
        const response = await quantitySolicitacoes(idAgendamento);
        prontuarioStore.quantitySolicitacoes = response;
        return response;
    }

    changeTabContentSelected = (tabSelected) => {
        if(tabSelected === 0) {
            this.reloadEntradas();
        };
        
        this.setState({
            tabContentSelected: tabSelected
        });
    };

    renderTabContent = () => {
        const { history } = this.props;
        const { tabContentSelected } = this.state;

        return tabContentSelected === 0 ? 
            <Prontuario
                loadMoreMensagens={this.loadMoreMensagens}
                reloadEntradas={this.reloadEntradas}
                handleClickSADT={this.handleClickSADT}
                prontuarioStore={this.props.prontuarioStore}
                atualizaState={this.atualizaState}
            />
            :
            <Odontograma history={history} openDrawer={this.state.open}/>
    }

    atualizaState = (values) => {
        const arrayKey = Object.keys(values);
    
        arrayKey.forEach(item => {
            this.setState({
                [item]: values[item]
            })
        })
    }

    checkUserHasInfosToConfirmPresence = async () => {
        const { prontuarioStore } = this.props;
        try{
            const sujeitoAtencao = await findSujeitoAtencaoById(prontuarioStore.sujeitoAtencaoSelected.id);
            const { contato } = sujeitoAtencao;
            const withContato = ['email', 'telefonePrincipal'].every(key => contato[key]);
            const checkContainDados = sujeitoAtencao.cpf && withContato;
            return !checkContainDados && prontuarioStore.confirmaPresencaAgendamento;
        } catch(e) {
            console.error(e);
        }
    }

    handleOpenModalConfirmacaoPresenca = async (e) => {
        const { prontuarioStore } = this.props;
        const needUpdate = await this.checkUserHasInfosToConfirmPresence();
        const profissionalSaudeLogado = await getProfissionalSaudeLogado();
        const response = await countAgendamentosAtendendo({
            profissionalSaudeId: profissionalSaudeLogado.id,
            dataInicio: moment().format('YYYY-MM-DD'),
            dataFim: moment().format('YYYY-MM-DD'),
        })

        if(needUpdate) {
            this.setState({ showModalConfirmarPresenca: true });
        } else {
            if(response > 0){
                this.setState({ openModalAtendimento:true })
                return;
            }
            this.onPlayAgendamento(e, prontuarioStore.agendamentoSelected)
        }
    }

    handleChangeEmEsperaSort = () => {
        const { prontuarioStore } = this.props;
        const { agendamentosAguardandoFilterOrder } = prontuarioStore;

        prontuarioStore.agendamentosAguardandoFilterOrder = agendamentosAguardandoFilterOrder === 'default' ? 'byArriveTime' : 'default';
        this.debounceFindAllAgendamento();
    }

    handleChangeModalState = (name, payload) => {
        this.setState((prevState) => ({
            modalsStates: {
                ...prevState.modalsStates,
                [name]: payload
            }
        }));
    }

    isModalMinimized = (name) => {
        const { modalsStates } = this.state;
        return modalsStates[name] === 'minimized';
    }

    handleCloseModalInfosDadosMinimizados = () => {
        this.setState({
            showModalDadosMinimizados: false,
            sujeitoAtencaoToSetOnAgendamento: null,
        });
    }

    handleClickGuiaInternacao = () => {
        const { guiaInternacaoStore } = this.props;
        guiaInternacaoStore.openGuiaInternacaoModal = true;
    };

    render() {
        const { 
            classes, 
            prontuarioStore, 
            atendimentoStore, 
            termoUsoStore, 
            odontogramaStore, 
            guiaInternacaoStore 
        } = this.props;
        const {
            sujeitoAtencaoSelected,
            loadingAllEntradas,
            receituario,
            usuarioLogado,
            isOpenAtendimentoModal,
            agendamentoSelected,
            isOpenModalVinculoSujeito,
            modalVinculoSujeitoAlertTitle,
            modalVinculoSujeitoAlertDescription,
            loadingCadastrarNovoSujeito,
            openModalDadosImportantes,
            utilizaCidObrigatorio,
            modalConfirmarTempoTeleconsulta
        } = prontuarioStore;
        const { isTelaAmpliada } = odontogramaStore;

        const { 
            open,  
            teleconsultaEncerrado, 
            tempoTeleconsulta, 
            openAvaliacaoAntropometrica,
            openTriagem,
            tabContentSelected,
            profissionalSaudeAtual,
            unidadeAtual,
            openModalWriteMessage
        } = this.state;

        const { openGuiaInternacaoModal } = guiaInternacaoStore;

        const receituarioIsOpen =
            !!(sujeitoAtencaoSelected && usuarioLogado && receituario?.open);

        const imprimirProntuarioIsOpen =
            !!(sujeitoAtencaoSelected && usuarioLogado && this.state.openImprimir);

        const SADTIsOpen =
            !!(sujeitoAtencaoSelected && usuarioLogado && this.props.SADTStore.openModal);

        const procedimentoRealizadoModalIsOpen = !!(sujeitoAtencaoSelected && usuarioLogado && this.state.openProcedimentoRealizadoModal)

        const solicitacaoProcedimentoIsOpen =   !!(sujeitoAtencaoSelected && usuarioLogado && this.state.showModalSolicitacaoProcedimento);
        
        const anamneseIsOpen =   !!(sujeitoAtencaoSelected && usuarioLogado && this.state.showModalAnamnese);

        let dateFilterHandler = this.state.dataFilter
            ? classes.searchPacienteDataFilter
            : classes.dataFilter;

        const possuiOdontograma = odontogramaStore.verificaEspecialidadeOdontologia(profissionalSaudeAtual)

        const modalCIDIsOpen = !!(utilizaCidObrigatorio && agendamentoSelected?.cid10Subcategorias?.length === 0);
        const tabOdontograma = tabContentSelected === 1;

        return (
            <div className={ classNames(open ? classes.gridContainer : classes.gridContainerBarraMinimizado, isTelaAmpliada && classes.gridContainerTelaAmpliada) }>
                <PageTitle title="Atendimento"/>
                { isTelaAmpliada ? 
                this.renderTabContent()
                :
                <>
                    <DrawerProntuario
                        open={this.state.open}
                        handleDateFilter={this.handleDateFilter}
                        handleShowProcedimentos={this.handleShowProcedimentos}
                        selectAgendamento={this.handleCheckWritedInProntuario}
                        changeDate={this.state.changeDate}
                        listarAgendamentosESelecionarAgendamento={this.listarAgendamentosESelecionarAgendamento}
                        handleDrawerClose={this.handleDrawerClose}
                        handleDrawerOpen={this.handleDrawerOpen}
                        dateFilterHandler={dateFilterHandler}
                        sujeitoAtencaoSelected={sujeitoAtencaoSelected}
                        handleChangeEmEsperaSort={this.handleChangeEmEsperaSort}
                    /> 
                    <div className={classes.conteudoConsulta}>
                    <ProntuarioPainelHOC
                        sujeito={sujeitoAtencaoSelected}
                        dateState={this.state.dateState}
                    >
                <div className={classes.content}>
                    <div className={classNames(classes.header, open && classes.headerOpenDrawer)}>
                        <HeaderProntuario
                            handleClickFinanceiro={this.handleClickFinanceiro}
                            handleClickEditSujeitoAtencao={this.handleClickEditSujeitoAtencao}
                            onPlayAgendamento={this.handleOpenModalConfirmacaoPresenca}
                            onStopAgendamento={e => {
                                if(modalCIDIsOpen) {
                                    this.setState({openModalCID: true, openModalCIDFromStop: true});
                                    return;
                                }
                                this.onStopAgendamento(e, prontuarioStore.agendamentoSelected, true)
                            }}
                            onCallAgendamento={this.onCallAgendamento}
                            eventoGA={this.eventoGA}
                            handleEditarCamposProntuario={this.handleEditarCamposProntuario}
                            toggleModalSolicitacoes={this.toggleModalSolicitacoes}
                            isLoaded={!loadingAllEntradas && sujeitoAtencaoSelected}
                            handleOpenCIDModal={() => this.setState({openModalCID: true})}
                        />
                    </div>

                        <div className={classNames(
                            classes.contentTab,
                            possuiOdontograma && classes.contentTabOdonto,
                            prontuarioStore.openFiltroData && classes.contentTabFiltrado,
                            tabOdontograma && classes.contentTabSelectedOdontograma,
                        )}>
                            { possuiOdontograma && <MultiToggleButtons
                                options={["Prontuário", "Odontograma"]}
                                tabSelected={tabContentSelected}
                                changeTabSelected={this.changeTabContentSelected}
                                classes={{ buttonsContainer: classes.buttonsTab }}
                                disabled={!sujeitoAtencaoSelected?.id}
                                posicaoDesabilitada={[1]}
                            />}
                            { this.renderTabContent() }
                                <div className={classes.contentMensagens}>
                                    <div className={classes.contentOptions}>
                                        <OptionsProntuario
                                            handleOpenReceituario={this.handleOpenReceituario}
                                            handleDocument={this.handleDocument}
                                            handleClickSADT={this.handleClickSADT}
                                            handleClickGuiaInternacao={this.handleClickGuiaInternacao}
                                            handleClickSolicitacaoProcedimento={this.handleClickSolicitacaoProcedimento}
                                            handleClickAnamnese={this.handleClickAnamnese}
                                            handleClickInformacoesImportantes={this.handleClickInformacoesImportantes}
                                            handleClickAvaliacaoAntropometrica={this.handleClickAvaliacaoAntropometrica}
                                            handleClickTriagem={this.handleClickTriagem}
                                            handleOpenImprimir={this.handleOpenImprimir}
                                            tabSelected={tabContentSelected}
                                            hiddenProcedimentosButton={odontogramaStore.verificaEspecialidadeOdontologia(profissionalSaudeAtual)}
                                        />
                                    </div>
                                </div>                                
                            </div>
                            <MinimizedTaskbar 
                                onOpenReceita={(index) => {
                                    this.handleOpenReceituario();
                                    this.setState({ modalEditIndex: index })
                                }}
                                isReceitaMinimized={this.isModalMinimized(MODAL.RECEITUARIO)}
                                isDocumentoMinimized={this.isModalMinimized(MODAL.DOCUMENTO)}
                                isAnamneseMinimized={this.isModalMinimized(MODAL.ANAMNESE)}
                                onOpenAnamnese={(index) => {
                                    this.handleClickAnamnese();
                                    this.setState({ modalEditIndex: index })
                                }}
                                onOpenDocumento={(index) => {
                                    this.handleDocument();
                                    this.setState({ modalEditIndex: index });
                                }}
                                indexSelected={this.state.modalEditIndex}
                            />
                    </div>
                 </ProntuarioPainelHOC>
                 </div>
                </>
                }
        <Footer />
        {teleconsultaEncerrado && (
            <EncerrarTeleconsultaModal
            onChangeTeleconsultaEncerrado={this.onChangeTeleconsultaEncerrado}
            tempoTeleconsulta={tempoTeleconsulta}
            />
        )}
                {(receituarioIsOpen || this.isModalMinimized(MODAL.RECEITUARIO)) && (
                    <Receituario
                        entrada={receituario?.entrada}
                        open={receituario.open}
                        handleClose={() => {
                            this.handleChangeModalState(MODAL.RECEITUARIO, 'closed');
                            this.handleCloseReceituario();
                        }}
                        sujeitoAtencaoId={sujeitoAtencaoSelected.id}
                        profissionalSaude={this.state.profissionalSaudeAtual}
                        unidadeAtual={unidadeAtual}
                        screen="Atendimento"
                        handleMinimize={() => {
                            this.handleChangeModalState(MODAL.RECEITUARIO, 'minimized');
                            this.setState({ modalEditIndex: null });
                            this.handleCloseReceituario();
                        }}
                        history={this.props.history}
                    />
                )}
                {this.props.receituarioStore.openModalModelo && (
                    <ReceituarioModelo
                        open={this.props.receituarioStore.openModalModelo}
                        handleClose={this.handleCloseReceituarioModelo}
                        profissionalSaude={this.state.profissionalSaudeAtual}
                        unidadeAtual={unidadeAtual}
                        screen="Atendimento"
                    />
                )}
                {(this.state.openPreencherDocumento || this.isModalMinimized(MODAL.DOCUMENTO) )&& (
                <PreencherDocumento
                    open={this.state.openPreencherDocumento}
                    handleMinimize={() => {
                        this.handleChangeModalState(MODAL.DOCUMENTO, 'minimized');
                        this.setState({ modalEditIndex: null });
                        this.handleClosePreencherDocumento();
                    }}
                    handleClose={() => {
                        this.handleChangeModalState(MODAL.DOCUMENTO, 'closed');
                        this.handleClosePreencherDocumento();
                    }}
                    isDuplicar={this.state.isDuplicar}
                    previewDocumento={prontuarioStore.previewDocumento}
                    sujeitoAtencaoId={sujeitoAtencaoSelected?.id}
                    profissionalSaude={profissionalSaudeAtual}
                    unidadeAtual={unidadeAtual}
                    options={this.state.documentos}
                    screen="Atendimento"
                    history={this.props.history}
                />
            )}


                {imprimirProntuarioIsOpen && (
                    <Imprimir
                        open={this.state.openImprimir}
                        handleClose={this.handleCloseImprimir}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        profissionalSaude={this.state.profissionalSaudeAtual}
                        unidadeAtual={this.state.unidadeAtual}
                    />
                )}

                {SADTIsOpen && (
                    <SADTModal
                        handleClose={this.handleCloseSADT}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        screen="Atendimento"
                        history={this.props.history}
                    />
                )}

                {procedimentoRealizadoModalIsOpen && (
                    <ModalProcedimentosRealizados 
                    handleClose={this.handleCloseProcedimentoRealizado}
                    handleOpenModalSolicitacao={this.handleOpenSolicitacaoProcedimento}
                    idSujeitoAtencao={sujeitoAtencaoSelected.id}
                    idAgendamento={agendamentoSelected?.id}
                    idProcedimentoRealizado={this.state.procedimentoRealizadoId}
                    showModal={procedimentoRealizadoModalIsOpen}
                    pageTitle="Atendimento - Procedimentos realizados"
                    />
                    )
                }

                {solicitacaoProcedimentoIsOpen && (
                    <SolicitacaoProcedimentoModal 
                        openModal={solicitacaoProcedimentoIsOpen}
                        handleClose={this.handleCloseSolicitacaoProcedimentoModal}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                    />
                )}

                {(anamneseIsOpen || this.isModalMinimized(MODAL.ANAMNESE)) && (
                    <AnamneseModal
                        openModal={anamneseIsOpen}
                        handleMinimize={() => {
                            this.handleChangeModalState(MODAL.ANAMNESE, 'minimized');
                            this.setState({ modalEditIndex: null });
                            this.handleCloseAnamneseModal();
                        }}
                        handleClose={() => {
                            this.handleChangeModalState(MODAL.ANAMNESE, 'closed');
                            this.handleCloseAnamneseModal();
                        }}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        anamneseSelecionado={this.state.anamneseSelecionado}
                        screen="Atendimento"
                    />
                )}

                {termoUsoStore.isOpenTermoUsoModal && (
                    <TermoUsoModal
                        open={termoUsoStore.isOpenTermoUsoModal}
                        documento={termoUsoStore.termoUso}
                        tipo={"TELEMEDICINA"}
                        titulo={"Termos de Uso da Ferramenta de Telemedicina"}
                        handleAccept={termoUsoStore.assinarTermoUso}
                        handleRefuse={termoUsoStore.closeModal}
                    />
                )}

                {this.state.openPersonalizarCamposProntuario && (
                    <PersonalizarCamposProntuario
                        open={this.state.openPersonalizarCamposProntuario}
                        handleClose={this.handleClosePersonalizarCamposProntuario}
                        selectedCamposProntuario={this.state.selectedCamposProntuario}
                        profissionalSaude={this.state.profissionalSaudeAtual}
                        updateSelectedCamposProntuario={this.updateSelectedCamposProntuario}
                    />
                )}

                <VincularSujeitoAlertDialog
                    open={isOpenModalVinculoSujeito}
                    alertTitle={modalVinculoSujeitoAlertTitle}
                    alertDescription={modalVinculoSujeitoAlertDescription}
                    onVincular={this.openModalAtendimento}
                    onCadastrarNovo={this.handleCadastrarNovoSujeito}
                    loadingCadastrarNovo={loadingCadastrarNovoSujeito}
                    onClose={this.handleCloseVincularAlertDialog}
                />
               {atendimentoStore.isProntuarioPage && isOpenAtendimentoModal && (<AtendimentoModal
                    open={isOpenAtendimentoModal}
                    onClose={this.handleCloseVincularAlertDialog}
                />)}

                {openModalDadosImportantes && (
                    <div className={classes.floatingModal}>
                        <InformacoesImportantesModal 
                            openedByButton 
                            onClose={this.onCloseInformacaoModal}
                            screen="Atendimento"
                        />
                    </div>
                )}

                {openAvaliacaoAntropometrica && (
                    <AvaliacaoAntropometricaModal
                        openModal={openAvaliacaoAntropometrica}
                        handleClose={this.onCloseAvaliacaoAntropometrica}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        idadeSujeitoAtencao={this.calculaIdade(sujeitoAtencaoSelected.dataNascimento)}
                        avaliacaoAntropometricaSelecionado={this.state.avaliacaoAntropometricaSelecionado}
                        isNovoLancamento={this.state.isNovoLancamento}
                        screen="Atendimento"
                    />
                )}

                {openTriagem && (
                    <AfericaoVitalModal
                        openModal={openTriagem}
                        handleClose={this.onCloseTriagem}
                        sujeitoAtencao={sujeitoAtencaoSelected}
                        idadeSujeitoAtencao={this.calculaIdade(sujeitoAtencaoSelected.dataNascimento)}
                        afericaoVitalSelecionado={this.state.triagemSelecionado}
                        isProntuario={true}
                        screen="Atendimento"
                    />
                )}
                { atendimentoStore.open && <AtendimentoModal /> }
                    
                <Notification
                    close={e => prontuarioStore.closeNotification(e)}
                    reset={() => prontuarioStore.resetNotification()}
                    isOpen={prontuarioStore.notification.isOpen}
                    variant={prontuarioStore.notification.variant}
                    message={prontuarioStore.notification.message}
                />
                { this.state.showProcedimentos && <InfosAgendamentoModal
                    open={this.state.showProcedimentos}
                    onClose={this.handleShowProcedimentos}
                    idSelected={this.state.prontuarioSelected?.id}
                    serviceFields
                    fieldProcedimento={this.state.agendamentoInfo}
                    handleEditAgendamento={this.openModal}
                /> }
                {this.state.showModalSocilicitacoes && 
                <ModalSolicitacoes
                    open={this.state.showModalSocilicitacoes} 
                    onClose={this.toggleModalSolicitacoes}  
                    sujeitoAtencao={sujeitoAtencaoSelected}
                    openNotification={(message, type) => prontuarioStore.openNotification(message, type)}
                    agendamentoId={agendamentoSelected?.id}
                />}
                {this.state.showModalConfirmarPresenca && 
                <ModalConfirmarPresenca 
                    open={this.state.showModalConfirmarPresenca} 
                    sujeitoAtencaoId={prontuarioStore.sujeitoAtencaoSelected.id}
                    onClose={() => {
                    this.setState({showModalConfirmarPresenca: false})
                    }}
                    openNotification={(message, type) => prontuarioStore.openNotification(message, type)}
                    onPlayAgendamento={this.handleOpenModalConfirmacaoPresenca}
                />}

                {this.state.openModalAtendimento && (
                    <ModalAtendimentos 
                        open={this.state.openModalAtendimento}
                        onClose={() => this.setState({openModalAtendimento: false})}
                        agendamentoSelected={prontuarioStore.agendamentoSelected}
                        onPlayAgendamento={(e) => this.onPlayAgendamento(e, prontuarioStore.agendamentoSelected)}
                        onStopAgendamento={(e, agendamento) => this.onStopAgendamento(e, agendamento, false)}
                        handleSelectAgendamento={(agendamentoId) => this.selectAgendamento(agendamentoId)}
                    />
                )}

                {this.state.showModalDadosMinimizados && (
                    <ModalConfirmacao
                        open={open}
                        buttons={{
                            labelPrimaryButton: "Cancelar",
                            handlePrimaryButton: () => this.handleCloseModalInfosDadosMinimizados(),
                            labelSecondButton: "Continuar",
                            handleSecondButton: () => {
                                this.selectAgendamento(this.state.sujeitoAtencaoToSetOnAgendamento)
                                this.handleCloseModalInfosDadosMinimizados()
                            }
                        }}
                    >
                    <div className={classes.wrapperInfoDadosMinimizados}>
                        <header>
                            <div>
                                <InfoIconFilled color='white' size={24}/>
                            </div>
                            <Button kind='transparent' shape='square'>
                                <CloseIcon size={16} onClick={this.handleCloseModalInfosDadosMinimizados}/>
                            </Button>
                        </header>
                        <div className={classes.containerInfoDadosMinimizados}>
                            <h2>Deseja sair desse prontuário?</h2>
                            <span>Existem documentos em execução ao sair esses documentos serão perdidos.</span>
                        </div>
                    </div>
                </ModalConfirmacao>
                )}
                {this.state.openModalCID && (
                    <ModalCID 
                        open={this.state.openModalCID} 
                        onClose={this.handleCloseModalCID} 
                        agendamentoSelected={prontuarioStore.agendamentoSelected}
                        onStopAgendamento={(e) => this.onStopAgendamento(e, prontuarioStore.agendamentoSelected, true)}
                        openFromStop={this.state.openModalCIDFromStop}
                    />
                )}
                {openGuiaInternacaoModal && (
                    <ModalGuiaInternacao
                        defaultEntity="guiaInternacao"
                        isOpen={openGuiaInternacaoModal}
                        onClose={() => guiaInternacaoStore.openGuiaInternacaoModal = false}
                        unidadeAtual={unidadeAtual}
                        sujeitoAtencaoId={sujeitoAtencaoSelected.id}
                        reloadEntradaPronturio={this.reloadEntradas}
                    />
                )}
                <ConfirmAlertModal
                    open={openModalWriteMessage}
                    title={"Deseja sair desse prontuário?"}
                    text={"Existe uma evolução em aberto. O ideal é salvá-la antes de sair."}
                    titleSecundaryButton={"Sair"}
                    onClose={this.onCloseModal}
                    onConfirm={this.allowNextPage}
                    onCancel={this.onCloseModal}
                />
                {modalConfirmarTempoTeleconsulta && <EncerrarTeleconsultaModal />}
            </div>
        );       
    }
}
export default withStyles(styles , { withTheme: true })(ProntuarioAtendimento);
