import {
	ImmortalStorage,
	IndexedDbStore,
	LocalStorageStore,
} from "immortal-db";
import TagManager from 'react-gtm-module';
import { UNIDADE_LOGADA_KEY } from "../services/storage";

const config = () => {
		let protocol = process.env.REACT_APP_CLIENT_PROTOCOL,
		apiUrl = process.env.REACT_APP_API_URL,
		authenticationUrl = process.env.REACT_APP_AUTH_URL,
		nestorUrl = process.env.REACT_APP_NESTOR_URL,
		gmtId = process.env.REACT_APP_GTM_ID,
		viziApiURL = process.env.REACT_APP_VIZI_API_URL;

	const username = "APPHEALTH_WEB";
	const password = "apphealth";

	return {
		protocol,
		username,
		password,
		clientId: username,
		userInfo: `${username}:${password}`,
		apiUrl: apiUrl,
		authenticationUrl: authenticationUrl,
		nestorUrl: nestorUrl,
		gmtId,
		viziApiURL: protocol + viziApiURL + "/api/v1/graphql",

		authenticationUrlRequest: protocol + authenticationUrl,
		apiUrlRequest: protocol + apiUrl + "/api/v1",
		nestorUrlRequest: protocol + nestorUrl,
	};
};

export const db = new ImmortalStorage([LocalStorageStore, IndexedDbStore]);

export const buildUrlDownload = (fileUrl, token) => {
	const { protocol, apiUrl } = config();
	return `${protocol}${apiUrl}${fileUrl}?access_token=${token}`;
};
export const buildUrlMiniatura = (fileUrl, token) => {
	const { protocol, apiUrl } = config();
	return `${protocol}${apiUrl}${fileUrl}?access_token=${token}`;
};

export const buildUrlFotoPerfil = (fotoPerfilId, token) => {
	const { protocol, nestorUrl } = config();
	return `${protocol}${nestorUrl}/resource/AppHealth/${token}/${fotoPerfilId}`;
};

export const buildUrlFotoPerfilLight = (fotoPerfilId, token) => {
	const { protocol, nestorUrl } = config();
	return `${protocol}${nestorUrl}/resource/AppHealth/${token}/${fotoPerfilId}/foto-perfil`;
};

export const buildChatFileUrl = (fileUrl, chatUrl) => {
	return `${chatUrl}${fileUrl}`;
}

export const buildUrlImagemDocumentoTemporario = (fotoPerfilId, token) => {
	const { protocol, nestorUrl } = config();
	return `${protocol}${nestorUrl}/resource/temporario/AppHealth/${token}/${fotoPerfilId}`;
};

export const buildUrlSujeitoAtencaoAnexo = (url, token, tipo) => {
	const { protocol, apiUrl } = config();
	return `${protocol}${apiUrl}/amazon/s3/sujeito-atencao/anexo/${tipo}/${url}?access_token=${token}`;
};

export const buildUrlSolicitacaoPaciente = (url, token) => {
	const { protocol, apiUrl } = config();
	return `${protocol}${apiUrl}${url}?access_token=${token}`;
};

export const buildUrlPerfilVizi = (perfilPublico) => {
	const { unidade, idPublico, nomeLinkPublico} = perfilPublico;

	const profileAs = unidade?.id ? '/unidade' : '/profissional';
	const nameWithoutSpaces = nomeLinkPublico?.trim().toLocaleLowerCase().replace(/\s/g, '-');
	const nameNormalized = nameWithoutSpaces?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
	const idWithName = `${nameNormalized}-${idPublico}`;

	const profileHref = profileAs + `/${encodeURIComponent(idWithName)}`;

  return profileHref;
};

export const buildLinkPagamentoVizi = (chavePublica) => {
	return `${getViziWebUrl()}/pagamento/${chavePublica}`
}

export const getViziWebUrl = () => {
	try{
		const urlFrontWeb = JSON.parse(localStorage.getItem('_immortal|' + UNIDADE_LOGADA_KEY))?.rede?.whitelabel?.frontWebUrl;

		return urlFrontWeb || process.env.REACT_APP_VIZI_SAUDE_URL;
	} catch (error) {
		return process.env.REACT_APP_VIZI_SAUDE_URL;
	}
}

export const request = (config) => {
	return config;
};

export const requestError = (error) => {
	return Promise.reject(error);
};

export const response = (response) => {
	return response;
};

export const responseError = (error) => {
	return Promise.reject(error);
};

export const initializeGmt = () => {
	const { gmtId } = config();
	const tagManagerArgs = {
    gtmId: gmtId
	};

	TagManager.initialize(tagManagerArgs);
}

export default new config();
