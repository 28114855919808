import Api from "../config/api";

export const imprimirDocumento = async (variables) => {
  const response = await Api.post("", {
    query: `
        mutation ($id: Long) {
            imprimir: imprimirDocumento(id: $id)
        }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
  return response.data.data.imprimir;
};

export const listarDocumentoModelo = async (variables) => {
  try {
    const response = await Api.post("", {
      query: `
        query ($profissionalSaudeId: Long, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput){
          findAllDocumentosModelo(profissionalSaudeId: $profissionalSaudeId, ativo: $ativo, search: $search, pageableDTO: $pageableDTO){
            last
            content {
              nome
              id
              ativo
            }
          }
        }
        `,
      variables: variables,
    });
    return response.data.data.findAllDocumentosModelo;
  } catch (error) {
    console.log(error);
  }
};

export const inativarDocumentoModelo = async (id) => {
  return await Api.post("", {
    query: `
        mutation{
            inativarDocumentoModelo(idDocumentoModelo: ${id}){
                id
          }
        }`,
  });
};

export const ativarDocumentoModelo = async (id) => {
  return await Api.post("", {
    query: `
        mutation{
            ativarDocumentoModelo(idDocumentoModelo: ${id}){
                id
          }
        }`,
  });
};

export const criarEImprimirDocumento = (variablesDTO) => {
  return Api.post("", {
    query: `
      mutation ($camposEntrada: [DocumentoModeloCampoEntradaInput], $documentoModelo: DocumentoModeloInput, $sujeitoAtencao: SujeitoAtencaoInput, $nome: String, $documento: String) {
        criarEImprimirDocumento(documentoModelo: $documentoModelo, sujeitoAtencao: $sujeitoAtencao, camposEntrada: $camposEntrada, nome: $nome, documento: $documento) {
          dataHoraEmissao
          documento
          pdf
          unidade {
            cnpj
            nome
            telefonePrincipal
            endereco {
              bairro
              cep
              complemento
              estado {
                nome
                uf
              }
              municipio {
                nome
              }
              nomeLogradouro
              numero
            }
          }
        }
      }`,
    variables: variablesDTO
  });
};

export const possuiTokenValido = () => {
  return Api.post("", {
    query: `
      query {
        possuiTokenAssinaturaValido
      }`
  });
};
