import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withStyles } from '@material-ui/core/styles/index';
import { Fab, Tooltip } from "@material-ui/core";
import Header from '../../../template/Header/Header';
import styles from '../../../assets/jss/pages/novoDocumentoStyle.js';
import Profile from '../../../template/Header/Profile';
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SaveIcon from '../../../components/Icon/Save';
import CloseIcon from "@material-ui/icons/Close";
import ButtonLoading from '../../../components/ButtonLoading';
import { getUnidadeLogado } from '../../../services/UsuarioService';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { pageConfiguracoes } from '../../../utils/getPageTitle';
import MultiToggleButtons from '../../../components/Button/MultiToggleButtons';
import { tabs } from '../../../stores/Auditoria.store';
import InformacoesModeloDocumento from './InformacoesModeloDocumento';
import Auditoria from '../../../components/Auditoria/Auditoria';
import { saveDocumentoModelo } from '../../../services/ModeloDocumentoService';
import { findAllDocumentoModeloAuditByDocumentoModeloId } from '../../../services/AuditoriaService';
import string from '../../../utils/string';
import ProfissionalIcon from '../../../components/Icon/ProfissionalIcon';
import { ReactSelect } from '../../../components/Select/SelectSearch';
import { checkUserRole } from '../../../utils/checkUserRole';

@inject("googleAnalyticsStore", "auditoriaStore", "modeloDocumentoStore")
@observer
class ModeloDocumento extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.props.modeloDocumentoStore.reset();
    this.props.modeloDocumentoStore.id = parseInt(this.props.match.params.id);
  }

  state = {
    documentModelRaw: '',
    saving: false,
    pageTitle: "",
    tabSelected: tabs.INFORMACOES,
    showFilterProfissional: false,
  };

  async componentDidMount() {
    const { modeloDocumentoStore, auditoriaStore } = this.props;

    this.getPageTitle();
    await modeloDocumentoStore.carregaCamposSistema();

    const canViewFilterProfissional = await checkUserRole('ROLE_DOCUMENTO_MODELO_READ_OUTROS_PROFISSIONAIS');

    canViewFilterProfissional && modeloDocumentoStore.profissionaisSaude.length === 0 && modeloDocumentoStore.getProfissionais();

    this.setState({ showFilterProfissional: canViewFilterProfissional });
    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      auditoria: "o modelo de documento",
      auditoriaTela: "documentoModelo",
    }

    const paginaVisualizada = {
      page: '/#/configuracoes/modelos-documento/ID/editar', 
      title: 'Configurações - Editar modelo de documento'
    }
    this.props.googleAnalyticsStore.pageView(paginaVisualizada);
  }

  getPageTitle = () => {
    const acao = window.location.hash.includes("criar") ? "Novo" : "Editar";
    this.setState({ pageTitle: pageConfiguracoes(`${acao} modelo de documento`) });
  };

  handleUserCampos = el => {
    this.child.current.insertText(el, 'USUARIO');
  };

  handleCamposDoSistema = el => {
    const formated = '${' + el.entidade + '.' + el.campo + '}';
    this.child.current.insertText(formated, 'SISTEMA');
  };


  handleSubmit = async () => {
    const { modeloDocumentoStore } = this.props;
    const { id, documentName, ativo, profissionalSaudeSelected } = modeloDocumentoStore;

    if(!documentName){
      return;
    }
    const text = this.child.current.handleSubmitFromChild();
    const images = this.child.current.handleSubmitImagesFromChild();
    const unidadeAtual = await getUnidadeLogado();

    this.setState({
      documentModelRaw: text,
      saving: true
    });

    const tableStyle  = `
      <style>
        table, th, td {
          border: 1px solid;
        }
      </style>
    `;

    const withUUIDImages = images.filter((image) => {
      return string.isUUID(String(image.id));
    });

    const documentoModelo = {
      nome: documentName,
      documentoModeloImagens: withUUIDImages || [],
      unidade: unidadeAtual,
      modelo:  `${!id ? tableStyle : ''} \n ${text}`,
      id: id || undefined,
      ativo: ativo,
      profissionalSaude: profissionalSaudeSelected?.id && {
        id: profissionalSaudeSelected?.id
      }
    }

    await saveDocumentoModelo(documentoModelo)

    this.setState({saving: false});
    this.goToList();
  };

  goToList = () => {
    const { history } = this.props;
    history.push('/configuracoes/modelos-documento');
  };

  changeTabSelected = async (tabSelected) => {
    const { auditoriaStore, match } = this.props;

    auditoriaStore.auditoriaProps={
      ...auditoriaStore.auditoriaProps,
      pageNumber: 0,
      lastPage: false,
      auditorias: [],
    }

    if (tabSelected === 1) {
      auditoriaStore.load={
        query: findAllDocumentoModeloAuditByDocumentoModeloId, 
        variableName: "documentoModeloId",
        variableId: match.params.id, 
      }
      await auditoriaStore.loadAuditItems();
    }

    this.setState({ tabSelected });
  };

  renderTab = () => {
    const { tabSelected } = this.state;

    return tabSelected === 0 ? 
      <InformacoesModeloDocumento child={this.child}/> 
      : <Auditoria />
  }

  handleProfissionalSaudeChange = (e) => {
    this.props.modeloDocumentoStore.profissionalSaudeSelected = e;
  };

  render() {
    const { classes, modeloDocumentoStore } = this.props;
    const { profissionalSaudeSelected } = modeloDocumentoStore;
    const { pageTitle, tabSelected, showFilterProfissional } = this.state;
    const id = parseInt(this.props.match.params.id);

    return (
      <>
        <PageTitle title={pageTitle}/>
        <Grid container className={classes.root} wrap={'nowrap'}>
          <Header disableShadow className={classes.header}>
            <div className={classes.titleFilterHeader}>
              <h3 className={classes.titleHeader}>Modelo de documentos</h3>
              {
                showFilterProfissional && (
                  <div className={classes.selectProfissional}>
                    <Tooltip
                      title="Profissional"
                      placement="top"
                    >
                      <ProfissionalIcon />
                    </Tooltip>
                    <ReactSelect
                      className={classes.reactSelect}
                      placeholder={'Selecione'}
                      isClearable
                      value={profissionalSaudeSelected}
                      options={modeloDocumentoStore.profissionaisSaude || []} 
                      getOptionLabel={(option) => option.nome}
                      getOptionValue={(option) => option.id}
                      onChange={this.handleProfissionalSaudeChange}
                    />
                  </div>
                )
              }
            </div>
            <Profile />
          </Header>
          <div className={classes.contentToggleButtons}>
            <MultiToggleButtons
              options={["Informações", "Auditoria"]}
              tabSelected={tabSelected}
              changeTabSelected={this.changeTabSelected}
              classes={{ buttonsContainer: classes.buttonsTab }}
              disabled={!id}
              posicaoDesabilitada={[1]}
            />
          </div>
          <div className={classes.contentTab}>
            { this.renderTab() }
          </div>
        </Grid> 
        <div className={classes.acoes}>
          <ButtonLoading 
            loading={this.state.saving}
            icon={SaveIcon}
            onClick={this.handleSubmit}
          />
          <Fab className={classes.buttonCancelar} onClick={this.goToList} disabled={this.state.saving}>
              <CloseIcon/>
          </Fab>
        </div>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(ModeloDocumento));
