import { action, observable } from 'mobx';
import moment from 'moment';
import Api from "../config/api";
import { buildUrlDownload, buildUrlMiniatura, buildUrlFotoPerfil, db } from '../config/config';
import { isEmpty } from '../utils/checkObj';
import LocalStorage from "../services/localStorage";
import localStorageService, { ACCESS_TOKEN_KEY, ATENDIMENTOS_EM_ANDAMENTO } from '../services/storage';
import { finalizarAgendamentoRemoto, findAgendamentoById, reenvioEntradaProntuarioHugg } from '../services/ProntuarioService';
import { findByIdSujeitoAtencao } from '../services/SujeitoAtencaoService';
import string from "../utils/string";
import { checkUserRole } from "../utils/checkUserRole";
import { findByUnidadeLogadaConfiguracaoUnidade } from '../services/ConfiguracoesUnidadeService';

export const tabs = {
    TODOS: 0,
    AGUARDANDO: 1
};

export const tabsContent = {
    PRONTUARIO: 0,
    ODONTOGRAMA: 1
};

const timeoutRetryOptions = {
    retries: 3,
    factor: 1,
    minTimeout: 5000,
    maxTimeout: 5000
};

export const defaultFilters = {
    dataEntrada: null,
    profissionalSaude: null,
    especialidade: null,
};

export default class ProntuarioStore {
    atendimentoStore = null;

    usuarioStore = null;

    chatStore = null;

    profissionalSaudeStore = null;

    totalPagesMensagens = 0;

    messagesScrollToBottom = false;

    filterAgendamentoDefault = {
        pageSize: 20,
        pageNumber: 0,
        sortField: 'horaInicio',
        sortDir: 'ASC'
    };

    filterEntradaDefault = {
        pageSize: 100,
        pageNumber: 0
    };

    tempoTeleconsulta = {
        inicio: '',
        fim: ''
    };
    @observable quantitySolicitacoes = 0;
    @observable tipos = [];

    @observable receituario = {
        open: false
    };

    @observable previewDocumento = {
        open: false,
        descricao: ""
    };

    @observable notification = {
        isOpen: false,
        variant: '',
        message: ''
    };

    @observable isOpenAtendimentoModal = false;

    @observable openModalDadosImportantes = false;

    @observable modalConfirmarTempoTeleconsulta = false;

    @observable isOpenWindowAtendimentoChime = false;

    @observable isOpenModalVinculoSujeito = false;

    @observable modalVinculoSujeitoAlertTitle = "";

    @observable modalVinculoSujeitoAlertDescription = "";

    @observable usuarioLogado = null;

    @observable agendamentoSelected = null;

    @observable sujeitoAtencaoSelected = null;

    @observable mensagemProntuario = "";

    @observable searchTextAgendamento = "";

    @observable sendingMessagemProntuario = false;
    @observable sendingFile = false;

    @observable listEntradas = [];
    @observable agendamentos = [];
    @observable loadingList = {};

    @observable loadingCadastrarNovoSujeito = false;
    @observable loadingAgendamentos = false;
    @observable loadingAllEntradas = false;
    @observable loadingMoreMensagens = false;
    @observable hasMoreMensagens = false;

    @observable timer = {
        initialTime: 0
    };

    @observable inicioAtendimentoLocal = "";
    @observable fimAtendimentoLocal = "";

    @observable searchProntuarioText = "";
    @observable dataEntrada = null;
    @observable filters = defaultFilters;
    @observable roleEntradaProntuarioRead = false;
    @observable confirmaPresencaAgendamento = false;
    @observable utilizaCidObrigatorio = false;
    @observable tabSelected = tabs.TODOS;
    @observable agendamentosAguardando = [];
    @observable selectedCamposProntuario = [];
    @observable callingPaciente = false;

    @observable agendamentosAguardandoFilterOrder = "byArriveTime";

    @observable assinaturaDigitalObrigatoria = false;

    @observable selectedsTypesToShow = [];
    @observable showEntradasOnlyProfissionalLogado = false;
    @observable openFiltroData = false;
    @observable canCreateGuiaInternacao = false;

    constructor(rootStore) {
        this.atendimentoStore = rootStore.atendimentoStore;
        this.usuarioStore = rootStore.usuarioStore;
        this.chatStore = rootStore.chatStore;
        this.profissionalSaudeStore = rootStore.profissionalSaudeStore;
    }

    @action async findAllEntradaProntuario({ filter = {}, pageNumber = 0, withLoading = true, isDateFilter = false } = {}, sujeito = null) {
        try {
            const method = isDateFilter ? 'findAllEntradaProntuarioFiltroDatas' : 'findAllEntradaProntuario';
            this.loadingMoreMensagens = true;
            this.openModalDadosImportantes = false;
            const { especialidade } = this.filters;

            if (withLoading) {
                this.loadingAllEntradas = true;
            }

            let sujeitoAtencao = sujeito || this.agendamentoSelected?.sujeitoAtencao || this.sujeitoAtencaoSelected;

            if (!sujeitoAtencao) {
                return;
            }

            this.sujeitoAtencaoSelected = {
                ...sujeitoAtencao,
                peso: sujeitoAtencao.peso ? `${sujeitoAtencao.peso}`.replace('.', ',') : null,
                altura: sujeitoAtencao.altura ? `${sujeitoAtencao.altura}`.replace('.', ',') : null
            };

            const { id: sujeitoAtencaoId } = sujeitoAtencao || {};

            if (!sujeitoAtencaoId) {
                throw new Error('Nenhum paciente selecionado!');
            }

            const usuarioLogado = await this.usuarioStore.getUsuarioLogado();
            const unidadeLogada = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeLogado = await this.usuarioStore.getProfissionalSaudeAtual();

            this.usuarioLogado = usuarioLogado;

            const unidadeId = unidadeLogada.id;
            const profissionalSaudeId = this.showEntradasOnlyProfissionalLogado ? profissionalSaudeLogado.id : null;

            this.mensagemProntuario = LocalStorage.get(`${sujeitoAtencaoId}-${profissionalSaudeLogado.id}`) || "";

            const filterTipos = this.tipos.includes('TEXTO') ? [...this.tipos, 'ARQUIVO'] : this.tipos;
            const dataInicial = this.dataEntrada;
            const dataFinal = this.dataEntrada;
            const prontuarioExclusivo = profissionalSaudeLogado.prontuarioExclusivo;

            const response = await Api.post(
                '',
                {
                    query: `
                        query ($searchDTO: SearchEntradaProntuarioDTOInput, $pageableDTO: PageableDTOInput) {
                            query: ${method}(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                                last
                                totalPages
                                content {
                                    id
                                    dataHoraLancamento
                                    texto
                                    tipo
                                    urlDownload
                                    urlMiniatura
                                    profissionalSaude {
                                        primeiroNome
                                        nome
                                        id
                                        usuario {
                                            fotoPerfil
                                        }
                                    }
                                    envioExterno
                                    sujeitoAtencao {
                                        nome
                                        fotoPerfil
                                    }
                                    ativo
                                    mensagensHugg {
                                        id
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        searchDTO: {
                            tipos: filterTipos || [],
                            unidadeId,
                            ...(profissionalSaudeId && { profissionalSaudeId }),
                            sujeitoAtencaoId,
                            search: this.searchProntuarioText,
                            dataInicial,
                            dataFinal,
                            prontuarioExclusivo,
                            especialidadeMedicaId: especialidade?.value,
                        },
                        pageableDTO: {
                            ...this.filterEntradaDefault,
                            sortField: 'dataHoraLancamento',
                            sortDir: 'DESC',
                            pageNumber,
                        }
                    }
                },
            );

            if (!response.data.data) {
                throw new Error('Não foi possível carregar o prontuário.');
            }

            const { content } = response.data.data.query;
            const token = await localStorageService.get(ACCESS_TOKEN_KEY);

            const data = content.map(item => {
                let showDate = false;
                const hour = typeof item.dataHoraLancamento === 'string'
                    ? moment(item.dataHoraLancamento.split('[')[0]).format('HH:mm')
                    : null;
                const date = typeof item.dataHoraLancamento === 'string'
                    ? moment(item.dataHoraLancamento.split('[')[0]).format('DD/MM/YYYY')
                    : null;

                return {
                    id: item.id,
                    date,
                    hour,
                    texto: item.texto,
                    tipo: item.tipo,
                    envioExterno: item.envioExterno,
                    sujeitoAtencao: item.sujeitoAtencao,
                    profissionalSaude: item.profissionalSaude,
                    showDate,
                    urlDownload: typeof item.urlDownload === 'string' ? buildUrlDownload(item.urlDownload, token) : null,
                    urlMiniatura: typeof item.urlMiniatura === 'string' ? buildUrlMiniatura(item.urlMiniatura, token) : null,
                    urlFotoPerfil: item.envioExterno ?
                        (item.sujeitoAtencao.fotoPerfil && buildUrlFotoPerfil(item.sujeitoAtencao?.fotoPerfil, token))
                        :
                        (item.profissionalSaude?.usuario?.fotoPerfil && buildUrlFotoPerfil(item.profissionalSaude?.usuario?.fotoPerfil, token)),
                    ativo: item.ativo,
                    mensagensHugg: item?.mensagensHugg || [],

                }
                    ;
            });

            let list = [];

            if (pageNumber > 0) {
                const listLoaded = [...this.listEntradas].reverse();
                list = [...listLoaded, ...data];
            } else {
                list = [...data];
                this.messagesScrollToBottom = true;
            }
            this.listEntradas = list.map((item, key) => {
                let showDate;
                const nextKey = key + 1;
                if (list[nextKey]) {
                    const nextDate = list[nextKey].date;
                    showDate = nextDate !== item.date;
                } else {
                    showDate = true;
                }

                return {
                    ...item,
                    showDate
                };
            }).reverse();

            this.totalPagesMensagens = response.data.data.query.totalPages;
            this.hasMoreMensagens = !response.data.data.query.last;
            this.loadingMoreMensagens = false;
        } catch (error) {
            throw error;
        } finally {
            if (withLoading) {
                this.loadingAllEntradas = false;
            }
        }
    }

    @action async findEntradaProntuarioInativacao(id) {
        const response = await Api.post(
            '',
            {
                query: `
                    query ($id: Long) {
                        query: findEntradaProntuarioInativacao(idEntradaProntuario: $id) {
                            dataInativacao
                            inativador{
                                nome
                            }
                            motivo
                        }
                    }`,
                variables: {
                    id
                }
            }
        )
        return response?.data?.data?.query
    }

    @action getAgendamentosAguardando(agendamentos) {
        const agendamentosAguardando = agendamentos.filter(item => item.situacao === "AGUARDANDO");
        this.agendamentosAguardando = agendamentosAguardando;
    };

    @action async findAllAgendamento(filter = {}, isShowLoading = true, persist = false) {
        try {
            const usuarioLogado = await this.usuarioStore.getUsuarioLogado();
            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();
            this.usuarioLogado = usuarioLogado;

            if (isShowLoading) {
                this.atendimentoStore.filterEvents();
                this.loadingAgendamentos = true;
                this.loadingAllEntradas = true;
                if (persist === false) this.sujeitoAtencaoSelected = null;
            }

            let checkDateFilterInicial = !isEmpty(filter) && filter.dataInicial && filter.dataInicial !== ''
            let checkDateFilterFinal = !isEmpty(filter) && filter.dataInicial && filter.dataInicial !== ''
            const situacoes = this.tabSelected === 1 ? ["AGUARDANDO"] : [];


            const searchDTO = {
                ...filter,
                situacoes,
                unidadeId: unidadeAtual.id,
                profissionalSaudeId: profissionalSaudeAtual.id,
                dataInicial: checkDateFilterInicial ? filter.dataInicial : moment().startOf('day').format('YYYY-MM-DD'),
                dataFinal: checkDateFilterFinal ? filter.dataFinal : moment().endOf('day').format('YYYY-MM-DD')
            };

            const sortField = this.tabSelected === 1 && this.agendamentosAguardandoFilterOrder === 'byArriveTime' ?
                'dataChegadaSujeitoAtencao' :
                'horaInicio';

            const pageableDTO = {
                pageNumber: 0,
                pageSize: 999,
                sortField: sortField,
                sortDir: "ASC"
            }

            const response = await Api.post('', {
                query: `
                query ($searchDTO: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput) {
                    findAllAgendamentoEEncaixe(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                      last
                      totalPages
                      totalElements
                      content {
                        id
                        nome
                        data
                        horaInicio
                        horaFim
                        tipo {
                          id
                          descricao
                        }
                        situacao
                        dataInicioAtendimento
                        dataFimAtendimento
                        dataChegadaSujeitoAtencao
                        dataNascimento
                        remoto
                        linkTelemedicina
                        paciente {
                            id
                            dataNascimento,
                            email,
                            endereco {
                                bairro
                                cep
                                complemento
                                nomeLogradouro
                                numero
                                municipio {
                                    nome
                                }
                                estado {
                                    nome
                                }
                            }
                            fotoPerfil,
                            telefonePrincipal
                        }
                        procedimentos {
                          id
                          procedimento {
                            id
                            nome
                          }
                          realizado
                        }
                        sujeitoAtencao {
                          id
                          nome
                          nomeSocial
                          nomeSocialOuNome
                          cpf
                          dataNascimento
                          nomeSocialOuNome
                          fotoPerfil
                          religiao
                          raca {
                            descricao
                          }
                          sexo {
                            descricao
                          }
                          estadoCivil {
                            descricao
                          }
                          contato {
                            telefonePrincipal
                          }
                          endereco {
                            municipio {
                              nome
                            }
                          }
                          ocupacao {
                            descricao
                          }
                          altura
                          peso
                          dadosConvenio {
                            convenio {
                              descricao
                            }
                          }
                        }
                        urgente
                      }
                    }
                  }
                `,
                variables: {
                    searchDTO,
                    pageableDTO
                }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            const { findAllAgendamentoEEncaixe } = response.data.data;
            const agendamentos = this.filterAgendamentosByStatus(findAllAgendamentoEEncaixe.content, this.atendimentoStore.status);
            this.getAgendamentosAguardando(findAllAgendamentoEEncaixe.content);

            const token = await localStorageService.get(ACCESS_TOKEN_KEY);

            agendamentos.forEach(function (agendamento) {
                if (agendamento.sujeitoAtencao) {
                    if (agendamento.sujeitoAtencao.dataNascimento) {
                        if (agendamento.sujeitoAtencao.dataNascimento.substring(5, 10) === moment().format("MM-DD")) {
                            agendamento.aniversario = true;
                        }
                    }

                    if (agendamento.sujeitoAtencao.fotoPerfil) {
                        agendamento.urlFotoPerfil = buildUrlFotoPerfil(agendamento.sujeitoAtencao.fotoPerfil, token)
                    }
                }

                if (agendamento.dataNascimento) {
                    if (agendamento.dataNascimento.substring(5, 10) === moment().format("MM-DD")) {
                        agendamento.aniversario = true;
                    }
                }
            });

            this.agendamentos = agendamentos;
            if (this.agendamentos[0] && this.agendamentoSelected) {
                if (isShowLoading) {
                    this.loadingAllEntradas = true;

                    let checkDate = checkDateFilterInicial && checkDateFilterFinal;

                    this.selectAgendamento(this.agendamentoSelected?.id || this.agendamentos[0].id, checkDate, persist);
                } else {
                    this.selectAgendamento(this.agendamentoSelected.id, false);
                }
            }

        } catch (error) {
            this.agendamentos = [];
            this.loadingAllEntradas = false;
            throw error;
        } finally {
            this.loadingAllEntradas = false;
            this.loadingAgendamentos = false;
        }
    }

    ordenaAgendamentosAguardandoPorTempoEspera(agendamentos) {
        const agendamentosOrdenado = agendamentos.sort(function (a, b) {
            return moment(a.dataChegadaSujeitoAtencao).format('x') - moment(b.dataChegadaSujeitoAtencao).format('x')
        });

        return agendamentosOrdenado;
    };

    @action updateAgendamentoProcedimentos = async (agendamentoProcedimentos, agendamentoId) => {
        try {
            const response = await Api.post('', {
                query: `
                    mutation($agendamentoProcedimentos: [AgendamentoProcedimentoInput], $agendamentoId: Long) {
                        updateAgendamentoProcedimentosSolicitados(agendamentoProcedimentos: $agendamentoProcedimentos, agendamentoId: $agendamentoId) {
                            id
                        }
                    }
                `,
                variables: {
                    agendamentoProcedimentos,
                    agendamentoId
                }
            });
            this.selectAgendamento(agendamentoId, false);
            if (response.data.errors) {
                throw response.data.errors[0];
            }

            return response;
        } catch (error) {
            this.openNotification(
                error.message,
                "error"
            )
            console.log(error)
        }
    }

    loadEntradas = async (agendamento) => {
        const sujeitoAtencaoNaoVinculado = !(agendamento?.sujeitoAtencao && agendamento?.sujeitoAtencao?.id);
        if (sujeitoAtencaoNaoVinculado) {
            this.listEntradas = [];
            this.modalVinculoSujeitoAlertTitle = `O agendamento ${agendamento?.nome} não possui paciente.`;
            this.modalVinculoSujeitoAlertDescription = `Vincule um paciente existente ou cadastre um novo.`;
            this.isOpenModalVinculoSujeito = true;
            this.openModalDadosImportantes = false;
            return;
        }
        this.mensagemProntuario = "";
        await this.findAllEntradaProntuario();
    }

    getInitialTime = async (agendamento) => {
        if (agendamento?.dataInicioAtendimento && !agendamento?.dataFimAtendimento) {
            const atendimentosEmAndamento = await localStorageService.get(ATENDIMENTOS_EM_ANDAMENTO);
            const atendimentoSelecionado = atendimentosEmAndamento?.find(item => item.id === agendamento.id);
            const inicioAtendimento = atendimentoSelecionado?.inicioAtendimento || agendamento.dataInicioAtendimento;
            return this.timer = {
                initialTime: moment().diff(inicioAtendimento, 'miliseconds'),
            };
        } else if (agendamento?.dataInicioAtendimento && agendamento?.dataFimAtendimento) {
            return this.timer = {
                initialTime: moment(agendamento.dataFimAtendimento).diff(moment(agendamento.dataInicioAtendimento), 'miliseconds'),
            };
        } else {
            return this.timer = {
                initialTime: 0
            }
        }
    }

    @action async selectAgendamento(idAgendamento, fetchEntradas = true, eventPersist = false) {
        this.tipos = [];
        try {
            if (!eventPersist) {
                this.agendamentos = this.agendamentos.map(item => {

                    if (item.id === idAgendamento) {
                        return {
                            ...item,
                            selected: true,
                        };
                    }

                    return {
                        ...item,
                        selected: false,
                    };
                });
            }
            const response = await findAgendamentoById(idAgendamento);
            const { findByIdAgendamento: agendamento } = response?.data?.data;
            this.agendamentoSelected = {
                ...agendamento,
                selected: true
            };

            await this.getInitialTime(agendamento);

            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();

            await db.set("prontuarioAgendamentoSelecionado-" + profissionalSaudeAtual?.id, (idAgendamento));

            if (fetchEntradas) {
                this.loadEntradas(this.agendamentoSelected);
            }
        } catch (error) {
            throw error;
        }
    }


    @action async selectFirstAgendamento() {
        const agendamento = this.agendamentos[0];
        try {
            if (agendamento) {
                this.agendamentoSelected = {
                    ...agendamento,
                    selected: true
                };

                await this.getInitialTime(agendamento);

                this.loadEntradas(agendamento);
            }
        } catch (error) {
            throw error;
        }
    }

    @action openModalConfirmarTempoTeleconsulta() {
        this.modalConfirmarTempoTeleconsulta = true;
    }

    @action closeModalConfirmarTempoTeleconsulta() {
        this.modalConfirmarTempoTeleconsulta = false;
    }

    @action openWindowAtendimentoChime() {
        this.tempoTeleconsulta.inicio = new moment();
        localStorage.setItem('isOpenWindowAtendimentoChime', "true");
        localStorage.setItem('inicioTeleconsulta', this.tempoTeleconsulta.inicio);
        return this.tempoTeleconsulta;
    }

    @action closeWindowAtendimentoChime() {
        this.tempoTeleconsulta.fim = new moment();
        localStorage.setItem('isOpenWindowAtendimentoChime', "false");
        localStorage.setItem('fimTeleconsulta', this.tempoTeleconsulta.fim);
        return this.tempoTeleconsulta;
    }

    @action async chamarProximoAtendimento() {
        try {
            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const tipoProfissional = 2;

            const profissionaisSaude = await this.profissionalSaudeStore.findProfissionalSaudeByUnidadeAndTipo(unidadeAtual?.id, tipoProfissional);

            if (!profissionaisSaude instanceof Array) {
                throw new Error('Profissionais de Saúde deve ser um array');
            }
        } catch (error) {
            throw error;
        }
    }

    @action async createChamada() {
        try {
            const response = await Api.post('', {
                query: `
                    mutation($agendamento: AgendamentoInput){
                        createChamada(agendamento: $agendamento){
                            id
                            dataHoraChamada
                        }
                    }
                `,
                variables: {
                    agendamento: {
                        id: this.agendamentoSelected.id
                    }
                }
            });

            return response
        } catch (error) {
            throw error;
        }
    }

    @action async findReceitaByIdEntradaProntuario(id) {
        try {
            const response = await Api.post('', {
                query: `
                    query {
                        content: findReceitaByIdEntradaProntuario(id: ${id}){
                            id
                            tipo
                            dataHoraLancamento
                            assinado
                            receitaItens {
                                id
                                adscricao
                                formaFarmaceutica
                                remedio
                                receitaItemTipoUso
                                quantidade
                                principioAtivo
                                viaAdministracao
                            }
                            entradaProntuario{
                                urlDownload
                            }
                            sujeitoAtencao {
                                id
                                nome
                                endereco {
                                    nomeLogradouro
                                    numero
                                    cep
                                    municipio {
                                        id
                                        nome
                                    }
                                    estado {
                                        id
                                        uf
                                    }
                                }
                            }
                            oftalmologica {
                                longeOlhoDireitoEsferico
                                longeOlhoDireitoCilindrico
                                longeOlhoDireitoEixo
                                longeOlhoDireitoDnp
                                longeOlhoEsquerdoEsferico
                                longeOlhoEsquerdoCilindrico
                                longeOlhoEsquerdoEixo
                                longeOlhoEsquerdoDnp
                                observacao
                                pertoOlhoDireitoEsferico
                                pertoOlhoDireitoCilindrico
                                pertoOlhoDireitoEixo
                                pertoOlhoDireitoDnp
                                pertoOlhoEsquerdoEsferico
                                pertoOlhoEsquerdoCilindrico
                                pertoOlhoEsquerdoEixo
                                pertoOlhoEsquerdoDnp
                                pertoAdicao
                            }
                            unidade {
                                nome
                                nomeFantasia
                                endereco {
                                    bairro
                                    complemento
                                    municipio {
                                        nome
                                    }
                                    estado {
                                        uf
                                    }
                                    nomeLogradouro
                                    numero
                                }
                                telefonePrincipal
                            }
                        }
                    }
                `
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            const { content } = response.data.data;

            return content;

        } catch (error) {
            throw error;
        }
    }

    @action async openDocumentoByIdEntradaProntuario(id) {
        try {
            if (this.previewDocumento?.content?.isLoading) return;

            this.previewDocumento = {
                content: {
                    isLoading: true,
                }
            };
            const response = await Api.post('', {
                query: `
                    query {
                        content: findDocumentoByIdEntradaProntuario(id: ${id}) {
                        documento
                        assinado
                        pdf
                        id
                        nome
                        entradaProntuario {
                            urlDownload
                        }
                        unidade {
                          cnpj
                          nome
                          telefonePrincipal
                          endereco {
                            bairro
                            cep
                            complemento
                            estado {
                              nome
                              uf
                            }
                            municipio {
                              nome
                            }
                            nomeLogradouro
                            numero
                          }
                        }
                      }
                    }
                `
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            const { content } = response.data.data;

            this.previewDocumento = {
                open: true,
                content
            };

        } catch (error) {
            this.previewDocumento = {
                open: false,
            };
            throw error;
        }
    }

    @action async findAllCampoProntuario() {
        const response = await Api.post('', {
            query: `
                query($campo: String) {
                    findAllCampoProntuario(campo: $campo) {
                      campo
                      nome
                      editavel
                      ordem
                      caminhoValor
                    }
                  }
            `,
            variables: {
                campo: ''
            }
        })

        return response.data.data.findAllCampoProntuario;
    }

    @action async findAllCampoProntuarioByProfissionalSaudeLogadoList() {
        const response = await Api.post('', {
            query: `
                query {
                    findAllCampoProntuarioByProfissionalSaudeLogadoList {
                        campoProntuario {
                            caminhoValor
                            campo
                            editavel
                            nome
                            ordem
                        }
                        ordem
                        profissionalSaude {
                            cpf
                            nome
                        }
                    }
                }
            `
        })

        const ordenarCamposProntuario = response.data.data.findAllCampoProntuarioByProfissionalSaudeLogadoList.sort((a, b) => a.ordem - b.ordem)
        return ordenarCamposProntuario;
    }

    @action async updateFiltroCampoProntuarioProfissionalSaudeLogado(camposSelecionados) {
        const selecionados = camposSelecionados.map(item => {
            return {
                campo: item.campo,
                nome: item.nome,
                editavel: item.editavel,
                caminhoValor: item.caminhoValor,
                ordem: item.ordem
            }
        })

        await Api.post('', {
            query: `
                mutation ($camposProntuario: [CampoProntuarioInput]) {
                    updateFiltroCampoProntuarioProfissionalSaudeLogado(filtroCamposProntuario: $camposProntuario) {
                    ordem
                    campoProntuario{
                        nome
                        caminhoValor
                        editavel
                        nome
                        ordem
                    }
                }
              }
            `,
            variables: {
                camposProntuario: [...selecionados]
            }
        })
    }

    @action async updateSujeitoAtencaoNoAtendimento(value, field) {
        let metodoUpdate = {
            tipoValor: 'String',
            campo: string.capitalizeEachWord(field),
            valor: value
        }
        if (metodoUpdate.campo === "Nome_social") {
            metodoUpdate.campo = "NomeSocial";
        }
        if (metodoUpdate.campo === "Altura" || metodoUpdate.campo === "Peso") {
            if (typeof metodoUpdate.valor === 'string' && metodoUpdate.valor.trim().length) {
                metodoUpdate.valor = parseFloat(metodoUpdate.valor.replace(',', '.'));
            }
            metodoUpdate.tipoValor = 'BigDecimal';
        }

        await Api.post('', {
            query: `
                mutation($agendamento: AgendamentoInput, $sujeitoAtencao: SujeitoAtencaoInput, $valor: ${metodoUpdate.tipoValor}){
                    updateSujeitoAtencao${metodoUpdate.campo}NoAtendimento(agendamento: $agendamento, sujeitoAtencao: $sujeitoAtencao, valor: $valor){
                        id
                    }
                }
            `,
            variables: {
                agendamento: {
                    id: this.agendamentoSelected.id
                },
                sujeitoAtencao: {
                    id: this.sujeitoAtencaoSelected.id,
                },
                valor: metodoUpdate.valor
            }
        });
    }

    @action async updateSujeitoAtencaoAlturaPesoNoAtendimento() {
        try {
            if (!this.agendamentoSelected || !this.sujeitoAtencaoSelected) {
                return;
            }

            let { altura, peso } = this.sujeitoAtencaoSelected;

            altura = typeof altura === 'string' && altura.trim().length
                ? altura
                : `${this.agendamentoSelected.sujeitoAtencao.altura || ''}`;

            if (typeof altura === 'string' && altura.trim().length) {
                altura = parseFloat(altura.replace(',', '.'));
            } else {
                console.warn('Por favor, preencha o campo altura.')
                return;
            }

            peso = typeof peso === 'string' && peso.trim().length
                ? peso
                : `${this.agendamentoSelected.sujeitoAtencao.peso || ''}`;

            if (typeof peso === 'string' && peso.trim().length) {
                peso = parseFloat(peso.replace(',', '.'));
            } else {
                console.warn('Por favor, preencha o campo peso.')
                return;
            }

            const response = await Api.post('', {
                query: `
                    mutation($agendamento: AgendamentoInput, $sujeitoAtencao: SujeitoAtencaoInput){
                        updateSujeitoAtencaoAlturaPesoNoAtendimento(agendamento: $agendamento, sujeitoAtencao: $sujeitoAtencao){
                            id
                        }
                    }
                `,
                variables: {
                    agendamento: {
                        id: this.agendamentoSelected.id
                    },
                    sujeitoAtencao: {
                        id: this.sujeitoAtencaoSelected.id,
                        altura,
                        peso
                    }
                }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            this.sujeitoAtencaoSelected = {
                ...this.sujeitoAtencaoSelected,
                altura: this.sujeitoAtencaoSelected.altura,
                peso: this.sujeitoAtencaoSelected.peso ? `${this.sujeitoAtencaoSelected.peso}`.replace('.', ',') : null,
            };

            this.agendamentoSelected = {
                ...this.agendamentoSelected,
                sujeitoAtencao: {
                    ...this.sujeitoAtencaoSelected
                },
            };

            this.agendamentos = this.agendamentos.map(item => {
                if (item.id === this.agendamentoSelected.id) {
                    return {
                        ...this.agendamentoSelected,
                    };
                }

                return {
                    ...item,
                };
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action async updateSujeitoAtencaoAlturaPesoNoAtendimentoInformacoesPaciente() {

        try {
            if (!this.sujeitoAtencaoSelected) {
                return;
            }

            let { altura, peso } = this.sujeitoAtencaoSelected;

            // eslint-disable-next-line
            const alturaSemMascara = parseInt(altura.trim().replace("/\D/g", ''));
            // eslint-disable-next-line
            const pesoSemMascara = parseFloat(peso.trim().replace("/\D/g", ''));

            const response = await Api.post('', {
                query: `
                    mutation($agendamento: AgendamentoInput, $sujeitoAtencao: SujeitoAtencaoInput){
                        updateSujeitoAtencaoAlturaPesoNoAtendimento(agendamento: $agendamento, sujeitoAtencao: $sujeitoAtencao){
                            id
                        }
                    }
                `,
                variables: {
                    agendamento: {
                        id: null
                    },
                    sujeitoAtencao: {
                        id: this.sujeitoAtencaoSelected.id,
                        altura: alturaSemMascara,
                        peso: pesoSemMascara
                    }
                }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    @action async iniciarAtendimento(agendamento) {
        try {
            const id = agendamento?.id;

            if (!id) {
                return;
            }

            this.agendamentos = this.agendamentos.map(a => {
                if (a.id === id) {
                    this.agendamentoSelected = {
                        ...agendamento,
                    };
                    return this.agendamentoSelected;
                }

                return a;
            });

            const response = await Api.post('', {
                query: `
                    mutation {
                        iniciarAtendimento(id: ${id}){
                            id
                            dataInicioAtendimento
                            dataFimAtendimento
                        }
                    }
                `,
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }
            this.inicioAtendimentoLocal = moment();
            localStorage.setItem('inicioConsulta', this.inicioAtendimentoLocal);
            const atendimentosEmAndamento = await localStorageService.get(ATENDIMENTOS_EM_ANDAMENTO) || [];
            atendimentosEmAndamento.push({
                id,
                inicioAtendimento: this.inicioAtendimentoLocal
            })

            this.timer = {
                initialTime: 0
            }

            localStorageService.set(ATENDIMENTOS_EM_ANDAMENTO, atendimentosEmAndamento);
            return response;
        } catch (error) {
            this.openNotification(
                error.message,
                "error"
            )
            console.error(error);
            throw error;
        }
    }

    @action async finalizarAtendimento(agendamento, persist = true) {
        try {
            const { id } = agendamento || {}

            if (!id) return;
            if (persist) {
                this.agendamentos = this.agendamentos.map(each => {
                    if (each.id === id) {
                        this.agendamentoSelected = {
                            ...agendamento,
                        };
                        return this.agendamentoSelected;
                    }

                    return each;
                });
            }

            const response = await Api.post('', {
                query: `
                    mutation {
                        finalizarAtendimento(id: ${id}){
                            id
                            dataFimAtendimento
                            dataInicioAtendimento
                        }
                    }
                `,
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            this.fimAtendimentoLocal = moment();
            await this.getInitialTime(response);
            const atendimentosEmAndamento = await localStorageService.get(ATENDIMENTOS_EM_ANDAMENTO);
            if (atendimentosEmAndamento) {
                const removeAtendimentoSelecionado = atendimentosEmAndamento.filter(item => item.id !== agendamento.id);
                localStorageService.set(ATENDIMENTOS_EM_ANDAMENTO, removeAtendimentoSelecionado);
            }

            return response;
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.fimAtendimentoLocal = 0;
            this.inicioAtendimentoLocal = 0;
        }
    }

    @action async createEntradaProntuarioTipoTexto({ filter = {}, withLoading = false } = {}, sujeito = {}) {
        try {
            const texto = this.mensagemProntuario;
            const sujeitoAtencaoSelected = this.sujeitoAtencaoSelected;
            if (typeof texto !== "string" || texto.trim().length === 0) {
                return;
            }

            if (!sujeitoAtencaoSelected) {
                throw new Error('Nenhum paciente selecionado.');
            }

            if (!sujeitoAtencaoSelected.id) {
                throw new Error('Nenhum paciente selecionado.');
            }

            LocalStorage.remove(sujeitoAtencaoSelected.id);
            this.sendingMessagemProntuario = true;

            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();

            const mensagem = {
                tipo: 'TEXTO',
                texto,
                sujeitoAtencao: {
                    id: sujeitoAtencaoSelected.id
                },
                unidade: {
                    id: unidadeAtual.id
                },
                profissionalSaude: {
                    id: profissionalSaudeAtual.id
                }
            };

            const response = await Api.post('', {
                query: `
                    mutation($mensagem: EntradaProntuarioInput){
                        createEntradaProntuarioTipoTexto(entradaProntuario: $mensagem){
                            id
                        }
                    }
                `,
                variables: { mensagem }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            this.mensagemProntuario = "";
            this.findAllEntradaProntuario({ filter, withLoading: false }, sujeitoAtencaoSelected);

        } catch (error) {
            throw error;
        } finally {
            this.sendingMessagemProntuario = false;
        }
    }
    @action async inativarEntradaProntuario(id, motivo) {
        try {

            const response = await Api.post('', {
                query: `
                    mutation($id: Long, $motivo: String){
                        inativarEntradaProntuario(idEntradaProntuario: $id, motivo: $motivo){
                            id
                        }
                    }
                `,
                variables: { id, motivo }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

        } catch (error) {
            throw error;
        } finally {
            this.sendingMessagemProntuario = false;
        }
    }

    @action async createEntradaProntuarioTeleconsulta({ inicio, fim, isPacienteNaoCompareceu }) {
        try {
            if (!isPacienteNaoCompareceu && (!inicio || !fim)) {
                throw new Error(`Horário ${inicio ? "fim" : "início"} é obrigatório`);
            }

            const dadosTeleconsulta = JSON.parse(localStorage.getItem('dadosTeleconsulta'));

            if (!dadosTeleconsulta.sujeitoAtencaoId) {
                throw new Error('Nenhum paciente selecionado.');
            }

            this.sendingMessagemProntuario = true;

            const texto = isPacienteNaoCompareceu ?
                'Paciente não compareceu à consulta'
                : this.getTextoEntradaProntuarioTeleconsulta(inicio, fim);


            await this.createEntradaTexto(texto, dadosTeleconsulta, isPacienteNaoCompareceu);
            this.closeModalConfirmarTempoTeleconsulta();
            this.closeWindowAtendimentoChime();
            this.findAllAgendamento({}, true, true);
            this.findAllEntradaProntuario({ filter: {}}, this.sujeitoAtencaoSelected);
        } catch (error) {
            throw error;
        } finally {
            this.sendingMessagemProntuario = false;
        }
    };

    getTextoEntradaProntuarioTeleconsulta = (inicio, fim) => {
        const inicioMoment = moment(inicio);
        const fimMoment = moment(fim);
        const inicioFormatted = inicioMoment.format("HH:mm");
        const fimFormatted = fimMoment.format("HH:mm");
        const duracao = moment.utc(fimMoment.diff(inicioMoment)).format("HH:mm");

        return `Foi realizado atendimento via teleconsulta duração de ${duracao} - Das ${inicioFormatted} até ${fimFormatted}`;
    };

    @action createEntradaTexto = async (texto, dadosTeleconsulta, isPacienteNaoCompareceu) => {
        try {
            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();

            const mensagem = {
                tipo: 'TEXTO',
                texto,
                sujeitoAtencao: {
                    id: dadosTeleconsulta.sujeitoAtencaoId
                },
                unidade: {
                    id: unidadeAtual.id
                },
                profissionalSaude: {
                    id: profissionalSaudeAtual.id
                }
            };

            await finalizarAgendamentoRemoto({ 
                compareceu: !isPacienteNaoCompareceu, 
                agendamento: {id: this.agendamentoSelected?.id}, 
                entradaProntuario: mensagem
            });
        } catch (error) {
            throw error;
        }
    };

    @action async createEntrada() {
        this.previewDocumento.files?.length > 1 ?
            this.createEntradas() :
            this.createEntradaProntuarioFromDocumento()
    }

    @action async createEntradaProntuarioFromDocumento() {
        try {
            this.sendingFile = true;

            const previewDocumento = { ...this.previewDocumento.files[0] }

            const method = previewDocumento.isImage ? 'createEntradaProntuarioTipoImagem' : 'createEntradaProntuarioTipoArquivo';

            const texto = this.previewDocumento.descricao;
            const sujeitoAtencaoSelected = this.sujeitoAtencaoSelected;

            if (!previewDocumento.file instanceof File) {
                throw new Error('Nenhum arquivo selecionado.');
            }

            if (!sujeitoAtencaoSelected) {
                throw new Error('Nenhum paciente selecionado.');
            }

            if (!sujeitoAtencaoSelected.id) {
                throw new Error('Nenhum paciente selecionado.');
            }

            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();

            const mensagem = {
                texto,
                sujeitoAtencao: {
                    id: sujeitoAtencaoSelected.id
                },
                unidade: {
                    id: unidadeAtual.id
                },
                profissionalSaude: {
                    id: profissionalSaudeAtual.id
                }
            };

            const nome = this.getNomeFromFile(previewDocumento.file);
            const base64 = await this.getBase64FromFile(previewDocumento.file);
            const extensao = this.getExtensaoFromFile(previewDocumento.file);
            const date = moment().format('DD/MM/YYYY');
            const hour = moment().format('HH:mm');
            const primeiroNomeProfissionalSaude = profissionalSaudeAtual.nome.split(' ')[0];

            this.loadingList = {
                id: "upload",
                date: date,
                hour: hour,
                texto: nome + extensao,
                tipo: "LOADING",
                profissionalSaude: {
                    primeiroNome: primeiroNomeProfissionalSaude
                }
            }

            this.closePreviewDocumento();
            this.listEntradas.push(this.loadingList);

            const response = await Api.post('', {
                query: `
                    mutation($mensagem: EntradaProntuarioInput, $objetoAmazonS3DTO: ObjetoAmazonS3DTOInput){
                        ${method}(entradaProntuario: $mensagem, objetoAmazonS3DTO: $objetoAmazonS3DTO){
                            id
                        }
                    }
                    `,
                variables: {
                    mensagem,
                    objetoAmazonS3DTO: {
                        nome,
                        base64,
                        extensao
                    }
                }
            })

            this.messagesScrollToBottom = true;

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            this.mensagemProntuario = "";
            this.findAllEntradaProntuario({ withLoading: false }, sujeitoAtencaoSelected);

        } catch (error) {
            throw error;
        } finally {
            this.sendingFile = false;
        }
    }

    @action async createEntradas() {
        try {
            this.sendingFile = true;

            if (!this.sujeitoAtencaoSelected || !this.sujeitoAtencaoSelected.id) {
                throw new Error('Nenhum paciente selecionado.');
            }

            const unidadeAtual = await this.usuarioStore.getUnidadeAtual();
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();

            const mensagem = {
                texto: this.previewDocumento.descricao,
                sujeitoAtencao: {
                    id: this.sujeitoAtencaoSelected.id
                },
                unidade: {
                    id: unidadeAtual.id
                },
                profissionalSaude: {
                    id: profissionalSaudeAtual.id
                }
            };

            const date = moment().format('DD/MM/YYYY');
            const hour = moment().format('HH:mm');
            const primeiroNomeProfissionalSaude = profissionalSaudeAtual.nome.split(' ')[0];

            let createEntradaTipo = "";
            let nomeQuery = "";
            let variablesComNome = null;

            for (let [index, previewDocumento] of this.previewDocumento.files.entries()) {
                const nome = this.getNomeFromFile(previewDocumento.file);
                const base64 = await this.getBase64FromFile(previewDocumento.file);
                const extensao = this.getExtensaoFromFile(previewDocumento.file);

                this.loadingList = {
                    id: "upload",
                    date: date,
                    hour: hour,
                    texto: nome + extensao,
                    ativo: 'true',
                    tipo: "LOADING",
                    profissionalSaude: {
                        primeiroNome: primeiroNomeProfissionalSaude
                    }
                }

                this.closePreviewDocumento();
                this.listEntradas.push(this.loadingList);

                const method = previewDocumento.isImage ? 'createEntradaProntuarioTipoImagem' : 'createEntradaProntuarioTipoArquivo';

                createEntradaTipo = createEntradaTipo + `$mensagem${index}: EntradaProntuarioInput, $objetoAmazonS3DTO${index}: ObjetoAmazonS3DTOInput`;
                nomeQuery = nomeQuery + `create${index}: ${method}(entradaProntuario: $mensagem${index}, objetoAmazonS3DTO: $objetoAmazonS3DTO${index}){
                    id
                } `;
                variablesComNome = {
                    ...variablesComNome,
                    [`mensagem${index}`]: mensagem,
                    [`objetoAmazonS3DTO${index}`]: {
                        nome,
                        base64,
                        extensao
                    }
                }
            };

            const response = await Api.post("", {
                query: `
                    mutation (${createEntradaTipo}){
                        ${nomeQuery}
                    }
                `,
                variables: variablesComNome
            });

            if (response.data.data) {
                this.sendingFile = false;
            };

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            this.messagesScrollToBottom = true;

            this.mensagemProntuario = "";
            await this.findAllEntradaProntuario({ withLoading: false }, this.sujeitoAtencaoSelected);

        } catch (error) {
            throw error;
        }
    }

    @action filterAgendamentosByStatus(agendamentos, status) {
        const filtroStatus = status.filter(situacao => situacao !== "BLOQUEADO");
        return agendamentos
            .filter(agendamento => filtroStatus.includes(agendamento.situacao));
    }

    @action openModalImage(entrada) {
        const { id } = entrada || {};
        const arquivos = entrada?.urls?.map((url) => {
            return {
                id: url?.id,
                imageUrl: url.urlDownload, 
                isImage: true,
                previewOnly: true,
                ativo: entrada.ativo
            }
        })

        this.previewDocumento = {
            id,
            open: true,
            descricao: entrada.texto,
            files: arquivos,
            isImage: true,
            previewOnly: true,
        };
    }

    @action openPreviewDocumento(files, previewOnly = false) {
        let arquivos = [];
        files.forEach((item, index) => {
            const isImage = item.type.search('image/') !== -1;
            const imageUrl = isImage ? window.URL.createObjectURL(item) : null;

            const arquivo = {
                id: String(index),
                descricao: "",
                file: item,
                imageUrl,
                isImage
            }

            arquivos.push(arquivo);

            this.previewDocumento = {
                open: true,
                previewOnly,
                files: arquivos
            };
        });
    }

    @action updateFileList = (fileSelectedId) => {
        const { files } = this.previewDocumento;
        const fileSelectedIndex = files.findIndex(file => file.id === fileSelectedId);
        let filesArray = [...files];
        filesArray[fileSelectedIndex].ativo = !files[fileSelectedIndex].ativo;
        this.previewDocumento = {
            ...this.previewDocumento,
            files: filesArray
        }
    
    };

    @action closePreviewDocumento() {
        this.previewDocumento = {
            open: false,
            descricao: "",
            file: null,
            imageUrl: null,
            isImage: false,
            previewOnly: false
        };
    };

    @action resetPacienteAndAgendamentoSelected = () => {
        this.sujeitoAtencaoSelected = null;
        this.agendamentoSelected = null;
    }

    @action resetNotification() {
        this.notification = {
            message: '',
            variant: '',
            isOpen: false,
        };
    }

    @action closeNotification(reason) {
        if (reason === 'clickaway') {
            return;
        }

        this.resetNotification();
    }

    @action openNotification(message, variant, delay = 2000) {
        this.notification = {
            message,
            variant,
            isOpen: true,
        };

        if (delay) {
            const timeoutId = setTimeout(() => {
                this.resetNotification();
                clearTimeout(timeoutId);
            }, delay);
        }
    }

    @action refreshSelectedSujeitoAtencao = async () => {
        const { id } = this.sujeitoAtencaoSelected;
        try {
            const response = await findByIdSujeitoAtencao(id);
            if (response?.data?.data) {
                const sujeitoAtencaoSelected = response?.data?.data?.findByIdSujeitoAtencao;
                this.sujeitoAtencaoSelected = sujeitoAtencaoSelected;
            } else {
                throw (new Error(response.data.errors[0]));
            }
        } catch (error) {
            console.log(error.message)
        }

    }

    getBase64FromFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = () => resolve(window.btoa(reader.result));
            reader.onerror = error => reject(error);
        });
    }

    @action refreshSelectedSujeitoAtencao = async () => {
        const { id } = this.sujeitoAtencaoSelected;
        try {
            const response = await findByIdSujeitoAtencao(id);
            if (response?.data?.data) {
                const sujeitoAtencaoSelected = response?.data?.data?.findByIdSujeitoAtencao;
                this.sujeitoAtencaoSelected = sujeitoAtencaoSelected;
            } else {
                throw (new Error(response.data.errors[0]));
            }
        } catch (error) {
            console.log(error.message)
        }
    }


    getExtensaoFromFile(file) {
        return '.' + file.name.split('.').pop();
    }

    getNomeFromFile(file) {
        return file.name.replace(this.getExtensaoFromFile(file), '');
    }

    @action checkUserRoleEntradaProntuario = () => {
        const roleEntradaProntuarioRead = checkUserRole('ROLE_ENTRADA_PRONTUARIO_READ');
        this.roleEntradaProntuarioRead = roleEntradaProntuarioRead;
    }

    @action checkUtilizaConfirmacaoPresenca = async () => {
        const response = await findByUnidadeLogadaConfiguracaoUnidade();
        const { confirmaPresencaAgendamento } = response?.data?.data?.findByUnidadeLogadaConfiguracaoUnidade || {}
        this.confirmaPresencaAgendamento = confirmaPresencaAgendamento;
    }

    @action checkUtilizaCIDObrigatorio = async () => {
        const response = await findByUnidadeLogadaConfiguracaoUnidade();
        const { utilizaCidObrigatorio } = response?.data?.data?.findByUnidadeLogadaConfiguracaoUnidade || {}
        this.utilizaCidObrigatorio = utilizaCidObrigatorio;
    }

    @action checkUseDigitalSignature = async () => {
        try {
            const response = await findByUnidadeLogadaConfiguracaoUnidade();
            const { assinaturaDigitalObrigatoria } = response.data.data.findByUnidadeLogadaConfiguracaoUnidade
            this.assinaturaDigitalObrigatoria = assinaturaDigitalObrigatoria;
        } catch (error) {
            console.error(error.message);
        }
    }

    @action reenviarEntradaProntuarioToHugg = async (idEntradaProntuario, type) => {
        try {
            const isSended = await reenvioEntradaProntuarioHugg(idEntradaProntuario);
            if (isSended) {
                this.openNotification(`${string.capitalize(type)} reenviado(a) com sucesso para o paciente.`, 'success');
                this.findAllEntradaProntuario({ withLoading: false });
            }
        } catch (error) {
            this.openNotification(`Erro ao reenviar ${type.toLowerCase()} para o paciente.`, 'error');
        }
    }

}
