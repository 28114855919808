import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, withStyles } from "@material-ui/core";
import EventDay from "./EventDay";
import styles from "./DayCalendar.style";
import {Dates} from "../../../utils"
import ActionsButtons from "../AgendaFloatButtons/ActionsButtons";
import ImpressaoHtml from "../../Impressao/ImpressaoHtml";
import RelatorioAgendaDiaDocument from "../../../template/pdf/relatorio/AgendaDia";
import RelatorioAgendaDiaSalaDocument from "../../../template/pdf/relatorio/AgendaDiaSala";
import string from "../../../utils/string";
import classNames from "classnames";
import { verificaIconsAgendamento } from "../../../utils/verificaIconsAgendamento";
import Notification from "../../Notification";
import { observer } from "mobx-react-lite";
import { inject } from "mobx-react";

export const HeaderAgendamento = props => {
    const { selectedDate, classes, salaDay} = props;
    
    return (
        <div className={salaDay ? classes.dayCalendarHeaderInnerSala : classes.dayCalendarHeaderInner}>
            <div className={classes.dayCalendarColumnHeader} style={{ backgroundColor: '#26ACA9', paddingInline: '16px'}}>
                {moment(selectedDate).format("DD/MM")}
            </div>
            <div className={classes.dayCalendarColumnHeader}>
                Nome
            </div>
            <div className={classes.dayCalendarColumnHeader}>
                {salaDay ? 'Responsável' : 'Idade'}
            </div>
            {!salaDay ? (
                <div className={classNames(classes.dayCalendarColumnHeader, classes.columnHeaderTipo)}>
                    Tipo
                </div>
            )
            :
                <div className={classes.dayCalendarColumnHeader}>
                    Procedimento
                </div>
            }
            {!salaDay && (
            <div className={classes.dayCalendarColumnHeader}>
                Telefone
            </div>
            )}
            <div className={classes.dayCalendarColumnHeader}>
                Convênio
            </div>
            <div className={classes.dayCalendarColumnHeader} style={{ paddingRight: '16px'}}>
                Informações
            </div>
        </div>
    )
};

const Agendamento = props => {

    const {
        salaDay,
        selectedDate,
        todosAgendamentos,
        agendamentos,
        configVigente,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
        tipoProfissionalLogado,
        handlePrint,
        showDisabledByConfig
    } = props;

    const daysOfWeekTransform =  Dates.daysOfWeekTransform();
    const selectDateString = selectedDate.format('dddd');
    const selectedDateTransform = daysOfWeekTransform[string.capitalize(selectDateString)];
    const [listaHorariosAgendamento, setListaHorariosAgendamento] = useState([]);
    const [hideBlocked, setHideBlock] = useState(false);
    const [hideEmpty, setHideEmpty] = useState(false);

    const onClickBlock = (event) => {
        setHideBlock(event);
        localStorage.setItem('hideBlocked', event);
    }

    const onClickEmpty = (event) => {
        setHideEmpty(event);
        localStorage.setItem('hideEmpty', event);
    }

    useEffect(() => {
        const hideBlock = localStorage.getItem('hideBlocked');
        const hideEmpty = localStorage.getItem('hideEmpty');

        setHideBlock(hideBlock === 'true');
        setHideEmpty(hideEmpty === 'true');
    }, [])

    useEffect(() => {
        let listaAgendamentos = [];

        if (!configVigente) {
            setListaHorariosAgendamento([]);
            return;
        }

        if (!agendamentos) {
            return
        }

        const agendamentosFiltrados = agendamentos.reduce((agendamento, atual) => {
            const agendamentoDuplicado = agendamento.find(item => 
                moment(item.start).format("x") === moment(atual.start).format("x") && (atual.status === "BLOQUEADO" && !atual?.permiteAgendamento)
            );

            if (!agendamentoDuplicado) {
                return agendamento.concat([atual]);
            } else {
                return agendamento;
            }
        }, []);


        agendamentosFiltrados.map(agendamento => {
            return listaAgendamentos = [
                ...listaAgendamentos,
                {
                    horaInicio: moment(agendamento?.start).format("HH:mm"),
                    horaFim: moment(agendamento?.end).format("HH:mm"),
                    data: moment(agendamento?.start).format("DD/MM/YYYY"),
                    id: agendamento?.id,
                    nome: agendamento.sujeitoAtencaoNome ? agendamento.sujeitoAtencaoNome : agendamento.nome,
                    convenio: agendamento.convenio || "",
                    status: agendamento?.status,
                    tipo: agendamento?.tipo,
                    destaqueTipo: agendamento?.destaqueTipo,
                    telefone: agendamento.sujeitoAtencaoTelefone &&  agendamento.sujeitoAtencaoTelefoneDDI ? agendamento.sujeitoAtencaoTelefone :
                        agendamento.telefone && agendamento.telefoneDDI ? agendamento.telefone : '',
                    telefoneDDI: agendamento.sujeitoAtencaoTelefone &&  agendamento.sujeitoAtencaoTelefoneDDI ? agendamento.sujeitoAtencaoTelefoneDDI :
                        agendamento.telefone && agendamento.telefoneDDI ? agendamento.telefoneDDI : '',
                    idade: agendamento?.idade,
                    paciente: agendamento?.paciente,
                    procedimentos: agendamento?.procedimentos,
                    solicitante: agendamento?.solicitante,
                    dataNascimento: agendamento?.dataNascimento,
                    chavePublica: agendamento?.chavePublica,
                    profissionalSaudeNome: agendamento?.profissionalSaudeNome,
                    nomeFantasiaUnidade: agendamento?.nomeFantasiaUnidade,
                    mensagemWhatsappEnviada: agendamento?.mensagemWhatsappEnviada,
                    whatsappMensagemQuantidade: agendamento?.whatsappMensagemQuantidade,
                    remoto: agendamento?.remoto,
                    linkTelemedicina: agendamento?.linkTelemedicina,
                    endereco: agendamento?.endereco,
                    observacao: agendamento?.observacao,
                    procedimentoPedido: agendamento?.procedimentoPedido,
                    voucherPrever: agendamento?.voucherPrever,
                    titulos: agendamento?.titulos,
                    permiteAgendamento: agendamento?.permiteAgendamento
                }
            ]
        });
        configVigente[selectedDateTransform].map(configuracaoAtual => {
            const agendamentosHorarioAtual = todosAgendamentos.filter(agendamento => {
                const horaInicio = agendamento.horaInicio || moment(agendamento.start).format("HH:mm");
                const horaFim = agendamento.horaFim || moment(agendamento.end).format("HH:mm");

                const isBetween = (horaInicio === configuracaoAtual.horaInicio) || 
                (moment(moment(configuracaoAtual.horaInicio, "HH:mm")).isBetween(
                    moment(horaInicio, "HH:mm"),
                    moment(horaFim, "HH:mm"))
                );
                
                if (isBetween && agendamento.status !== 'CANCELADO') {
                    return agendamento
                } else {
                    return null
                }
            });

            if (agendamentosHorarioAtual.length === 0) {
                listaAgendamentos = [
                    ...listaAgendamentos,
                    {
                        id: configuracaoAtual?.horaInicio,
                        horaInicio: configuracaoAtual.horaInicio,
                        horaFim: configuracaoAtual?.horaFim,
                        permiteAgendamento: configuracaoAtual?.permiteAgendamento,
                        profissionalSolicitanteConfig: configuracaoAtual?.profissionalSolicitanteConfig,
                        procedimentosConfig: configuracaoAtual?.procedimentosConfig,
                    },
                ];
            }

            return listaAgendamentos;
        });

        if (!showDisabledByConfig) {
            listaAgendamentos = listaAgendamentos.filter(agendamento => agendamento.status !== 'BLOQUEADO' || agendamento.permiteAgendamento);
        }

        if(hideBlocked) {
            listaAgendamentos = listaAgendamentos.filter(agendamento => {
                return agendamento.status !== 'BLOQUEADO';
            });
        }

        if(hideEmpty) {
            listaAgendamentos = listaAgendamentos.filter(agendamento => agendamento.status !== undefined);
        }

        setListaHorariosAgendamento(listaAgendamentos);
    }, [agendamentos, hideBlocked, hideEmpty]);

    return (
        <div>
            {listaHorariosAgendamento.sort((a,b) => moment(a.horaInicio, "HH:mm").format("x") - moment(b.horaInicio, "HH:mm").format("x")).map(agendamento => {
                const possuiTodosIcons = verificaIconsAgendamento(agendamento);

                return (
                    <div
                        key={agendamento.id}
                        className={classNames(classes.dayCalendarEvent, possuiTodosIcons && classes.rowMaior)}
                    >
                        {agendamento && (
                            <EventDay
                                salaDay={salaDay}
                                event={agendamento}
                                selectedDate={selectedDate}
                                onSelectNovoAgendamento={onSelectNovoAgendamento}
                                onSelectAgendamento={onSelectAgendamento}
                                onRemoveAgendamento={onRemoveAgendamento}
                                possuiTodosIcons={possuiTodosIcons}
                            />
                        )}
                    </div>
                );
            })}
            <ActionsButtons 
                userType={tipoProfissionalLogado}
                addButtonAction={() => onSelectAgendamento()}
                hideBlocked={hideBlocked}
                hideEmpty={hideEmpty}
                onClickBlock={() => onClickBlock(!hideBlocked)}
                onClickEmpty={() => onClickEmpty(!hideEmpty)}
                printButtonAction={() => handlePrint()}
            />
        </div>
    );
};

const Calendar = props => {
    const {
        salaDay,
        selectedDate,
        agendamentos,
        classes,
        onSelectAgendamento,
        onSelectNovoAgendamento,
        onRemoveAgendamento,
        configVigente,
        todosAgendamentos,
        tipoProfissionalLogado,
        utilizaTelefoneInternacional,
        configuracaoImpressaoStore,
        nomeProfissionalSelecionado,
        unidadeStore,
    } = props;

    const [printing, setPrinting] = useState(false);
    const [agendamentosOrdenado, setAgendamentosOrdenado] = useState([]);
    const { configuracaoUnidade } = unidadeStore;
    const showDisabledByConfig = configuracaoUnidade?.mostrarHorariosBloqueados;

    const handlePrint = async() => {    
        ordenaAgendamentos();    
        await configuracaoImpressaoStore.getConfig('RELATORIO');
        setPrinting(true);
    };

    const ordenaAgendamentos = () => {
        const apenasAgendamentoOrdenado = agendamentos.filter(item => item.permiteAgendamento).sort((a, b) => moment(a.start, "HH:mm").format("x") - moment(b.end, "HH:mm").format("x"))
        
        setAgendamentosOrdenado(apenasAgendamentoOrdenado);
    };

    return (
        <Grid
        className={classes.dayCalendar}
        item
        xs={12}
        container
        direction={"column"}
        wrap={"nowrap"}
        >
            <HeaderAgendamento selectedDate={selectedDate} classes={classes} salaDay={salaDay} />
            <Agendamento 
                salaDay={salaDay}
                selectedDate={selectedDate}
                agendamentos={agendamentos}
                todosAgendamentos={todosAgendamentos}
                configVigente={configVigente}
                classes={classes}
                onSelectAgendamento={onSelectAgendamento}
                onSelectNovoAgendamento={onSelectNovoAgendamento}
                onRemoveAgendamento={onRemoveAgendamento}
                tipoProfissionalLogado={tipoProfissionalLogado}
                handlePrint={handlePrint}
                showDisabledByConfig={showDisabledByConfig}
            />

            {printing && 
                <ImpressaoHtml
                    isPrintMustache={printing}
                    handlePrintMustache={() => setPrinting(false)}
                    htmlStringComponent={ salaDay ?
                    <RelatorioAgendaDiaSalaDocument
                        data={selectedDate}
                        agendamentos={agendamentosOrdenado}
                    />
                    :
                    <RelatorioAgendaDiaDocument
                        data={selectedDate}
                        agendamentos={agendamentosOrdenado}
                        utilizaTelefoneInternacional={utilizaTelefoneInternacional}
                        profissional={nomeProfissionalSelecionado}
                    />}
                />
            }
        </Grid>

    )
};

const DayCalendar = observer((props) => {
    const { atendimentoStore } = props;
    const { notification } = atendimentoStore;
    const { isOpen, message, variant } = notification;
    return (
        <>
            <Calendar {...props} />
            <Notification
                isOpen={isOpen}
                message={message}
                variant={variant}
            />
        </>
    );
});

const stores = ['atendimentoStore', 'unidadeStore']
const DayCalendarStyles = withStyles(styles)(DayCalendar)
export default inject(...stores)(DayCalendarStyles);