import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import PersonEditIcon from '../Icon/PersonEdit'
import ButtonWhatsApp from '../Button/ButtonWhatsApp'
import dates from '../../utils/dates'
import GracefulImage from '../Image/GracefulImage'
import { buildUrlFotoPerfil } from '../../config/config'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import { getDadosMensagem } from '../../pages/Atendimento/utils/getDadosMensagem'
import { goToWhatsApp } from '../../utils/goToWhatsApp'
import moment from 'moment'
import { applyPhoneMask } from '../../utils/PhoneMask'
import string from '../../utils/string'

const profileDefault = require('../../assets/img/svg/img-profile-default.svg')

const Info = ({ classes, label, value, endEnhancer }) => {
  if (!value) return null
  return (
    <div className={classes.wrapperInfos}>
      <span className={classes.label}>{label} </span>
      <span className={classes.value}>{value}</span>
      {endEnhancer}
    </div>
  )
}

const HeaderInfosPaciente = observer(
  ({ classes, sujeitoAtencao, atendimentoStore, handleClickEditSujeitoAtencao }) => {
    const { nome, dataNascimento, contato, dadosConvenio, endereco, fotoPerfil } = sujeitoAtencao
    const { accessToken, objView, prontuarioStore } = atendimentoStore

    const handleClickWhatsapp =  (telefonePrincipal, telefonePrincipalDDI) => {
      return goToWhatsApp(telefonePrincipal, telefonePrincipalDDI, "")
    }

    useEffect(() => {
      if (!prontuarioStore.agendamentoSelected?.id) return
      atendimentoStore.loadAgendamento(prontuarioStore.agendamentoSelected?.id)
    }, [prontuarioStore.agendamentoSelected?.id])

    return (
      <div className={classes.container}>
        <h1 className={classes.title}>Infos do paciente:</h1>
        <div className={classes.containerHeader}>
          <section className={classes.containerImage}>
            <GracefulImage
              src={fotoPerfil ? buildUrlFotoPerfil(fotoPerfil, accessToken) : profileDefault}
              alt={'Foto do paciente'}
              height='64'
              className={classes.imageMini}
              placeholderColor={'#00ACA9'}
            />
          </section>
          <section className={classes.containerInfos}>
            <div className={classes.namePaciente}>
              <h2>{string.truncate(nome, 30)}</h2>
              <PersonEditIcon
                color='white'
                style={{ cursor: 'pointer' }}
                onClick={handleClickEditSujeitoAtencao}
              />
            </div>
            <div className={classes.inlineInfos}>
              <Info
                classes={classes}
                label='Telefone:'
                value={applyPhoneMask(contato?.telefonePrincipal)}
                endEnhancer={
                  <ButtonWhatsApp
                    messageSent={objView?.mensagemWhatsappEnviada}
                    labelTooltip='Mensagem enviada, deseja enviar novamente?'
                    onClick={() => handleClickWhatsapp(contato?.telefonePrincipal, '55')}
                  />
                }
              />
              <Info
                classes={classes}
                label='Telefone:'
                value={applyPhoneMask(contato?.telefoneSecundario)}
                endEnhancer={
                  <ButtonWhatsApp
                    messageSent={objView?.mensagemWhatsappEnviada}
                    labelTooltip='Mensagem enviada, deseja enviar novamente?'
                    onClick={() => handleClickWhatsapp(contato?.telefoneSecundario, '55')}
                  />
                }
              />
            </div>
            <Info classes={classes} label='Idade:' value={dates.calculaIdade(dataNascimento)} />
            <div className={classes.inlineInfos}>
              <Info
                classes={classes}
                label='Convênio:'
                value={dadosConvenio?.convenio?.descricao}
              />
              <Info
                classes={classes}
                label='Municipio:'
                value={endereco?.municipio ? `${endereco?.municipio?.nome}-${endereco?.municipio?.uf}` : ""}
              />
            </div>
          </section>
        </div>
      </div>
    )
  }
)

const styles = () => ({
  title: {
    color: '#219A97',
    fontSize: '18px',
    fontWeight: '700',
    margin: '0',
  },
  container: {
    padding: '1rem',
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  imageMini: {
    borderRadius: '50%',
  },
  containerImage: {
    display: 'flex',
    alignItems: 'center',
  },
  containerInfos: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 1rem',
    gap: '0.3rem',
    flex: '5',
  },
  wrapperInfos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.3rem',
  },
  inlineInfos: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  label: {
    color: '#505050',
    fontSize: '14px',
    fontWeight: '400',
  },
  value: {
    color: '#505050',
    fontSize: '14px',
    fontWeight: '700',
    whiteSpace: 'nowrap',
  },
  namePaciente: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    '& h2': {
      color: '#505050',
      fontSize: '18px',
      fontWeight: '700',
      margin: '0',
    },
  },
})

const StyledHeaderInfosPaciente = withStyles(styles)(HeaderInfosPaciente)

export default inject('atendimentoStore')(StyledHeaderInfosPaciente)
