import { action, observable } from 'mobx';
import Api, { NestorApi } from '../config/api';
import { createFindAllQuery, createFindByObjectQuery, createMutation } from '../pages/Querys';
import string from "../utils/string";
import moment from "moment"
import { quantidadeSujeitoDeAtencaoCadastrados } from './../services/SujeitoAtencaoService'
import localStorageService, { ACCESS_TOKEN_KEY, UNIDADE_LOGADA_KEY } from '../services/storage';
import { buildUrlFotoPerfil, buildUrlImagemDocumentoTemporario, db } from '../config/config';
import { getUnidadeLogado } from '../services/UsuarioService';
import { findAgendamentosByIdSujeitoAtencao } from '../services/AgendamentoService';
import validaDocumentoEspanha from '../utils/validacaoDocumentos';
import { liberarAcessoVizi } from './../services/SujeitoAtencaoService';
import { findByUnidadeLogadaConfiguracaoUnidade } from '../services/ConfiguracoesUnidadeService';
import { CAMPOS_OBRIGATORIOS } from '../pages/Configuracoes/ConfiguracaoUnidade/Tabs/Pacientes';


const defaultObjView = {
    id: '',
    nome: '',
    nomeSocial: '',
    dataNascimento: null,
    convenio: null,
    sexoId: 3,
    documentoTipo: null,
    documento: '',
    espanhaMunicipio: null,
    rg: '',
    cns: '',
    comoConheceu: null,
    quemIndicou: '',
    profissionalSaude: null,
    bairro: '',
    cep: '',
    complemento: '',
    nomeLogradouro: '',
    numero: '',
    estado: null,
    municipio: null,
    email: '',
    receberMensagemEmail: true,
    receberMensagemWhatsappTelefonePrincipal: true,
    receberMensagemWhatsappTelefoneSecundario: true,
    telefonePrincipal: '',
    telefonePrincipalDDI: '',
    telefoneSecundario: '',
    telefoneSecundarioDDI: '',
    observacao: '',
    racaId: null,
    religiao: '',
    nomeMae: '',
    nomePai: '',
    escolaridadeId: null,
    ocupacao: null,
    pais: null,
    nacionalidadeId: null,
    municipioOrigem: null,
    estadoOrigem: null,
    dataEntradaBrasil: null,
    nomeResponsavel: '',
    documentoResponsavel: '',
    numeroCarteira: '',
    validadeCarteira: null,
    ativo: true,
    estadoCivil: null,
    urlImagemPerfil: undefined,
    fotoPerfil: null,
    anademPaciente: 'NAO_HABILITAR',
    permiteAcessoApp: [],
    loadingSave: false,
    loadingPrint: false,
    tipoLogradouro: null,
    paciente: null,
};

const sujeitoObjViewFromAgendamentoDefault = {
    nome: '',
    documento: '',
    dataNascimento: null,
    email: '',
    fotoPerfil: null,
    paciente: null,
    dadosConvenio: null,
    dadosConvenios: [],
    telefonePrincipal: null,
    telefonePrincipalDDI: '55'
}

const filterDefault = {
    search: '',
    ativo: { value: true },
    convenioId: null,
    municipioId: null,
    profissionalSaudeId: null,
    pageNumber: 0,
    pageSize: 30,
    sortField: 'nome',
    sortDir: 'ASC'
};

export default class SujeitoAtencaoStore {
    usuarioStore = null;
    unidadeStore = null;
    atendimentoStore = null;
    @observable open = false;
    @observable idOpened = null;
    @observable anademPaciente = 'NAO_HABILITAR';
    @observable sujeitoAtencaoFilter = filterDefault;

    @observable convenios = [];
    @observable sexos = [];
    @observable nacionalidades = [];
    @observable estados = [];
    @observable escolaridades = [];
    @observable racas = [];
    @observable paises = [];
    @observable municipios = [];
    @observable municipiosOrigem = [];
    @observable comoConheceuEnum = [];
    @observable estadosCivis = [];
    @observable tiposLogradouro = [];
    @observable profissionaisSaudeComAgenda = [];
    @observable profissionaisPermiteAcessoApp = [];
    @observable unidadeUtilizaTelefoneInternacional = false;
    @observable loadingPacientes = false;
    @observable documentosTipo = [];
    @observable espanhaComunidadesAutonoma = [];
    @observable espanhaProvincias = [];
    @observable espanhaMunicipios = [];
    @observable comunidadeAutonoma = null;
    @observable provincia = null;
    @observable screenToBack = '';
    @observable isLoadingTable = false;
    @observable hasMoreToLoad = false;
    @observable camposSujeitoAtencao = [];
    @observable utilizaImpressaoCartao = false;

    @observable notification = {
        isOpen: false,
        variant: '',
        message: ''
    };

    @observable objView = {
        ...defaultObjView
    };

    @observable errors = {
        nome: false,
        telefone: false,
        cpf: false,
        rg: false,
        dataNascimento: false,
        cep: false,
        bairro: false,
        estado: false,
        municipio: false,
        nomeLogradouro: false,
        tipoLogradouro: false,
        numero: false,
        convenio: false,
        numeroCarteira: false,
        validadeCarteira: false,
        documentoResponsavel: false,
    };

    @observable isSujeitoAtencaoUnificado = false;

    @observable ultimaConsulta = undefined;

    @observable willLinkViziHealthPaciente = false;
    @observable sujeitoToSetOnAgendamento = false;
    @observable sujeitoObjViewFromAgendamento = sujeitoObjViewFromAgendamentoDefault;
    @observable withSessionControl = false;

    constructor(rootStore) {
        this.usuarioStore = rootStore.usuarioStore;
        this.unidadeStore = rootStore.unidadeStore;
        this.atendimentoStore = rootStore.atendimentoStore;
    }

    @action load(id) {
        if (id) {
            this.idOpened = id;
            this.loadSujeitoAtencao();
        } else {
            this.newSujeitoAtencao();
        }
    }

    changeId(id) {
        this.idOpened = id;
    }

    changeOpen() {
        this.open = !this.open;
    }

    async newSujeitoAtencao() {
        try {
            if (!this.open) {
                const attrsReturn = await this.getAttrsReturn();

                const result = await Api.post('',
                    {
                        query: `
                        {
                            sujeitoAtencaoPadrao {
                                ${attrsReturn.sujeitoAtencao}
                            }

                            ${attrsReturn.dominios}
                        }
                        `
                    });

                this.changeOpen();

                const {
                    sujeitoAtencaoPadrao: entidade,
                    findAllSexoList,
                    findAllNacionalidadeList,
                    findAllEstadoList,
                    findAllEscolaridadeList,
                    findAllEstadoCivilList,
                    findAllRacaList,
                    findAllConvenioList,
                    findProfissionalSaudeByUnidadeComAgenda,
                    findAllEspanhaComunidadeAutonoma,
                    findAllDocumentoTipo,
                } = result.data.data;

                let dominios = {
                    sexo: findAllSexoList,
                    nacionalidades: findAllNacionalidadeList,
                    estados: findAllEstadoList,
                    racas: findAllRacaList,
                    escolaridades: findAllEscolaridadeList,
                    estadosCivis: findAllEstadoCivilList,
                    convenios: findAllConvenioList,
                    profissionaisSaudeComAgenda: findProfissionalSaudeByUnidadeComAgenda,
                    espanhaComunidadesAutonoma: findAllEspanhaComunidadeAutonoma,
                    documentosTipo: findAllDocumentoTipo,
                };
                return this.initObjView(this.willLinkViziHealthPaciente ? { ...this.objView } : entidade, dominios);
            }
        } catch (error) {
            throw error;
        }
    }

    validaDocumento = () => {
        const { documentoTipo, documento } = this.objView;

        let errorDocumento = false;

        if (documentoTipo) {
            if (documentoTipo?.descricao === "DNI" || documentoTipo?.descricao === "NIE") {
                errorDocumento = !validaDocumentoEspanha(documento);
            } else {
                errorDocumento = (documento == null || string.removeSpecialChars(documento).length > 0) &&
                    !string.validaCPF(documento);
            };
        };

        return errorDocumento
    }

    @action findConfigUnidade = async () => {
        const response = await findByUnidadeLogadaConfiguracaoUnidade()
        const { camposSujeitoAtencao, utilizaImpressaoCartao } = response.data.data.findByUnidadeLogadaConfiguracaoUnidade
        this.camposSujeitoAtencao = camposSujeitoAtencao
        this.utilizaImpressaoCartao = utilizaImpressaoCartao
    }

    validateTelefone = () => {
        const { telefonePrincipal, telefonePrincipalDDI } = this.objView;
        const telefone = this.unidadeUtilizaTelefoneInternacional ? telefonePrincipal : string.removeSpecialChars(telefonePrincipal);
        const telefoneDDI = this.unidadeUtilizaTelefoneInternacional ? telefonePrincipalDDI : '55';

        return string.isEmpty(telefone) || (string.isEmpty(telefoneDDI) && !string.isEmpty(telefone));
    }

    @action contemErros({ revalidate = false } = {}) {
        const {
            nome,
            rg,
            dataNascimento,
            documento,
            cep,
            bairro,
            estado,
            tipoLogradouro,
            documentoResponsavel,
            municipio,
            nomeLogradouro,
            numero,
            dadosConvenios
        } = this.objView;
        const camposObrigatorios = this.camposSujeitoAtencao.filter(campo => campo.status === true).map(campo => campo.campo)
        const isDocumentoObrigatorio = camposObrigatorios?.includes(CAMPOS_OBRIGATORIOS.CPF);
        const bothDocumentsInvalids = isDocumentoObrigatorio && string.isEmpty(documento) && string.isEmpty(documentoResponsavel);

        this.errors = {
            nome: string.isEmpty(nome),
            telefone: this.validateTelefone(),
            documento: bothDocumentsInvalids || (documento?.length > 0 && !string.validaCPF(documento)),
            ...((bothDocumentsInvalids || (documentoResponsavel?.length > 0 && !string.validaCPF(documentoResponsavel))) && 
                { documentoResponsavel: true }),
            rg: camposObrigatorios.includes(CAMPOS_OBRIGATORIOS.RG) && string.isEmpty(rg),
            dataNascimento: camposObrigatorios.includes(CAMPOS_OBRIGATORIOS.DATA_NASCIMENTO) && !dataNascimento,
            ...(camposObrigatorios.includes(CAMPOS_OBRIGATORIOS.ENDERECO_RESIDENCIAL) && {
                cep: string.isEmpty(cep),
                bairro: string.isEmpty(bairro),
                estado: !estado?.value,
                municipio: !municipio?.value,
                nomeLogradouro: string.isEmpty(nomeLogradouro),
                numero: string.isEmpty(numero),
                tipoLogradouro: !tipoLogradouro?.value
            }),
            ...(camposObrigatorios.includes(CAMPOS_OBRIGATORIOS.CONVENIO) && {
                convenio: dadosConvenios.length === 0
            })
        };
        if (Object.values(this.errors).some(error => error) && !revalidate) {
            this.openNotification('Por favor, preencha os campos obrigatórios.', 'error');
            return true;
        }
        return false
    }

    @action async getObjViewOnStorage(objViewId) {
        try {
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();
            const profissionalSaudeId = profissionalSaudeAtual?.id;
            const data = await db.get(`sujeitoAtencao_${objViewId}_ps${profissionalSaudeId}`);
            return JSON.parse(data);
        } catch (error) {
            console.log(error)
            return null;
        }
    }

    @action async removeObjViewOnStorage(objViewId) {
        try {
            const profissionalSaudeAtual = await this.usuarioStore.getProfissionalSaudeAtual();
            const profissionalSaudeId = profissionalSaudeAtual?.id;
            await db.remove(`sujeitoAtencao_${objViewId}_ps${profissionalSaudeId}`);
        } catch (error) {
            console.log(error);
        }
    }

    @action async loadSujeitoAtencao() {
        if (!this.open) {
            this.openNotification("Carregando...", "info");
            const attrsReturn = await this.getAttrsReturn();
            Api.post('',
                {
                    query: `
                        query ($id: UUID){
                            findByIdSujeitoAtencao(id: $id){
                                ${attrsReturn.sujeitoAtencao}
                            }

                            ${attrsReturn.dominios}
                        }
                    `,
                    variables: { id: this.idOpened }
                })
                .then((response) => {
                    this.changeOpen();
                    this.closeNotification();
                    this.resetNotification();
                    const {
                        findByIdSujeitoAtencao: entidade,
                        findAllSexoList,
                        findAllNacionalidadeList,
                        findAllEstadoList,
                        findAllEscolaridadeList,
                        findAllRacaList,
                        findAllConvenioList,
                        findAllEstadoCivilList,
                        findProfissionalSaudeByUnidadeComAgenda,
                        findAllEspanhaComunidadeAutonoma,
                        findAllDocumentoTipo,
                    } = response.data.data;

                    let dominios = {
                        sexo: findAllSexoList,
                        nacionalidades: findAllNacionalidadeList,
                        estados: findAllEstadoList,
                        racas: findAllRacaList,
                        convenios: findAllConvenioList,
                        escolaridades: findAllEscolaridadeList,
                        estadosCivis: findAllEstadoCivilList,
                        profissionaisSaudeComAgenda: findProfissionalSaudeByUnidadeComAgenda,
                        espanhaComunidadesAutonoma: findAllEspanhaComunidadeAutonoma,
                        documentosTipo: findAllDocumentoTipo,
                    };

                    return this.initObjView(this.willLinkViziHealthPaciente ? this.mergeAgendamentoViewWithSujeito(entidade) : entidade, dominios)
                });
        }
    }

    @action mergeAgendamentoViewWithSujeito = (entidade) => {
        const dataFields = ['dadosConveio', 'dataNascimento', 'documento', 'fotoPerfil',];
        const contactFields = ['email, telefonePrincipal', 'telefonePrincipalDDI'];

        this.mergeObjects(dataFields, entidade, this.sujeitoObjViewFromAgendamento);
        this.mergeObjects(contactFields, entidade?.contato, this.sujeitoObjViewFromAgendamento);

        return entidade;
    }

    mergeObjects = (fields, object, objectToMerge) => {
        fields.forEach((key) => {
            const value = object?.[key]
            if (typeof value === 'object') {
                return
            }

            if (!value && objectToMerge?.[key]) {
                object[key] = objectToMerge?.[key]
            }
        });
    }

    @action save() {
        if (this.objView.anademPaciente === 'NAO_HABILITAR') {
            this.objView.permiteAcessoApp = []
        }

        this.loadingSave = true;

        if (this.idOpened === null)
            this.saveNewSujeitoAtencao();
        else
            this.updateSujeitoAtencao();
    }

    @action reset() {
        this.idOpened = null;
        this.open = false;
        this.municipios = [];
        this.municipiosOrigem = [];
        this.permiteAcessoApp = [];
        this.errors = { nome: false, email: false, telefone: false, documento: false };
        this.isSujeitoAtencaoUnificado = false;
        this.objView = {
            ...defaultObjView
        };
    }

    @action resetFilter = () => {
        this.sujeitoAtencaoFilter = filterDefault;
    }

    @action findMunicipioBy(estado) {
        if (estado) {
            this.findMunicipio(estado.value).then((result) => {
                let data = result.data.data.findByEstado;
                this.municipios = data ? data.map(c => ({
                    value: c.id,
                    name: string.capitalize(c.nome)
                })) : this.municipios;
            });
        } else {
            this.municipios = []
        }
    }

    @action findMunicipioOrigemBy(estado) {
        if (estado) {
            this.findMunicipio(estado.value).then((result) => {
                let data = result.data.data.findByEstado;
                this.municipiosOrigem = data ? data.map(c => ({
                    value: c.id,
                    name: string.capitalize(c.nome)
                })) : this.municipiosOrigem;
            });
        } else {
            this.municipiosOrigem = []
        }

    }

    @action findProvinciaBy(comunidadeAutonoma) {
        if (comunidadeAutonoma) {
            this.findProvincia(comunidadeAutonoma.value).then((result) => {
                this.espanhaProvincias = result ? result.map(item => ({
                    value: item.id,
                    name: string.capitalize(item.descricao)
                })) : this.espanhaProvincias;
            });
        } else {
            this.espanhaProvincias = []
        }
    }


    findProvincia = async (codigoComunidadeAutonoma) => {
        const response = await Api.post('',
            {
                query: `
                    query ($codigoComunidadeAutonoma: String, $pageableDTO: PageableDTOInput){
                        findAllEspanhaProvincia(codigoComunidadeAutonoma: $codigoComunidadeAutonoma, pageableDTO: $pageableDTO){
                            id
                            descricao                 
                        }
                    }`,
                variables: {
                    pageableDTO: { sortField: "descricao", sortDir: "ASC" },
                    codigoComunidadeAutonoma
                }
            }
        );
        return response.data.data.findAllEspanhaProvincia;
    }

    @action async findEspanhaMunicipio(variables) {
        try {
            const response = await Api.post('',
                {
                    query: `
                        query ($codigoProvincia: String, $pageableDTO: PageableDTOInput, $descricao: String){
                            findAllEspanhaMunicipio(codigoProvincia: $codigoProvincia, pageableDTO: $pageableDTO, descricao: $descricao){
                                last
                                content {
                                    id
                                    codigoMunicipio
                                    descricao
                                }               
                            }
                        }`,
                    variables: variables
                })

            const { last, content } = response.data.data.findAllEspanhaMunicipio;

            const customContent = content.map(item => {
                const descricaoCapitalize = string.capitalize(item.descricao)

                return ({
                    ...item,
                    value: item.id,
                    label: descricaoCapitalize
                })
            });

            return {
                last,
                content: customContent
            };
        } catch (error) {
            throw error;
        }
    }

    findMunicipio(estadoId) {
        let estado = { id: estadoId };
        return Api.post('',
            JSON.stringify(
                {
                    query: createFindByObjectQuery({
                        name: 'estado',
                        objType: 'EstadoInput',
                        objName: 'estado',
                        attrsReturn: `id nome`
                    }, 'query'),
                    variables: { estado: estado }
                })
        );
    }


    @action consultaSujeitosDeAtencao = async (page) => {
        try {
            this.loadingPacientes = true;
            const unidadeAtual = await getUnidadeLogado();
            const unidadeId = unidadeAtual && unidadeAtual.id ? unidadeAtual.id : null;
            const redeId = unidadeAtual && unidadeAtual.rede && unidadeAtual.rede.id ? unidadeAtual.rede.id : null;
            const response = await Api.post('',
                {
                    query: `
                    query ($searchDTO: SearchSujeitoAtencaoDTOInput){

                        findAllSujeitoAtencao(searchDTO: $searchDTO){
                            totalElements
                            numberOfElements
                            last
                            content {
                                id
                                nome
                                cns
                                sexo{
                                id
                                descricao
                                }
                                documentoTipo{
                                    id
                                    descricao
                                }
                                dadosConvenio {
                                    id
                                    convenioPrincipal
                                    validadeCarteira
                                    numeroCarteira
                                    convenio {
                                        id
                                        descricao
                                    }
                                }
                                dadosConvenios {
                                    id
                                    convenioPrincipal
                                    validadeCarteira
                                    numeroCarteira
                                    convenio {
                                        id
                                        descricao
                                    }
                                }
                                documento
                                dataNascimento
                                ativo
                                religiao
                                idade
                                estadoCivil {
                                    descricao
                                    id
                                }
                                raca {
                                    descricao
                                    id
                                }
                                nomeMae
                                nomePai
                                nomeSocial
                                ocupacao {
                                    descricao
                                    id
                                }
                                rg
                                profissionalSaude{
                                    id
                                    nome
                                }
                                contato {
                                    telefonePrincipal
                                    telefonePrincipalDDI
                                    email
                                }
                                endereco {
                                    cep
                                    bairro
                                    estado{
                                        nome
                                    }
                                    municipio {
                                        nome
                                        uf
                                    }
                                }
                                espanhaMunicipio {
                                    descricao
                                    id
                                }
                                paciente {
                                    email
                                }
                                origem{
                                    nacionalidade{
                                        descricao
                                        id
                                    }
                                }
                            }
                        }
                    }`,
                    variables: {
                        searchDTO: {
                            ...page,
                            ativo: page.ativo?.value,
                            municipioId: page.municipioId?.value,
                            profissionalSaudeId: page.profissionalSaudeId?.id,
                            convenioId: page.convenioId?.id,
                            redeId,
                            unidadeId,
                            sortField: page?.sortField || 'nome',
                            sortDir: page?.sortDir || 'ASC'
                        }
                    }
                }
            );

            const { findAllSujeitoAtencao } = response.data.data;
            findAllSujeitoAtencao.content = findAllSujeitoAtencao.content.map(item => {
                let telefone = '';
                if (this.unidadeUtilizaTelefoneInternacional) {
                    telefone = this.formatPhone(item.contato?.telefonePrincipal, item.contato?.telefonePrincipalDDI);
                } else {
                    telefone = string.format('(##) #####-####', item.contato?.telefonePrincipal)
                }

                return {
                    ...item,
                    documentoTipo: item.documentoTipo,
                    documento: string.isEmpty(item.documento) ? null : string.format('###.###.###-##', item.documento),
                    rg: string.isEmpty(item.rg) ? null : string.format('##.###.###-#', item.rg),
                    dataNascimento: string.isEmpty(item.dataNascimento) ? null : moment(item.dataNascimento).format('DD/MM/YYYY'),
                    telefone,
                    contato: {
                        ...item.contato,
                        telefonePrincipal: telefone
                    },
                    endereco: {
                        ...item.endereco,
                        cep: string.isEmpty(item.endereco?.cep) ? null : string.format('#####-###', item.endereco?.cep),
                    },
                    municipio: item.endereco?.municipio?.nome,
                    estado: item.endereco?.municipio?.uf
                }
            });

            return findAllSujeitoAtencao;
        } catch (error) {
            throw error;
        } finally {
            this.loadingPacientes = false;
        }
    };

    @action consultaMunicipios = async (variables) => {
        try {
            const unidadeAtual = await getUnidadeLogado();
            const redeId = unidadeAtual?.rede?.id;
            const response = await Api.post('',
                {
                    query: `
                        query ($page: SearchDTOInput){
                            findAllMunicipio(searchDTO: $page){
                                totalElements
                                numberOfElements
                                last
                                content {
                                    id
                                    nome
                                    uf
                                }
                            }
                        }`,
                    variables: {
                        page: {
                            redeId,
                            sortField: 'nome',
                            sortDir: 'ASC',
                            search: variables.search
                        }
                    }
                }
            );

            const { findAllMunicipio } = response.data.data;
            const content = findAllMunicipio.content.map(item => {
                return {
                    value: item.id,
                    label: `${item.nome} - ${item.uf} `
                }
            });
            return {
                last: findAllMunicipio.last,
                content
            }
        } catch (error) {
            throw error;
        }
    }

    @action consultaProfissionalSaude = async () => {
        try {
            const unidadeAtual = await getUnidadeLogado();
            const redeId = unidadeAtual?.rede?.id;
            const response = await Api.post("", {
                query: `
                    query($searchDTO: SearchDTOInput){
                        findAllProfissionalSaude (searchDTO: $searchDTO){
                            content{
                                id
                                nome
                                possuiAgenda
                            }
                        }
                    }`,
                variables: {
                    searchDTO: {
                        redeId,
                        sortField: "nome",
                        sortDir: "ASC",
                    },
                },
            });
            const findAllSujeitoAtencaoByProfissional =
                response.data.data.findAllProfissionalSaude;

            const listaMedicos = findAllSujeitoAtencaoByProfissional.content.filter(
                (item) => item.possuiAgenda
            );
            return listaMedicos.map((item) => {
                return {
                    value: item.id,
                    name: `${item.nome}`,
                };
            });
        } catch (error) {
            throw error;
        }
    }

    @action loadPaises(query, callback) {
        let page = { search: query ? query : this.objView.pais.label, pageNumber: '', pageSize: 20 };
        let name = 'Pais';

        Api.post('',
            createFindAllQuery({
                name: name,
                page: page,
                objName: 'searchDTO',
                attrsReturn: `
                totalElements
                numberOfElements
                content {
                    id
                    descricao
                }`
            })
        ).then((result) => {
            const data = result.data.data.query.content;
            this.paises = data ? data.map(c => ({ value: c.id, label: string.capitalize(c.descricao) })) : [];
            callback(this.paises)
        });
    };

    @action loadOcupacao(query, callback) {
        let page = { search: query ? query : this.objView.ocupacao.label, pageNumber: '', pageSize: 20 };
        let name = 'Cbo';

        return Api.post('',
            createFindAllQuery({
                name: name,
                page: page,
                objName: 'searchDTO',
                attrsReturn: `
                totalElements
                numberOfElements
                content {
                    id
                    codigoCBO
                    descricao
                }`
            })
        ).then((result) => {
            const data = result.data.data.query.content;
            this.ocupacoes = data ? data.map(c => ({ value: c.id, label: c.codigoCBO ? c.codigoCBO + ' - ' + c.descricao : c.descricao })) : [];
            callback(this.ocupacoes);
        });
    };

    @action createOcupacao(descricao) {
        let novaOcupacao = {
            descricao: descricao,
            ativo: true
        }

        return Api.post('',
            {
                query: `
                    mutation($ocupacao:CboInput){
                      createCbo(cbo:$ocupacao){
                        value: id
                        label: descricao
                      }
                    }
                `,
                variables: {
                    ocupacao: novaOcupacao
                }
            })
            .then(response => {
                return response.data?.data?.createCbo;
            });
    }

    @action loadProfissionaisSaude(query, callback) {
        let page = { search: query ? query : this.objView.profissionalSaude.label, pageNumber: '', pageSize: 20 };
        let name = 'ProfissionalSaude';

        Api.post('',
            createFindAllQuery({
                name: name,
                page: page,
                objName: 'searchDTO',
                attrsReturn: `
                totalElements
                numberOfElements
                content {
                    id
                    nome
                }`
            })
        ).then((result) => {
            const data = result.data.data.query.content;
            this.profissionaisSaude = data ? data.map(c => ({ value: c.id, label: c.nome })) : [];
            callback(this.profissionaisSaude);
        });

    };

    @action loadTiposLogradouro(query, callback) {
        let page = { search: query ? query : this.objView.tipoLogradouro.label, pageNumber: '', pageSize: 30 };
        let name = 'TipoLogradouro';

        Api.post('',
            createFindAllQuery({
                name: name,
                page: page,
                objName: 'searchDTO',
                attrsReturn: `
                content {
                    id
                    descricao
                }`
            })
        ).then((result) => {
            const data = result.data.data.query.content;
            this.tiposLogradouro = data.map(item => ({ value: item.id, label: string.capitalize(item.descricao) }));
            callback(this.tiposLogradouro);
        });
    };

    @action loadNaturalidade(query, callback) {
        let page = { search: query ? query : this.objView.profissionalSaude.label, pageNumber: '', pageSize: 20 };
        let name = 'Municipio';

        Api.post('',
            createFindAllQuery({
                name: name,
                page: page,
                objName: 'searchDTO',
                attrsReturn: `
                totalElements
                numberOfElements
                content {
                    id
                    nome
                    uf
                }`
            })
        ).then((result) => {
            const data = result.data.data.query.content;
            this.municipios = data ? data.map(c => ({ value: c.id, label: `${c.nome} - ${c.uf}` })) : [];
            callback(this.municipios);
        });

    };

    @action async loadQuantidadeSujeitosCadastrados() {
        const response = await quantidadeSujeitoDeAtencaoCadastrados();

        if (response.data.errors) {
            throw Error('Falha ao carregar total de pacientes.');
        }

        const totalPacientes = response.data.data.quantidadeSujeitoDeAtencaoCadastrados;
        return totalPacientes;
    }

    @action loadComunidadeAutonoma(query, callback, field) {
        let page = { search: query ? query : this.objView[field].label, pageNumber: '', pageSize: 20 };
        let name = string.capitalize(field)

        Api.post('',
            createFindAllQuery({
                name,
                page,
                objName: 'searchDTO',
                attrsReturn: `
                totalElements
                numberOfElements
                content {
                    id
                    nome
                }`
            })
        ).then((result) => {
            const data = result.data.data.query.content;
            this[nomeLista] = data ? data.map(c => ({ value: c.id, label: c.nome })) : [];
            callback(this[nomeLista]);
        });

    };

    closeNotification = () => {
        this.notification.message = '';
        this.notification.variant = '';
        this.notification.isOpen = false;
    }

    resetNotification = () => {
        this.notification.message = '';
        this.notification.variant = '';
        this.notification.isOpen = false;
    }

    openNotification = (message, variant) => {
        this.notification.message = message;
        this.notification.variant = variant;
        this.notification.isOpen = true;
    }

    saveNewSujeitoAtencao = () => {
        if (this.contemErros()) {
            this.loadingSave = false;
            return;
        }

        let sujeito = this.createSujeito();
        Api.post('',
            JSON.stringify(
                {
                    query: createMutation({
                        name: `createSujeitoAtencao`,
                        objType: 'SujeitoAtencaoInput',
                        objName: 'sujeitoAtencao',
                        attrsReturn: 'id'
                    }, 'mutation'),
                    variables: { sujeitoAtencao: sujeito }
                })
        ).then(async response => {
            const data = response.data.data?.createSujeitoAtencao;
            this.loadingSave = false;
            if (data) {
                this.objView.fotoPerfil !== 'none' && await this.syncImageChanged({ id: this.objView.fotoPerfil }, data.id);
                this.changeId(data.id);
                this.openNotification('Cadastro efetuado com sucesso.', 'success');
                if (this.willLinkViziHealthPaciente) {
                    this.objView.id = data.id;
                    this.atendimentoStore.selectSujeitoAtencao(this.objView);
                    await this.atendimentoStore.updateAgendamento('updateAgendamento');
                    await this.liberarAcessoVizi(data.id, this.objView.paciente.id);
                    this.willLinkViziHealthPaciente = false;
                    this.sujeitoToSetOnAgendamento = true;
                    this.closeNotification();
                    return window.location.replace(`#${this.screenToBack}`);
                }
                this.reset();
                await this.removeObjViewOnStorage(null);
                this.newSujeitoAtencao();
                window.location.replace('#/sujeitos-de-atencao');
            } else {
                this.openNotification('Erro ao salvar o cadastro.', 'error');
            }
        })
    }

    updateSujeitoAtencao = () => {
        if (this.contemErros()) {
            this.loadingSave = false;
            return;
        }

        let sujeito = this.createSujeito();

        Api.post('',
            JSON.stringify(
                {
                    query: createMutation({
                        name: `updateSujeitoAtencao`,
                        objType: 'SujeitoAtencaoInput',
                        objName: 'sujeitoAtencao',
                        attrsReturn: 'id'
                    }, 'mutation'),
                    variables: { sujeitoAtencao: sujeito }
                })
        ).then(async (response) => {
            const data = response.data.data?.updateSujeitoAtencao;
            this.loadingSave = false;
            if (data) {
                this.changeId(data.id);
                this.openNotification('Cadastro atualizado com sucesso.', 'success');
                if (this.willLinkViziHealthPaciente) {
                    this.objView.id = data.id;
                    await this.liberarAcessoVizi(data.id, this.sujeitoObjViewFromAgendamento.paciente.id);
                    await this.atendimentoStore.updateAgendamento('updateAgendamento');
                    this.willLinkViziHealthPaciente = false;
                    this.sujeitoToSetOnAgendamento = true;
                    this.closeNotification();
                    return window.location.replace(`#${this.screenToBack}`);
                }
                this.removeObjViewOnStorage(data.id);
            } else {
                this.openNotification('Erro ao atualizar cadastro.', 'error');
            }
        })
    }

    documentoTipoPadrao = async (documentosTipo) => {
        const unidadeAtual = await getUnidadeLogado();
        const paisAtuacao = unidadeAtual?.rede?.paisAtuacao?.descricao;

        return paisAtuacao === "BRASIL" ? documentosTipo.find(item => item.descricao === "CPF") : ""
    };

    getTelefonePadrao = async () => {
        const unidade = await localStorageService.get(UNIDADE_LOGADA_KEY);
        const paisAtuacao = unidade.rede?.paisAtuacao?.descricao;

        if (this.unidadeUtilizaTelefoneInternacional) {
            return paisAtuacao === "ESPANHA" ? '+34' : '+55'
        };

        return '';
    };

    initObjView = async (entidade, dominios) => {
        this.updateImagemSujeitoAtencao(entidade?.fotoPerfil);

        this.objView.id = entidade.id ? entidade.id : null;
        this.objView.permiteAcessoApp = entidade.permiteAcessoApp || [];
        if (entidade.permiteAcessoApp && entidade.permiteAcessoApp.length > 0) {
            this.objView.anademPaciente = 'HABILITAR';
        }
        this.objView.ativo = entidade.ativo;
        this.objView.nome = entidade.nome ? entidade.nome : '';
        this.objView.nomeSocial = entidade.nomeSocial ? entidade.nomeSocial : '';
        this.objView.dataNascimento = entidade.dataNascimento ? moment(entidade.dataNascimento, 'YYYY-MM-DD') : null;
        this.objView.sexoId = entidade.sexo && entidade.sexo.id ? entidade.sexo.id : 3;
        this.objView.documentoTipo = entidade.documentoTipo ? entidade.documentoTipo : await this.documentoTipoPadrao(dominios.documentosTipo);
        this.objView.documento = entidade.documento ? entidade.documento : '';
        this.objView.cidade = entidade.cidade ? entidade.cidade : '';
        this.objView.rg = entidade.rg ? entidade.rg : '';
        this.objView.cns = entidade.cns ? entidade.cns : '';
        this.objView.estadoCivil = entidade.estadoCivil ? entidade.estadoCivil.id : null;
        this.objView.quemIndicou = entidade.quemIndicou ? entidade.quemIndicou : '';
        this.objView.profissionalSaude = entidade.profissionalSaude ? { value: entidade.profissionalSaude.id, label: entidade.profissionalSaude.nome } : null;
        this.objView.comoConheceu = entidade.comoConheceu ? entidade.comoConheceu : null;
        this.objView.bairro = entidade.endereco && entidade.endereco.bairro ? entidade.endereco.bairro : '';
        this.objView.cep = entidade.endereco && entidade.endereco.cep ? entidade.endereco.cep : '';
        this.objView.complemento = entidade.endereco && entidade.endereco.complemento ? entidade.endereco.complemento : '';
        this.objView.paciente = entidade.paciente && entidade.paciente?.id ? entidade.paciente : null;
        this.objView.nomeLogradouro = entidade.endereco && entidade.endereco.nomeLogradouro ? entidade.endereco.nomeLogradouro : '';
        this.objView.numero = entidade.endereco && entidade.endereco.numero ? entidade.endereco.numero : '';
        this.objView.municipio = entidade.endereco && entidade.endereco.municipio ? { value: entidade.endereco.municipio.id, label: entidade.endereco.municipio.nome } : null;
        this.objView.estado = entidade.endereco && entidade.endereco.estado ? { value: entidade.endereco.estado.id, label: entidade.endereco.estado.nome } : null;
        this.objView.tipoLogradouro = entidade.endereco?.tipoLogradouro ? { value: entidade.endereco.tipoLogradouro.id, label: string.capitalize(entidade.endereco.tipoLogradouro.descricao) } : null;
        this.objView.permiteAcessoApp = entidade.permiteAcessoApp || [];
        this.objView.espanhaMunicipio = entidade.espanhaMunicipio && { value: entidade.espanhaMunicipio.id, label: string.capitalize(entidade.espanhaMunicipio.descricao) }
        this.provincia = entidade.espanhaMunicipio && { value: entidade.espanhaMunicipio.provincia.id, label: string.capitalize(entidade.espanhaMunicipio.provincia.descricao) };
        this.comunidadeAutonoma = entidade.espanhaMunicipio && { value: entidade.espanhaMunicipio.provincia?.comunidadeAutonoma?.id, label: string.capitalize(entidade.espanhaMunicipio.provincia?.comunidadeAutonoma?.descricao) };
        this.isSujeitoAtencaoUnificado = !!entidade?.unificacao?.id;

        this.objView.email = entidade.contato && entidade.contato.email ? entidade.contato.email : '';

        const telefonePadrao = await this.getTelefonePadrao();
        await this.unidadeStore.checkUsoTelefoneInternacional();
        this.objView.telefonePrincipalDDI = entidade?.contato?.telefonePrincipalDDI;
        this.objView.telefonePrincipal = this.unidadeStore.unidade.utilizaTelefoneInternacional && entidade.contato?.telefonePrincipal && entidade.contato?.telefonePrincipalDDI ?
            `${this.objView?.telefonePrincipalDDI} ${entidade.contato?.telefonePrincipal}` :
            entidade.contato && entidade.contato.telefonePrincipal ? entidade.contato.telefonePrincipal : telefonePadrao;
        this.objView.telefoneSecundarioDDI = entidade?.contato?.telefoneSecundarioDDI;
        this.objView.telefoneSecundario = this.unidadeStore.unidade.utilizaTelefoneInternacional && entidade.contato?.telefoneSecundario && entidade.contato?.telefoneSecundarioDDI ?
            `${this.objView.telefoneSecundarioDDI} ${entidade.contato?.telefoneSecundario}` :
            entidade.contato && entidade.contato?.telefoneSecundario ? entidade.contato?.telefoneSecundario : telefonePadrao;
        this.objView.receberMensagemEmail = entidade.contato && entidade.contato.receberMensagemEmail !== null ? entidade.contato.receberMensagemEmail : true;
        this.objView.receberMensagemWhatsappTelefonePrincipal = entidade.contato && entidade.contato?.receberMensagemWhatsappTelefonePrincipal !== null ? entidade.contato?.receberMensagemWhatsappTelefonePrincipal : true;
        this.objView.receberMensagemWhatsappTelefoneSecundario = entidade.contato && entidade.contato?.receberMensagemWhatsappTelefoneSecundario !== null ? entidade.contato?.receberMensagemWhatsappTelefoneSecundario : true;
        this.objView.observacao = entidade.observacao ? entidade.observacao : '';
        this.objView.nomeResponsavel = entidade.nomeResponsavel || '';
        this.objView.documentoResponsavel = entidade.documentoResponsavel || '';
        this.objView.racaId = entidade.raca && entidade.raca.id ? entidade.raca.id : null;
        this.objView.dadosConvenio = entidade.dadosConvenio || {};
        this.objView.dadosConvenios = entidade.dadosConvenios || [];
        this.objView.religiao = entidade.religiao ? entidade.religiao : '';
        this.objView.nomeMae = entidade.nomeMae ? entidade.nomeMae : '';
        this.objView.nomePai = entidade.nomePai ? entidade.nomePai : '';
        this.objView.nomeConjuge = entidade.nomeConjuge ? entidade.nomeConjuge : '';
        this.objView.peso = entidade.peso ? entidade.peso : null;
        this.objView.altura = entidade.altura ? entidade.altura : null;
        this.objView.escolaridadeId = entidade.escolaridade && entidade.escolaridade.id ? entidade.escolaridade.id : null;
        this.objView.ocupacao = entidade.ocupacao ? { value: entidade.ocupacao.id, label: entidade.ocupacao.codigoCBO ? entidade.ocupacao.codigoCBO + ' - ' + entidade.ocupacao.descricao : entidade.ocupacao.descricao } : null;

        if (entidade?.origem) {
            this.objView.pais = entidade.origem.pais ? { value: entidade.origem.pais.id, label: string.capitalize(entidade.origem.pais.descricao) } : null;
            this.objView.nacionalidadeId = entidade.origem.nacionalidade && entidade.origem.nacionalidade.id ? entidade.origem.nacionalidade.id : null;
            this.objView.municipioOrigem = entidade.origem.municipio ? { value: entidade.origem.municipio.id, label: `${entidade.origem.municipio.nome} - ${entidade.origem.municipio.uf}` } : null;
            this.objView.estadoOrigem = entidade.origem.estado ? { value: entidade.origem.estado.id, label: entidade.origem.estado.nome } : null;
            this.objView.dataEntradaBrasil = entidade.origem.dataEntradaBrasil ? moment(entidade.origem.dataEntradaBrasil) : null;
        }

        if (entidade?.dadosConvenio) {
            this.objView.convenio = {
                value: entidade?.dadosConvenio?.convenio?.id,
                label: entidade?.dadosConvenio?.convenio?.descricao
            }
            this.objView.numeroCarteira = entidade?.dadosConvenio?.numeroCarteira ? entidade?.dadosConvenio?.numeroCarteira : '';
            this.objView.validadeCarteira = entidade?.dadosConvenio?.validadeCarteira ? moment(entidade?.dadosConvenio?.validadeCarteira) : null;
        }


        if (entidade?.paciente) {
            this.objView.paciente = entidade.paciente;
        }

        const store = await this.getObjViewOnStorage(this.objView.id);

        if (store && store.objView && store.objView.id === this.objView.id) {
            this.objView = {
                ...this.objView,
                ...store.objView,
            };
        }

        this.sexos = dominios.sexo ? dominios.sexo.map(c => ({
            value: c.id,
            name: string.capitalize(c.descricao)
        })) : this.sexos;
        this.nacionalidades = dominios.nacionalidades ? dominios.nacionalidades.map(c => ({
            value: c.id,
            name: string.capitalize(c.descricao)
        })) : this.nacionalidades;
        this.estados = dominios.estados ? dominios.estados.map(c => ({
            value: c.id,
            name: string.capitalize(c.nome)
        })) : this.estados;
        this.escolaridades = dominios.escolaridades ? dominios.escolaridades.map(c => ({
            value: c.id,
            name: string.capitalize(c.descricao)
        })) : this.escolaridades;
        this.estadosCivis = dominios.estadosCivis ? dominios.estadosCivis.map(c => ({
            value: c.id,
            name: string.capitalize(c.descricao)
        })) : this.escolaridades;
        this.racas = dominios.racas ? dominios.racas.map(c => ({
            value: c.id,
            name: string.capitalize(c.nome)
        })) : this.racas;
        this.convenios = dominios.convenios.map(c => ({
            value: c.id,
            name: string.capitalize(c.descricao)
        }));
        this.espanhaComunidadesAutonoma = dominios.espanhaComunidadesAutonoma.map(c => ({
            value: c.id,
            name: string.capitalize(c.descricao)
        }));
        this.documentosTipo = dominios.documentosTipo;
        this.profissionaisSaudeComAgenda = dominios.profissionaisSaudeComAgenda.map(c => ({
            value: c.id,
            name: c.nome
        }));

        this.comoConheceuEnum = [{ value: 'INDICACAO', name: 'Indicação' }];

        if (this.objView.estado && this.objView.estado !== null) {
            this.findMunicipioBy(this.objView.estado.value);
        }

        if (this.objView.estadoOrigem !== null) {
            this.findMunicipioOrigemBy(this.objView.estadoOrigem.value);
        }

        if (entidade.permiteAcessoApp && entidade.permiteAcessoApp.length > 0) {
            this.anademPaciente = 'HABILITAR';
        }

        return this.objView;
    }


    @action
    async updateImagemSujeitoAtencao(fotoPerfil) {
        if (!fotoPerfil) return false;

        const accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
        this.objView.fotoPerfil = fotoPerfil;
        this.objView.urlImagemPerfil = this.idOpened ? buildUrlFotoPerfil(fotoPerfil, accessToken) : buildUrlImagemDocumentoTemporario(fotoPerfil, accessToken);
        return true;
    }

    @action
    async salvarImagem(file) {
        const result = await this.saveFile(file);
        const data = await result.data;
        let retorno = false;

        if (data) {
            const syncResult = this.idOpened && await this.syncImageChanged(data)
            const dataResult = syncResult ? await syncResult.data : { id: data.id };

            if (dataResult) {
                retorno = await this.updateImagemSujeitoAtencao(data.id)
            }
        }

        if (retorno) {
            this.openNotification('Imagem alterada com sucesso.', 'success');
        } else {
            this.openNotification('Erro ao salvar a nova imagem. Tente novamente!', 'error')
        }

        return retorno;
    }

    async saveFile(file) {
        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                return NestorApi.post('/resource/AppHealth', formData);
            } catch (error) {
                throw error;
            }
        }
    }

    async syncImageChanged(fotoPerfil, sujeitoAtencaoId = null) {
        if (fotoPerfil && fotoPerfil.id) {
            return Api.post('', {
                query: `
                    mutation($fotoPerfilId: UUID, $id: UUID){
                        alterarFotoPerfilSujeitoAtencao(fotoPerfilId: $fotoPerfilId, id: $id){
                          id
                        }
                    }`,
                variables: {
                    fotoPerfilId: fotoPerfil.id,
                    id: this.idOpened || sujeitoAtencaoId
                }
            })
        }
    }

    async getAttrsReturn() {
        const unidade = await this.usuarioStore.getUnidadeAtual();

        return {
            sujeitoAtencao: `
                id 
                nome 
                nomeSocial 
                nomeResponsavel
                documentoResponsavel
                dataNascimento 
                documentoTipo {
                    id
                    descricao
                }
                documento 
                rg 
                cns 
                comoConheceu 
                quemIndicou 
                observacao 
                religiao 
                nomeMae 
                nomePai
                nomeConjuge
                peso
                altura
                ativo
                fotoPerfil
                unificacao {
                    id
                }
                estadoCivil {
                    id
                    descricao
                }
                sexo {id} 
                profissionalSaude {id, nome} 
                permiteAcessoApp {id} 
                raca {id}
                dadosConvenio {
                    id
                    convenioPrincipal
                    validadeCarteira
                    numeroCarteira
                    convenio {
                        id
                        descricao
                    }
                }
                dadosConvenios {
                    id
                    convenioPrincipal
                    validadeCarteira
                    numeroCarteira
                    convenio {
                        id
                        descricao
                    }
                }
                escolaridade {id} 
                ocupacao {id, codigoCBO, descricao}
                endereco {
                    bairro 
                    cep 
                    complemento 
                    nomeLogradouro 
                    numero 
                    estado {id, nome} 
                    municipio {id, nome}
                    tipoLogradouro {id, descricao}
                }

                espanhaMunicipio{
                    descricao
                    id
                    provincia{
                        descricao
                        id
                        comunidadeAutonoma{
                            descricao
                            id
                        }
                    }
                }

                contato {
                    email 
                    receberMensagemEmail 
                    receberMensagemWhatsappTelefonePrincipal 
                    receberMensagemWhatsappTelefoneSecundario 
                    telefonePrincipal
                    telefonePrincipalDDI
                    telefoneSecundario
                    telefoneSecundarioDDI
                }

                paciente {
                    id
                }

                unificacao{
                    id
                }

                origem {
                    pais {
                        id
                        descricao
                    } 
                    estado {
                        id
                        nome
                    } 
                    municipio {
                        id
                        nome
                        uf
                    } 
                    nacionalidade {
                        id
                        descricao
                    }
                    dataEntradaBrasil
                }`,
            dominios: `
                findAllSexoList {
                    
                        id
                        descricao
                }

                findAllNacionalidadeList {
                    
                    id
                    descricao
                }

                findAllEstadoList {
                    
                        id
                        nome
                }

                findAllEscolaridadeList {
                    
                        id
                        descricao
                }
                
                findAllEstadoCivilList {
                    
                        id
                        descricao
                } 

                findAllRacaList {
                        id
                        nome
                        descricao

                      
                }
                
                findAllConvenioList(searchDTO: {
                    unidadeId: ${unidade?.id || null},
                    ativo: true,
                    sortField: "descricao",
                    sortDir: "ASC"
                }) {
                        id
                        descricao
                }

                findProfissionalSaudeByUnidadeComAgenda(unidadeId: ${unidade?.id || null}) {
                    id
                    nome
               }

               findAllEspanhaComunidadeAutonoma(pageableDTO: {sortField: "descricao", sortDir: "ASC"}) {
                    id
                    descricao
               }

               findAllDocumentoTipo(pageableDTO:{sortField: "descricao", sortDir: "ASC"}){
                    id
                    descricao
              }

            `}
    }

    createSujeito() {
        const sujeito = {
            nome: this.objView.nome,
            nomeSocial: this.objView.nomeSocial,
            rg: this.objView.rg,
            cns: this.objView.cns || null,
            quemIndicou: this.objView.quemIndicou,
            endereco: {
                bairro: this.objView.bairro,
                cep: string.removeSpecialChars(this.objView.cep),
                complemento: this.objView.complemento,
                nomeLogradouro: this.objView.nomeLogradouro,
                numero: this.objView.numero,
                tipoLogradouro: this.objView.tipoLogradouro ? {
                    id: this.objView.tipoLogradouro.value,
                } : null,
            },
            contato: {
                email: this.objView.email,
                receberMensagemEmail: this.objView.receberMensagemEmail,
                receberMensagemWhatsappTelefonePrincipal: this.objView.receberMensagemWhatsappTelefonePrincipal,
                receberMensagemWhatsappTelefoneSecundario: this.objView.receberMensagemWhatsappTelefoneSecundario
            },
            observacao: this.objView.observacao,
            ativo: this.objView.ativo,
            nomeConjuge: this.objView.nomeConjuge,
            permiteAcessoApp: this.objView.permiteAcessoApp,
            nomeResponsavel: this.objView.nomeResponsavel,
            documentoResponsavel: this.objView.documentoResponsavel,
        };

        if (this.idOpened)
            sujeito.id = this.idOpened;

        if (this.objView.documentoTipo) {
            sujeito.documentoTipo = { id: this.objView.documentoTipo.id }
        }

        if (this.objView.espanhaMunicipio) {
            sujeito.espanhaMunicipio = { id: this.objView.espanhaMunicipio.value }
        }

        if (this.objView.fotoPerfil && this.objView.fotoPerfil !== "none")
            sujeito.fotoPerfil = this.objView.fotoPerfil;

        if (this.objView.peso)
            sujeito.peso = this.objView.peso;

        if (this.objView.altura)
            sujeito.altura = this.objView.altura;

        if (this.objView?.telefonePrincipal?.length > 0) {
            if (this.unidadeUtilizaTelefoneInternacional) {
                sujeito.contato.telefonePrincipal = this.getPhoneValue(
                    this.objView.telefonePrincipal,
                    this.objView.telefonePrincipalDDI
                );
            } else {
                sujeito.contato.telefonePrincipal = string.removeSpecialChars(this.objView.telefonePrincipal);
            }
        }

        if (this.objView?.telefonePrincipalDDI?.length > 0 && this.unidadeUtilizaTelefoneInternacional && this.objView?.telefonePrincipal?.length > 0) {
            sujeito.contato.telefonePrincipalDDI = this.objView.telefonePrincipalDDI;
        }

        if (this.objView?.telefoneSecundario?.length > 0)
            if (this.unidadeUtilizaTelefoneInternacional) {
                sujeito.contato.telefoneSecundario = this.getPhoneValue(
                    this.objView.telefoneSecundario,
                    this.objView.telefoneSecundarioDDI
                );
            } else {
                sujeito.contato.telefoneSecundario = string.removeSpecialChars(this.objView.telefoneSecundario);
            }

        if (this.objView?.telefoneSecundarioDDI?.length > 0 && this.unidadeUtilizaTelefoneInternacional && this.objView?.telefoneSecundario?.length > 0) {
            sujeito.contato.telefoneSecundarioDDI = this.objView.telefoneSecundarioDDI;
        }

        if (this.objView.documento && this.objView.documento.length > 0)
            sujeito.documento = string.removeSpecialChars(this.objView.documento);

        if (this.objView.dataNascimento)
            sujeito.dataNascimento = moment(this.objView.dataNascimento).format('YYYY-MM-DD');

        if (this.objView.sexoId && this.objView.sexoId !== "none")
            sujeito.sexo = { id: this.objView.sexoId };

        if (this.objView.comoConheceu && this.objView.comoConheceu !== "none")
            sujeito.comoConheceu = this.objView.comoConheceu;

        if (this.objView.estadoCivil && this.objView.estadoCivil !== "none")
            sujeito.estadoCivil = { id: this.objView.estadoCivil };

        if (this.objView.profissionalSaude && this.objView.profissionalSaude !== "none")
            sujeito.profissionalSaude = { id: this.objView.profissionalSaude.value };

        if (this.objView.municipio && this.objView.municipio !== "none")
            sujeito.endereco.municipio = { id: this.objView.municipio.value };

        if (this.objView.estado && this.objView.estado !== null)
            sujeito.endereco.estado = { id: this.objView.estado.value };

        if (this.objView.racaId && this.objView.racaId !== "none")
            sujeito.raca = { id: this.objView.racaId };

        if (this.objView.religiao && this.objView.religiao.length > 0)
            sujeito.religiao = this.objView.religiao;

        if (this.objView.nomeMae && this.objView.nomeMae.length > 0)
            sujeito.nomeMae = this.objView.nomeMae;

        if (this.objView.nomePai && this.objView.nomePai.length > 0)
            sujeito.nomePai = this.objView.nomePai;

        if (this.objView.escolaridadeId && this.objView.escolaridadeId !== "none")
            sujeito.escolaridade = { id: this.objView.escolaridadeId };

        if (this.objView.ocupacao && this.objView.ocupacao !== "none")
            sujeito.ocupacao = { id: this.objView.ocupacao.value };

        if (this.objView.pais
            || this.objView.nacionalidadeId
            || this.objView.municipioOrigem
            || this.objView.estadoOrigem
            || this.objView.dataEntradaBrasil) {
            sujeito.origem = {};

            if (this.objView.pais && this.objView.pais !== "none")
                sujeito.origem.pais = { id: this.objView.pais.value };

            if (this.objView.nacionalidadeId && this.objView.nacionalidadeId !== "none")
                sujeito.origem.nacionalidade = { id: this.objView.nacionalidadeId };

            if (this.objView.municipioOrigem && this.objView.municipioOrigem !== "none")
                sujeito.origem.municipio = { id: this.objView.municipioOrigem.value };

            if (this.objView.estadoOrigem && this.objView.estadoOrigem !== "none")
                sujeito.origem.estado = { id: this.objView.estadoOrigem.value };

            if (this.objView.dataEntradaBrasil)
                sujeito.origem.dataEntradaBrasil = moment(this.objView.dataEntradaBrasil).format('YYYY-MM-DD');
        }

        if (this.objView.dadosConvenios && this.objView.dadosConvenios.length > 0) {
            sujeito.dadosConvenios = this.objView.dadosConvenios.map(item => {
                return {
                    convenioPrincipal: item.convenioPrincipal,
                    convenio: { id: item?.convenio?.id || item?.value || item?.convenio?.value },
                    numeroCarteira: item.numeroCarteira,
                    validadeCarteira: item.validadeCarteira ? moment(item.validadeCarteira).format('YYYY-MM-DD') : null
                }
            })
        } else { sujeito.dadosConvenios = [] }


        if (this.objView.paciente) {
            sujeito.paciente = this.objView.paciente;
        }

        return sujeito;
    }

    @action getPhoneValue = (phone, dialCode) => {
        const [code, ...phoneNumber] = string.removeSpecialChars(phone).split(dialCode);
        return phoneNumber.join(dialCode);
    }

    formatPhone = (phone, dialCode) => {
        if (dialCode?.length > 0 && phone?.length > 0) {
            if (dialCode === '55') {
                return string.format('+## (##) #####-####', dialCode + phone)
            } else {
                const dialCodeFormat = dialCode.split('').map(() => '#');
                const phoneFormat = phone.split('').map(() => '#');
                const format = ['+', ...dialCodeFormat, ' ', ...phoneFormat].join('');
                return string.format(format, dialCode + phone);
            }
        } else {
            return '';
        }
    }

    @action getUltimaConsulta = async sujeitoAtencaoId => {
        findAgendamentosByIdSujeitoAtencao(sujeitoAtencaoId).then(({ data: response }) => {
            const consultasDecrescente = response.data.findAgendamentosByIdSujeitoAtencao.sort((a, b) => new Date(b.data) - new Date(a.data));

            this.ultimaConsulta = consultasDecrescente[0]
        });
    }

    @action verificaSujeitoAtencaoUnificado = async id => {
        const response = await Api.post('',
            {
                query: `
                query ($id: UUID) {
                    findByIdSujeitoAtencao(id: $id){
                        unificacao {
                            id
                        }
                    }
                }
            `,
                variables: { id }
            });
        this.isSujeitoAtencaoUnificado = !!response?.data?.data?.findByIdSujeitoAtencao?.unificacao?.id;
    }

    @action habilitaBotaoImpressao = () => {
        this.loadingPrint = false;
    }

    @action disabledBotaoImpressao = () => {
        this.loadingPrint = true;
    }

    @action setViziPacienteInObjView = (objView) => {
        this.objView = {
            ...this.objView,
            ...objView
        };
    };

    @action liberarAcessoVizi = async (sujeitoAtencaoId, pacienteId) => {
        const response = await liberarAcessoVizi(sujeitoAtencaoId, pacienteId)
        if (response.data?.data?.liberarAcessoVizi?.id) {
            this.objView.paciente = {
                id: response.data.data.liberarAcessoVizi.id
            }
            return response.data.data.liberarAcessoVizi.id;
        } else {
            return null;
        }
    }

    resetVincularViziVariaveis = () => {
        this.willLinkViziHealthPaciente = false;
        this.sujeitoToSetOnAgendamento = false;
        this.sujeitoObjViewFromAgendamento = sujeitoObjViewFromAgendamentoDefault;
    }

    resetSujeitoAtencaoFilter = () => {
        this.sujeitoAtencaoFilter = filterDefault;
    }
}
