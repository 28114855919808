import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import ModelEditor from './ModelEditor';

const ModelDocumentEditor = ({
    classes,
    id,
    updateName,
    updateStatus,
    updateCampos,
    disabledButtons,
    refChild,
    textoHtml,
    disabled,
    isDuplicar,
    modeloDocumentoStore,
}) => (
    <div className={classes.textAreaWrapper}>
        <ModelEditor
            classes={classes}
            id={id}
            updateName={updateName}
            updateStatus={updateStatus}
            ref={refChild}
            updateCampos={updateCampos}
            disabledButtons={disabledButtons}
            textoHtml={textoHtml}
            disabled={disabled}
            isDuplicar={isDuplicar}
            modeloDocumentoStore={modeloDocumentoStore}
        />
    </div>
);

const styles = {
    textAreaWrapper: {
        maxWidth: '700px',
        borderRadius: 10,
        overflow: 'hidden',
    },
    Btn: {
        border: 'none',
        background: 'none',
        color: '#505050',
        marginRight: '18px !important',
        '&[title="Bold"]>span': {
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAOCAYAAAD5YeaVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAClSURBVHgBjZDRDYMwDERNxX+7QekGXYENskHbCdoNSjcoE8AIGYERYAIyAhvAnZRIAQTmpFPsj5yfnRhjRtlWC5fW2prNSfZ1hysEftgki+RfVGfww9cDfEvjGIwr4h5B4j9c+FnDmO2zh3GFn752mDrHgL6yFnlzFhoGRd63hkFxuczXL+0aNZ4+tEcwgs7pIqmQNUZQd+QalIP/GgbP1sA59nETBQUrrSOdzPYAAAAASUVORK5CYII=)',
            backgroundSize: '10.75px 14px',
        },
        '&[title="Italic"]>span': {
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACNSURBVHgBlVKBDYAgDGNc4Al4gh9wAh94ivETT8AP+MAT5AQ/0JJgQiZOabJsY+vWLJBicM6dSoBWjWgmUO0RsgzcButgETZ47w9pg83NCeFulghTEc9lQVfkODiT0xXTo0gAxiJeeJHYdAO35zRies8JfMOr9gcB09NVbE7TVUKNQF9fAbKouuEvmgkXPA0gCDfFaLwAAAAASUVORK5CYII=)',
            backgroundSize: '12px 14px',
        },
        '&[title="Underlie"]>span': {
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAASCAYAAABrXO8xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACjSURBVHgB7ZPhDYIwEIWvTsAIOoGOoBPYEdzAEdANdJNuIE4AI3QENoB34UEOQkjgB/zhJe21d/dd2+TqBPLeV9LoHUJ4yYiQo/5U18hxB1moHVwDTCZykzEwoz1PgHfawoJ/2is6JB0S9B25/erkGNBr5CYYMQLXFy3Y+tFupw4krNDPwENFjBvA2ANNgQfMkyepMj7lA6iUzeTMX5ylxZ1TA9ixJckV3dD8AAAAAElFTkSuQmCC)',
            backgroundSize: '14px 18px',
        },
        '&[title="Strikethrough"]>span': {
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAYAAAAbBi9cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgBvVMxFgFBDM2i0Sl1lNtxBEdYJ8AJcALPCayKkhNYJ7Clckud0SlXp+PnbdbmjfGeYvnv/TeZJJNJMhmPHAiCoIFlzCLYFbUBY3AeRZGxz3iOIG0sB7BNnzFFsFArKg6nnQqS8CHhVvnM5MIXalY2XFJeSgr2dRmw8zIA2Y83oTOQOGik1p4PGpGNNrh6dKaitJiyfiT0L3jI4EEloEIlwfvWEZkPsSyoeBB+0Si3Vy3n0Pf9FTgBLycgt0FMoKtD7InqDt0+t9ulGcpejDmmd7SUfNMGe4424EzS7yFD/ip8K89TBxwq31gfdM0RT7b+Ji4s0Z6JVlRtD9R9RS84iwvYFJJkdQRHCLKmX+EJeU9RQYpRfPIAAAAASUVORK5CYII=)',
            backgroundSize: '18px 15.01px',
        },
        '&[title="UL"]>span': {
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAQCAYAAAD0xERiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABBSURBVHgB7dHRCQAgCATQqwkdoSarEW7DMnCByo8IH/ihH8JxgBKRsgaXkj1ptleSXW8DBzK8ecV8XLT5k2hzywTngiSXpdzgnAAAAABJRU5ErkJggg==)',
            backgroundSize: '18.5px 15px',
        },
        '&[title="OL"]>span': {
            backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAQCAYAAAD0xERiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABiSURBVHgBzZPLCQAxCER12QLtKOlgW7G1rSBxDkLOZg4+yAchjwyimNnCEgKKDTJ31yxW5Y+wYcXsi54RzyZUeCEI4Rf3P4vlbsbDgR+GdMol1Jh9ocbM2ZxxjJS1mE1qzA1I8ywszAk23wAAAABJRU5ErkJggg==)',
            backgroundSize: '19px 16px',
        },
    },
    BtnActive: {
        border: 'none',
        background: '#00ff00',
    },
    editor: {
        border: 'none',
        '& .EditorToolbar__root___3_Aqz': {
            margin: 0,
            padding: '10px 10px 5px 10px',
        },
        '& .public-DraftEditor-content': {
            padding: '14px 19px',
        },
    },
    scrollEditor: {
        maxHeight: '55vh',
        overflow: 'scroll !important'
    },

    jodit: {
        '--jd-color-background-default': '#ffffff',
        '--jd-color-border': '#eaeaea',
        '--jd-color-panel': '#ffffff',
        '--jd-color-icon': '#525252'
    }
};

ModelDocumentEditor.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    updateName: PropTypes.func,
    updateStatus: PropTypes.func,
    updateCampos: PropTypes.func,
    refChild: PropTypes.any,
    disabledButtons: PropTypes.array
};

ModelDocumentEditor.defaultProps = {
    updateName: () => {},
    updateStatus: () => {},
    updateCampos: () => {},
    disabledButtons: []
};

export default withStyles(styles)(ModelDocumentEditor);
